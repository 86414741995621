import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatDate from "utils/formatDate"
import truncate from "utils/truncate"

const Table = ({ collection, destroyStoryWithFetch, client }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th className="col-2">Дата обращения</th>
        <th>Тема</th>
        <th className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`story-${index}`}>
          <td>{formatDate(i.treatmentOn)}</td>
          <td>
            <Link
              to={`/clients/${client.id}/stories/${i.id}`}
              dangerouslySetInnerHTML={{ __html: truncate(i.subject) }}
            />
          </td>
          <td>
            <Edit url={`/clients/${client.id}/stories/${i.id}/edit`} />
            <Destroy action={() => destroyStoryWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
