import instance from "connection/instance"
import parseMoney from "utils/parseMoney"

const prepare = (data = {}) => {
  const { payment, employmentableId, ...other } = data
  return {
    ...other,
    employmentableId: employmentableId,
    payment: parseMoney(payment)
  }
}

const Estimate = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/employments`, { params })
  },
  create: (data) => instance.post("/employments", { data: prepare(data) }),
  update: (data) => instance.put(`/employments/${data.id}`, { data: prepare(data) }),
  updateCollection: (data) => instance.put("/employments", { data: data.map((i) => prepare(i)) }),
  destroy: (data) => instance.delete(`/employments/${data.id}`)
}

export default Estimate
