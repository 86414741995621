import { useState } from "react"
import PresentationApi from "api/presentation"
import afterDestroyFetch from "utils/afterDestroyFetch"

const usePresentations = (collection) => {
  const [presentations, setPresentations] = useState(collection)

  const fetchPresentations = async (params) => {
    const { data } = await PresentationApi.fetch(params)
    setPresentations(data.presentations)
    return data
  }

  const destroyPresentation = async (params) => PresentationApi.destroy(params)
  const destroyPresentationWithFetch = async (params) => afterDestroyFetch(presentations, fetchPresentations, destroyPresentation, params)

  return { presentations, fetchPresentations, destroyPresentation, destroyPresentationWithFetch }
}

export default usePresentations
