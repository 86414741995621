import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useStaff from "hooks/useStaff"
import Form from "pages/Staffs/components/Form"

const Edit = (props) => {
  const { staff, updateStaff } = useStaff(props.staff)
  const { citizenships, languages, metroStations, sexes, skills, statuses, clothesSizes } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={staff}
        options={{ citizenships, languages, metroStations, sexes, skills, statuses, clothesSizes }}
        handleSubmit={async (values) => updateStaff(values)}
      />
    </Fragment>
  )
}

export default Edit
