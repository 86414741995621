import React, { useState } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Card } from "components/UI"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import TabsBlock from "pages/Projects/components/TabsBlock"
import download from "utils/download"
import ProjectApi from "api/project"
import ProjectStaffs from "./components/ProjectStaffs"
import Overworks from "./components/Overworks"
import Reserves from "./components/Reserves"
import Managers from "./components/Managers"
import Vacancies from "./components/Vacancies"

const Staffs = ({ project, projectStaffs, reserves, vacancies, managers, positions, users }) => {
  const [disabled, setDisabled] = useState(false)

  const onExportTeamToXlsx = async () => {
    setDisabled(true)
    try {
      const { data } = await ProjectApi.exportTeamToXlsx({ id: project.id })
      download(data, `Проектный список №${project.id}.xlsx`)
    } finally {
      setDisabled(false)
    }
  }
  const onExportTeamForFsoToXlsx = async () => {
    setDisabled(true)
    try {
      const { data } = await ProjectApi.exportTeamForFsoToXlsx({ id: project.id })
      download(data, `Список персонала №${project.id}.xlsx`)
    } finally {
      setDisabled(false)
    }
  }
  const exportPresentationToPdf = async () => {
    setDisabled(true)
    try {
      const { data } = await ProjectApi.exportPresentationToPdf({ id: project.id })
      download(data, `Презентация №${project.id}.pdf`)
    } finally {
      setDisabled(false)
    }
  }
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Команда</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <DropdownButton title="Экспорт" variant="success" disabled={disabled}>
          <Dropdown.Item onClick={onExportTeamToXlsx}>Проектный список в .xlsx</Dropdown.Item>
          <Dropdown.Item onClick={onExportTeamForFsoToXlsx}>Список персонала в .xlsx</Dropdown.Item>
          <Dropdown.Item onClick={exportPresentationToPdf}>Презентация в .pdf</Dropdown.Item>
        </DropdownButton>
      </Card>
      <Managers project={project} managers={managers} positions={positions} users={users} />
      <ProjectStaffs project={project} projectStaffs={projectStaffs} />
      <Overworks project={project} projectStaffs={projectStaffs} />
      <Reserves project={project} reserves={reserves} />
      <Vacancies project={project} vacancies={vacancies} />
    </DisabledPage>
  )
}

export default Staffs
