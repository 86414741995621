import React from "react"
import ClientApi from "api/client"
import { AsyncSelectInput } from "components/UI"

const ClientSelect = ({ name = "client", label = "Клиент", required, afterChange, ...props }) => {
  const loadOptions = async (value) => {
    const { data } = await ClientApi.suggest({ query: value })
    return data.clients
  }
  return (
    <AsyncSelectInput
      name={name}
      label={label}
      required={required}
      options={props.defaultOptions}
      isSearchable
      loadOptions={loadOptions}
      afterChange={afterChange}
      // {...props}
    />
  )
}

export default ClientSelect
