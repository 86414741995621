import React from "react"
import { Button, Card } from "components/UI"
import { connect, getIn, FieldArray } from "formik"

const NestedField = (
  { name, index, field, component, disabledDestroy, showDestroy, form, h: { replace, remove } },
  ...props
) => {
  if (field._destroy) return null
  const onDestroyClick = () => (field.id ? replace(index, { ...field, _destroy: true }) : remove(index))
  const disabled =
    typeof disabledDestroy === "function"
      ? disabledDestroy({ prefix: `${name}.${index}`, index, form, ...props })
      : disabledDestroy
  return (
    <div>
      {React.createElement(component, { prefix: `${name}.${index}`, index, form, ...props })}
      {showDestroy(index) && (
        <Button variant="danger" onClick={onDestroyClick} disabled={disabled}>
          Удалить
        </Button>
      )}
      <hr />
    </div>
  )
}

const NestedFields = ({
  name,
  push,
  form,
  label,
  component,
  defaultValues,
  disabledAdd,
  disabledDestroy,
  showDestroy,
  required,
  ...h
}) => {
  const onAddClick = () => push(defaultValues)
  const error = getIn(form.errors, name, false)
  return (
    <Card required={required}>
      {label && <h3>{label}</h3>}
      {getIn(form.values, name, []).map((field, index) =>
        React.createElement(NestedField, {
          key: `${name}-${index}`,
          name,
          index,
          field,
          component,
          disabledDestroy,
          showDestroy,
          form,
          h
        })
      )}
      <Button variant="success" onClick={onAddClick} disabled={disabledAdd}>
        Добавить
      </Button>
      {error && <div className="invalid-feedback">{error}</div>}
    </Card>
  )
}

const Aa = ({
  name,
  label,
  component,
  defaultValues,
  disabledAdd = false,
  disabledDestroy = false,
  showDestroy = () => true,
  required = false
}) => (
  <FieldArray
    name={name}
    render={(props) => (
      <NestedFields
        {...props}
        label={label}
        component={component}
        defaultValues={defaultValues}
        disabledAdd={disabledAdd}
        disabledDestroy={disabledDestroy}
        showDestroy={showDestroy}
        required={required}
      />
    )}
  />
)

export default connect(Aa)
