import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useStaff from "hooks/useStaff"
import Form from "pages/Staffs/components/Form"

const Add = (props) => {
  const { staff, createStaff } = useStaff(props.staff)
  const { citizenships, languages, metroStations, sexes, skills, statuses, clothesSizes } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={staff}
        options={{ citizenships, languages, metroStations, sexes, skills, statuses, clothesSizes }}
        handleSubmit={async (values) => createStaff(values)}
      />
    </Fragment>
  )
}

export default Add
