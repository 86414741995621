import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Documents = () => (
  <Routes>
    <Route
      path="/clients/:clientId/documents"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/documents`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId/documents/new"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/documents/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/documents/:documentId/edit"
      fetchData={async ({ clientId, documentId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/documents/${documentId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Documents
