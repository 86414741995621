import instance from "connection/instance"

const prepare = (data = {}) => {
  const { passport, ...other } = data
  return {
    ...other,
    passport: {
      ...passport,
      sex: passport?.sex?.value || null
    }
  }
}

const User = {
  fetch: (params) => instance.get("/users", { params }),
  create: (data) => instance.post("/users", { data: prepare(data) }),
  update: (data) => instance.put(`/users/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/users/${data.id}`),
  suggest: (params) => instance.get("/users/suggest", { params })
}

export default User
