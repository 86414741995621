import { useState } from "react"
import AuthApi from "api/auth"
import store from "store"
import instance from "connection/instance"
import pageInstance from "connection/pageInstance"

const useAuth = () => {
  const [user, setUser] = useState(store.get("user"))

  if (user) {
    instance.defaults.headers.common.Authorization = user?.token
    pageInstance.defaults.headers.common.Authorization = user?.token
  } else {
    delete instance.defaults.headers.common.Authorization
    delete pageInstance.defaults.headers.common.Authorization
    store.set("user", null)
    window.history.pushState(null, "", "/login")
  }

  const login = async (params) => {
    const { data } = await AuthApi.login(params)
    window.location.pathname = '/'
    // setUser(data.user)
    
    return data
  }

  const logout = async (params) => {
    const { data } = await AuthApi.logout(params)
    setUser(null)
    return data
  }
  
  const sendSms = async (params) => {
    const { data } = await AuthApi.sendSms(params)
    return data
  }

  return { user, setUser, login, logout, sendSms }
}

export default useAuth
