import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useDocuments from "hooks/useDocuments"
import TabsBlock from "pages/Projects/components/TabsBlock"
import Table from "./components/Table"

const List = ({ project, ...props }) => {
  const { documents, fetchDocuments, destroyDocumentWithFetch } = useDocuments(
    props.documents,
    `/projects/${project.id}`
  )
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Документы</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/documents/new`}>Создать</Button>
      </Card>
      <Table collection={documents} destroyDocumentWithFetch={destroyDocumentWithFetch} project={project} />
      <Paginate collection={documents} fetch={fetchDocuments} />
    </DisabledPage>
  )
}

export default List
