import { useState } from "react"
import SkillApi from "api/skill"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useSkills = (collection) => {
  const [skills, setSkills] = useState(collection)

  const fetchSkills = async (params) => {
    const { data } = await SkillApi.fetch(params)
    setSkills(data.skills)
    return data
  }

  const destroySkill = async (params) => SkillApi.destroy(params)
  const destroySkillWithFetch = async (params) => afterDestroyFetch(skills, fetchSkills, destroySkill, params)

  return { skills, fetchSkills, destroySkill, destroySkillWithFetch }
}

export default useSkills
