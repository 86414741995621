import instance from "connection/instance"
import parseMoney from "utils/parseMoney"

const prepare = (data = {}) => {
  const { payment, ...other } = data
  return {
    payment: parseMoney(payment),
    ...other
  }
}

const ProjectStaff = {
  create: (data) => instance.post("/project_staffs", { data: prepare(data) }),
  update: (data) => instance.put(`/project_staffs/${data.id}`, { data: prepare(data) }),
  updateCollection: (data) => instance.put("/project_staffs", { data: data.map((i) => prepare(i)) }),
  destroy: (data) => instance.delete(`/project_staffs/${data.id}`),
  destroyCollection: (data) => instance.delete("/project_staffs", { data: { data } }),
  fetchMoreProjectStaffs: (params) => instance.get("/project_staffs", { params })
}

export default ProjectStaff
