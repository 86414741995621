import React from "react"
import { withFormik } from "formik"
import { Destroy, Submit, ImagePreview } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import formatMoney from "utils/formatMoney"

const Form = ({ handleSubmit, setFieldValue, values, errors }) => {
  const isCheckedAllStaffIds = values.items.every((i) => i.isExecuted)
  const onCheckedAllStaffIds = (e) =>
    setFieldValue(
      "items",
      values.items.map((i) => {
        return { ...i, isExecuted: e.target.checked }
      })
    )

  const isCheckedStaffId = (value) => values.items.find((i) => i.staff.id === value).isExecuted
  const onCheckedStaffId = (value) => {
    const isExecuted = isCheckedStaffId(value)

    const items = values.items.map((i) => {
      if (i.staff.id === value) {
        return { ...i, isExecuted: !isExecuted }
      }
      return i
    })

    setFieldValue("items", items)
  }

  const onDelete = (staffId) => {
    setFieldValue(
      "items",
      values.items.map((i) => {
        if (i.staff.id === staffId) {
          return { ...i, _destroy: true }
        }
        return i
      })
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <table className="table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" checked={isCheckedAllStaffIds} onChange={onCheckedAllStaffIds} />
              &nbsp; Исполнено
            </th>
            <th>ФИО</th>
            <th>Фото</th>
            <th>Сумма выплат {values.kind == "tinkoff" && "включая 6,4%"}</th>
            <th>Выплаты по проектам</th>
            <th className="actions one"></th>
          </tr>
        </thead>
        <tbody>
          {values.items
            .filter((i) => !i._destroy)
            .map((item) => (
              <tr key={`project-staff-${item.id}`}>
                <td>
                  <input
                    type="checkbox"
                    checked={isCheckedStaffId(item.staff.id)}
                    onChange={() => onCheckedStaffId(item.staff.id)}
                  />
                </td>
                <td>{item.staff.fullName}</td>
                <td>
                  <ImagePreview src={item.staff.avatar} width="75" height="100" />
                </td>
                <td>{formatMoney(item.paymentSum)}</td>
                <td>{item.projectIds.join(", ")}</td>
                <td>
                  <Destroy action={() => onDelete(item.staff.id)} disabled={isCheckedStaffId(item.staff.id)} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {errors.alreadyStatusInWork && <p style={{ color: "#e74a3b" }}>{errors.alreadyStatusInWork[0]}</p>}
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/payment_registries")
})(Form)
