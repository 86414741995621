import { useState } from "react"
import ServicesAnalyticApi from "api/servicesAnalytic"

const useServicesAnalytic = (collection, url = null) => {
  const [services, setServices] = useState(collection)

  const fetchServicesAnalytic = async (params) => {
    const { data } = await ServicesAnalyticApi.setBaseURL(url).fetch(params)
    setServices(data.services)
    return data
  }

  return { services, fetchServicesAnalytic }
}

export default useServicesAnalytic
