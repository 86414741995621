import { useState } from "react"

const useModal = (isOpen = false) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => setIsModalOpen(false)

  return [isModalOpen, openModal, closeModal]
}

export default useModal
