import React from "react"
import EntityApi from "api/entity"
import { AsyncSelectInput } from "components/UI"

const EntitySelect = ({ name = "entity", label = "Юридическое лицо", required, ...props }) => {
  const loadOptions = async (value) => {
    const { data } = await EntityApi.suggest({ query: value })
    return data.entities
  }
  return (
    <AsyncSelectInput
      name={name}
      label={label}
      required={required}
      options={props.defaultOptions}
      isSearchable
      loadOptions={loadOptions}
      {...props}
    />
  )
}

export default EntitySelect
