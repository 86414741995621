import { useState } from "react"
import OverworkApi from "api/overwork"

const useOverworks = (collection) => {
  const [overworks, setOverworks] = useState(collection)

  const createOverwork = async (params) => await OverworkApi.create(params)
  const createOverworkWithRefresh = async (params) => {
    const { data } = await createOverwork(params)
    setOverworks(overworks.concat([data.overwork]))
  }

  const updateOverwork = async (params) => await OverworkApi.update(params)
  const updateOverworkWithRefresh = async (params) => {
    const { data } = await updateOverwork(params)

    setOverworks(
      overworks.map((i) => {
        if (i.id === data.overwork.id) {
          return data.overwork
        }
        return i
      })
    )
  }

  const updateOverworks = async (params) => await OverworkApi.updateCollection(params)
  const updateOverworksWithRefresh = async (params) => {
    const { data } = await updateOverworks(params)

    setOverworks(
      overworks.map((overwork) => {
        const updatedOverwork = data.overworks.find((i) => i.id === overwork.id)
        if (updatedOverwork) {
          return updatedOverwork
        }
        return overwork
      })
    )
  }

  const destroyOverwork = async (params) => await OverworkApi.destroy(params)
  const destroyOverworkWithRefresh = async (params) => {
    const { data } = await destroyOverwork(params)
    setOverworks(overworks.filter((i) => i.id !== params.id))
  }

  return {
    overworks,
    createOverworkWithRefresh,
    updateOverworkWithRefresh,
    updateOverworksWithRefresh,
    destroyOverworkWithRefresh
  }
}

export default useOverworks
