import React, { Fragment } from "react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useUsers from "hooks/useUsers"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ sexes, skills, statuses, ...props }) => {
  const { users, fetchUsers, destroyUserWithFetch } = useUsers(props.users)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/users/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          options={{ sexes, skills, statuses }}
          collection={users}
          handleSubmit={async (values) => fetchUsers({ filter: values })}
        />
      </Card>
      <Table collection={users} destroyUserWithFetch={destroyUserWithFetch} />
      <Paginate collection={users} fetch={fetchUsers} />
    </Fragment>
  )
}

export default List
