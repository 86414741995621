import React from "react"
import { ImagePreview } from "components/UI"

const Table = ({ collection, isCheckedRequisite, onCheckedRequisite, isCheckedAllRequisites, onCheckedAllRequisites }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th className="actions one">
          <input type="checkbox" checked={isCheckedAllRequisites} onChange={onCheckedAllRequisites} />
        </th>
        <th scope="col" className="col-lg-9">Наименование</th>
        <th scope="col" className="col-lg-9">Изображение</th>
        <th scope="col">Доступное количество</th>
      </tr>
    </thead>
    <tbody>
      {collection.data.map((row, index) => (
        <tr key={`requisite-${index}`}>
          <td>
            <input type="checkbox" checked={isCheckedRequisite(row.id)} onChange={() => onCheckedRequisite(row.id)} />
          </td>
          <td>{row.name}</td>
          <td><ImagePreview src={row.file} /></td>
          <td>{row.availableCount}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
