import React, { Fragment } from "react"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useCourier from "hooks/useCourier"
import Form from "pages/Couriers/components/Form"

const Add = ({ courierOptions, ...props }) => {
  const { courier, createCourier } = useCourier(props.courier)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={courier}
        courierOptions={courierOptions}
        handleSubmit={async (values) => createCourier(values)}
      />
    </Fragment>
  )
}

export default Add
