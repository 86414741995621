import React, { useEffect } from "react"
import classnames from "classnames"
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, ImagePreview } from "components/UI"
import useSelect from "hooks/useSelect"
import uniqBy from "lodash.uniqby"

const StatusModal = ({
  isProjectStaffsComplete,
  closeModal,
  isOpen,
  vacancies,
  vacancy,
  intersectingServices,
  setIntersectingServices,
  acceptVacanciesWithRefresh,
  rejectVacanciesWithRefresh,
  reserveVacanciesWithRefresh,
  increaseServicesAcceptVacanciesCount
}) => {
  const staffVacancies = vacancies.filter((i) => i.staff.id === vacancy.staff.id)

  const isVacancyAvailable = (vacancy) =>
    vacancy.managerStatus.value ||
    vacancy.staffStatus.value === "reject" ||
    vacancy.service.quantity <=
      uniqBy(
        vacancies
          .filter((a) => a.service.id === vacancy.service.id)
          .map((a) => a.service.projectStaffs)
          .flat(),
        "id"
      ).filter((projectStaff) => projectStaff.isReserve === isProjectStaffsComplete).length ||
    intersectingServices[vacancy.id]

  const [
    selectedVacancyIds,
    totalVacancyIdsSelected,
    isVacancyIdChecked,
    onVacancyIdChecked,
    isVacancyIdCheckedAll,
    onVacancyIdCheckedAll,
    resetSelected
  ] = useSelect(staffVacancies.filter((i) => !isVacancyAvailable(i)).map((i) => i.id))

  useEffect(() => {
    resetSelected()
  }, [isOpen])

  const onAcceptClick = async () => {
    try {
      const data = await acceptVacanciesWithRefresh(
        selectedVacancyIds.map((id) => {
          return { id }
        })
      )

      const groupedByServiceId = data.vacancies.reduce((a, b) => {
        a[b.serviceId] = a[b.serviceId] || []
        a[b.serviceId].push(b)
        return a
      }, {})

      const services = Object.keys(groupedByServiceId).map((id) => {
        return {
          id: parseInt(id, 10),
          acceptVacanciesCount: groupedByServiceId[id].filter((i) => i.managerStatus.value === "accept").length
        }
      })

      increaseServicesAcceptVacanciesCount(services)
    } catch (error) {
      setIntersectingServices({ intersectingServices, ...error.response.data.errors })
    } finally {
      resetSelected()
    }
  }

  const onRejectClick = () => {
    rejectVacanciesWithRefresh(
      selectedVacancyIds.map((id) => {
        return { id }
      })
    )
    resetSelected()
  }

  const onReserveClick = () => {
    reserveVacanciesWithRefresh(
      selectedVacancyIds.map((id) => {
        return { id }
      })
    )
    resetSelected()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="profile bg-light p-2">
              <ImagePreview src={vacancy.staff.avatar} width="85" height="110" enableLightbox={false} />
              <span>
                <strong>{vacancy.staff.fullName}</strong>
              </span>
              <br />
              <span>Проекты: {vacancy.staff.projectCount}</span>
              <br />
              <span>Рейтинг: {vacancy.staff.rating}</span>
              <br />
              <span>
                Телефоны:&nbsp;
                {vacancy.staff?.phones
                  ?.map((i, index) => (
                    <a key={`phone-${index}`} href={`tel:${i.value}`}>
                      {i.value}
                    </a>
                  ))
                  .reduce((a, b) => [a, ", ", b])}
              </span>
            </div>
          </div>
        </div>
        <table className="table tabler-hover">
          <thead>
            <tr>
              <td className="actions one">
                <input
                  type="checkbox"
                  checked={isVacancyIdCheckedAll}
                  onChange={onVacancyIdCheckedAll}
                  disabled={staffVacancies.filter((i) => !isVacancyAvailable(i)).length === 0}
                />
              </td>
              <td>Наименование в заказ-наряде</td>
              <td>Дата и время работы</td>
              <td>Статус менеджера</td>
              <td>Статус персонала</td>
            </tr>
          </thead>
          <tbody>
            {staffVacancies.map((i, index) => (
              <tr
                key={`vacancy-${index}`}
                data-title={intersectingServices[i.id]?.modelYears}
                className={classnames({ "table-danger": intersectingServices[i.id] })}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={isVacancyIdChecked(i.id)}
                    onChange={() => onVacancyIdChecked(i.id)}
                    disabled={isVacancyAvailable(i)}
                  />
                </td>
                <td>
                  <strong>{i.service.innerName}</strong>&nbsp;({i.service.orderName})
                </td>
                <td>{i.service.working}</td>
                <td>{i.managerStatus.label}</td>
                <td>{i.staffStatus.label}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="5">
                <Button variant="success btn-sm" disabled={selectedVacancyIds.length === 0} onClick={onAcceptClick}>
                  Принять
                </Button>
                &nbsp;
                <Button variant="warning btn-sm" disabled={selectedVacancyIds.length === 0} onClick={onReserveClick}>
                  В резерв
                </Button>
                &nbsp;
                <Button variant="danger btn-sm" disabled={selectedVacancyIds.length === 0} onClick={onRejectClick}>
                  Отклонить
                </Button>
              </th>
            </tr>
          </tfoot>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default StatusModal
