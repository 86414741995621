import instance from "connection/instance"
import parseMoney from "utils/parseMoney"

const prepare = (data = {}) => {
  const { payment, ...other } = data
  return {
    payment: parseMoney(payment),
    ...other
  }
}

const Overwork = {
  create: (data) => instance.post("/overworks", { data: prepare(data) }),
  update: (data) => instance.put(`/overworks/${data.id}`, { data: prepare(data) }),
  updateCollection: (data) => instance.put("/overworks", { data: data.map((i) => prepare(i)) }),
  destroy: (data) => instance.delete(`/overworks/${data.id}`)
}

export default Overwork
