import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"

const Feedbacks = () => (
  <Routes>
    <Route
      path="/projects/:projectId/feedbacks"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/feedbacks`, {
          signal
        })
        return data
      }}
      component={List}
    />
  </Routes>
)
export default Feedbacks
