import React, { Fragment } from "react"
import CropModal from "components/Common/CropModal"
import { useFormikContext } from "formik"
import { Lightbox } from "components/UI"
import useModal from "hooks/useModal"
import useModalTrigger from "hooks/useModalTrigger"
import { useDropzone } from "react-dropzone"

const UploadImage = ({ name, label = "Фото", aspect = 3 / 4, ...props }) => {
  const [isLightboxModalOpen, openLightboxModal, closeLightboxModal] = useModal(false)
  const [isUploadModalOpen, uploadModalData, openUploadModal, closeUploadModal] = useModalTrigger(false)
  const { values, setFieldValue } = useFormikContext()
  const onAdd = (attachment) => setFieldValue(name, attachment)

  const thumbSrc = values[name]?.thumb
  const imageSrc = values[name]?.file

  const getFileFromUrl = (url) => url && decodeURI(url.replace(/^.*[\\|/]/, ""))

  const fileName = getFileFromUrl(values[name]?.file)

  const onFileInputChange = (file) => {
    if (!file) return
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      openUploadModal({ src: reader.result, file: file })
    })
    reader.readAsDataURL(file)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      onFileInputChange(acceptedFiles[0])
    }
  })

  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <CropModal
        name={name}
        label={label}
        isOpen={isUploadModalOpen}
        closeModal={closeUploadModal}
        onCropAdded={onAdd}
        src={uploadModalData.src}
        file={uploadModalData.file}
        aspect={aspect}
        {...props}
      />
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Перетащите сюда файл или кликните, чтобы выбрать файл</p>
        <em>(Загружены могут быть только файлы форматов .jpeg и .png)</em>
      </div>
      {values[name] && thumbSrc && (
        <Fragment>
          <br />
          <div className="upload-image-preview">
            <img src={thumbSrc} className="img-fluid img-thumbnail" height="100" onClick={openLightboxModal} />
            <Lightbox isOpen={isLightboxModalOpen} closeModal={closeLightboxModal} src={imageSrc} />
          </div>
          <span style={{ display: "inline-block", marginTop: "0.5rem" }}>
            <a href={imageSrc} target="_blank">
              {fileName}
            </a>
          </span>
        </Fragment>
      )}
    </div>
  )
}

export default UploadImage
