import instance from "connection/instance"
import parseMoney from "utils/parseMoney"

const prepare = (data = {}) => {
  const { status, cost, cashback, recipient, ...other } = data
  return {
    ...other,
    status: status?.value || null,
    cost: parseMoney(cost),
    cashback: parseMoney(cashback),
    recipientId: recipient?.value || null
  }
}

const Cash = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL || "/cashes"}`, { params })
    // return instance.get(`${this.baseURL || ""}/cashes/project/10`, { params })
  },
  create: (data) => instance.post("/cashes", { data: prepare(data) }),
  update: (data) => instance.put(`/cashes/${data.id}`, { data: prepare(data) }),
  updateCollection: (data) => instance.put("/cashes", data),
  destroy: (data) => instance.delete(`/cashes/${data.id}`)
}

export default Cash
