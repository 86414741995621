import instance from "connection/instance"

const prepare = (data = {}) => {
  const { entity, executor, ...other } = data
  return { ...other, entityId: entity?.id, executorId: executor?.id }
}

const Agreement = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/agreements`, { params })
  },
  create: (data) => instance.post("/agreements", { data: prepare(data) }),
  update: (data) => instance.put(`/agreements/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/agreements/${data.id}`),
  suggest(params) {
    return instance.get(`${this.baseURL}/agreements/suggest`, { params })
  }
}

export default Agreement
