import React, { Fragment } from "react"
import Breadcrumbs from "pages/Skills/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useSkills from "hooks/useSkills"
import Table from "./components/Table"

const List = (props) => {
  const { skills, fetchSkills, destroySkillWithFetch } = useSkills(props.skills)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/skills/new">Создать</Button>
      </Card>
      <Table collection={skills} destroySkillWithFetch={destroySkillWithFetch} />
      <Paginate collection={skills} fetch={fetchSkills} />
    </Fragment>
  )
}

export default List
