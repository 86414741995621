import "./styles.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn } from "formik"
import { ru } from "date-fns/locale"
import { IMaskInput } from "react-imask"

const DateTimeInput = ({ name, label, required = false, value = null, ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  value = value ? value : getIn(values, name, false)
  const className = classnames("form-control", { "is-invalid": error })
  registerLocale("ru", ru)

  return (
    <div className={classnames("mb-3", {  "is-invalid": error ,required: required })}>
      <label htmlFor={id} className="form-label">{label}</label>
      <DatePicker
        locale="ru"
        dateFormat="dd.MM.yyyy HH:mm"
        selected={value}
        onBlur={handleBlur}
        onChange={(e) => setFieldValue(name, e)}
        autoComplete="off"
        showTimeSelect
        timeCaption="Время"
        customInput={<IMaskInput mask={"00.00.0000 00:00"} lazy={false} />}
        {...{ className, id, name, ...props }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}
export default connect(DateTimeInput)
