import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import formatMoney from "utils/formatMoney"

const StatisticModal = ({ isOpen, closeModal, staistic }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Статистика</ModalHeader>
      <ModalBody>
        <table className="table table-hover">
          <tbody>
            <tr>
              <td className="col-6">Расход по смете</td>
              <td>{formatMoney(staistic[0].value)}</td>
            </tr>
            <tr>
              <td className="col-6">Фактический расход</td>
              <td>{formatMoney(staistic[1].value)}</td>
            </tr>
            <tr>
              <td className="col-6">Выдано МО</td>
              <td>{formatMoney(staistic[2].value)}</td>
            </tr>
            <tr>
              <td className="col-6">Сметная прибыль</td>
              <td>{formatMoney(staistic[3].value)}</td>
            </tr>
            <tr>
              <td className="col-6">Проектная прибыль</td>
              <td>{formatMoney(staistic[4].value - staistic[1].value)}</td>
            </tr>
            <tr>
              <td className="col-6">Операционные расходы 10%</td>
              <td>{formatMoney((staistic[4].value - staistic[1].value) * 0.1)}</td>
            </tr>
            <tr>
              <td className="col-6">Фактическая прибыль</td>
              <td>{formatMoney((staistic[4].value - staistic[1].value) * 0.9)}</td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default StatisticModal
