import React, { Fragment, useEffect, useMemo, useState } from "react"
import Breadcrumbs from "pages/Calendars/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import useCostsCalendar from "hooks/useCostsCalendar"
import useModalTrigger from "hooks/useModalTrigger"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import formatMoney from "utils/formatMoney"
import ShowCostModal from "./components/ShowCostModal"
import Filter from "./components/Filter"

const Costs = ({ costOptions, ...props }) => {
  const { costs, fetchCostsCalendar } = useCostsCalendar(props.costs)

  const events = useMemo(
    () =>
      costs.data.map((i) => {
        return {
          ...i,
          title: formatMoney(i.title)
        }
      }),
    [costs.data]
  )

  const [isShowCostModalOpen, editModalData, openShowCostModal, closeShowCostModal] = useModalTrigger({
    extendedProps: {}
  })

  const [filter, setFilter] = useState({})

  useEffect(() => {
    fetchCostsCalendar({
      filter
    })
  }, [filter])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Календарь затрат</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter
          options={costOptions}
          collection={costs}
          handleSubmit={async (values) => setFilter({ ...filter, byStatus: values.byStatus })}
        />
      </Card>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ru"
        firstDay={1}
        dayMaxEventRows={3}
        initialDate={new Date()}
        datesSet={({ startStr, endStr }) => setFilter({ ...filter, startStr, endStr })}
        eventClick={({ event }) => openShowCostModal(event)}
        events={events}
        moreLinkContent="Еще"
        displayEventTime={false}
        buttonText={{
          today: "Сегодня"
        }}
      />
      <ShowCostModal isOpen={isShowCostModalOpen} closeModal={closeShowCostModal} data={editModalData} />
    </Fragment>
  )
}

export default Costs
