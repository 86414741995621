import instance from "connection/instance"
import store from "store"

const Auth = {
  sendSms: async (data) => {
    try {
      const response = await instance.post("/auth/send_sms", { data })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error )
    }
  },
  login: async (data) => {
    try {
      const response = await instance.post("/auth/sign_in", { data })
      store.set("user", { ...response.data.user })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  logout: async () => {
    try {
      const response = await instance.post("/auth/sign_out")
      store.set("user", null)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject({ error })
    }
  }
}

export default Auth
