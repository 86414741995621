import React, { Fragment, useState } from "react"
import Breadcrumbs from "pages/Presentations/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import PresentationApi from "api/presentation"
import download from "utils/download"

const Show = ({ presentation }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async () => {
    try {
      setDisabled(true)
      const { data } = await PresentationApi.exportToPdf({ id: presentation.id })
      download(data, `${presentation.id}.pdf`)
    } finally {
      setDisabled(false)
    }
  }
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{presentation.name}</BreadcrumbItem>
        <BreadcrumbItem active>Презентация</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/presentations/${presentation.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <Button variant="warning" className="float-right" onClick={onExport} disabled={disabled}>
          Экспорт в .pdf
        </Button>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">Название</td>
            <td>{presentation.name}</td>
          </tr>
          <tr>
            <td className="col-6">Клиент</td>
            <td>{presentation.client?.name}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="col-6">ID</th>
            <th>Имя</th>
          </tr>
        </thead>
        <tbody>
          {presentation.staffs.map((i) => (
            <tr key={`staff-${i.id}`}>
              <td>{i.id}</td>
              <td>{i.fullName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default Show
