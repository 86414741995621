import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Show from "./containers/Show"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const Presentations = () => (
  <Routes>
    <Route
      path="/presentations"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/presentations`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/presentations/:presentationId"
      fetchData={async ({ presentationId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/presentations/${presentationId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/presentations/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/presentations/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/presentations/:presentationId/edit"
      fetchData={async ({ presentationId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/presentations/${presentationId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Presentations
