import React, { Fragment } from "react"
import Breadcrumbs from "pages/Skills/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useSkill from "hooks/useSkill"
import Form from "pages/Skills/components/Form"

const Add = (props) => {
  const { skill, createSkill } = useSkill(props.skill)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={skill} handleSubmit={async (values) => createSkill(values)} />
    </Fragment>
  )
}

export default Add
