import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Clients = () => (
  <Routes>
    <Route
      path="/clients/:clientId/contacts"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/contacts`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId/contacts/:contactId"
      fetchData={async ({ clientId, contactId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/contacts/${contactId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/clients/:clientId/contacts/new"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/contacts/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/contacts/:contactId/edit"
      fetchData={async ({ clientId, contactId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/contacts/${contactId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Clients
