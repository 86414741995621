import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Projects = () => (
  <Routes>
    <Route
      path="/projects/:projectId/managers/:managerId/employments"
      fetchData={async ({ projectId, managerId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/managers/${managerId}/employments`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/managers/:managerId/employments/new"
      fetchData={async ({ projectId, managerId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/managers/${managerId}/employments/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/managers/:managerId/employments/:employmentId/edit"
      fetchData={async ({ projectId, managerId, employmentId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/managers/${managerId}/employments/${employmentId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Projects
