import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Staffs = () => (
  <Routes>
    <Route
      path="/staffs/:staffId/portfolios"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/portfolios`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/staffs/:staffId/portfolios/:portfolioId"
      fetchData={async ({ staffId, portfolioId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/portfolios/${portfolioId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/staffs/:staffId/portfolios/new"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/portfolios/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/staffs/:staffId/portfolios/:portfolioId/edit"
      fetchData={async ({ staffId, portfolioId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/portfolios/${portfolioId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Staffs
