import { useState } from "react"
import EmploymentsCalendarApi from "api/employmentsCalendar"

const useEmploymentsCalendar = (collection, url = null) => {
  const [employments, setEmployments] = useState(collection)

  const fetchEmploymentsCalendar = async (params) => {
    const { data } = await EmploymentsCalendarApi.fetch(params)
    setEmployments(data.employments)
    return data
  }

  const createEmployment = async (params) => await EmploymentsCalendarApi.create(params)
  const createEmploymentWithRefresh = async (params) => {
    const { data } = await createEmployment(params)
    setEmployments({
      ...employments,
      data: employments.data.concat([data.employment])
    })
  }

  const destroyEmployment = async (params) => await EmploymentsCalendarApi.destroy(params)
  const destroyEmploymentWithRefresh = async (params) => {
    const { data } = await destroyEmployment(params)
    console.log(data)
    setEmployments({
      ...employments,
      data: employments.data.filter((i) => i.id !== data.employment.id)
    })
  }

  return { employments, fetchEmploymentsCalendar, createEmploymentWithRefresh, destroyEmploymentWithRefresh }
}

export default useEmploymentsCalendar
