import React, { Fragment } from "react"
import { ImagePreview } from "components/UI"
import { Link } from "components/Router"
import classnames from "classnames"

const ProjectStaffsByService = ({ project, projectStaffs, service }) => {
  return (
    <Fragment>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col" colSpan="6"></th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-warning">
            <td colSpan="6">
              <strong>{service.innerName}</strong>&nbsp;({service.orderName}) &nbsp;&nbsp;&nbsp;
            </td>
          </tr>
          <tr className="table-primary">
            <th scope="col">№</th>
            <th scope="col">Фото</th>
            <th scope="col">ФИО</th>
          </tr>
          {projectStaffs.map((projectStaff) => (
            <tr
              key={`overwork-${projectStaff.id}`}
              className={classnames({
                "table-success": projectStaff.paymentIsIssued
              })}
            >
              <td>{projectStaff.staff.id}</td>
              <td>
                <ImagePreview
                  src={projectStaff.staff.avatar}
                  width="75"
                  height="100"
                  className={projectStaff.staff.selfemployedRequisite.isSelfemployed ? "selfemployed-avatar" : ""}
                />
              </td>
              <td>
                <Link to={`/projects/${project.id}/teams/overworks/${service.id}/staffs/${projectStaff.staff.id}`}>
                  {projectStaff.staff.fullName}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default ProjectStaffsByService
