import replaceSearchParams from "utils/replaceSearchParams"

const afterDestroyFetch = (collection, fetch, destroy, params, fetchParams = {}) => {
  let { page } = collection.pagination
  destroy(params).then(() => {
    if (collection.data.length === 1 && page > 1) {
      page--
      replaceSearchParams({ page })
    }
    fetch({ ...fetchParams, page })
  })
}

export default afterDestroyFetch
