import React from "react"
import { Modal, ModalBody, ModalHeader } from "components/UI"

const CommentModal = ({ closeModal, isOpen, comment }) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal}>
    <ModalHeader>Отзыв</ModalHeader>
    <ModalBody>{comment}</ModalBody>
  </Modal>
)

export default CommentModal
