import React from "react"
import { Destroy, Edit } from "components/UI"
import formatDateTime from "utils/formatDateTime"
import PaymentRegistryApi from "api/paymentRegistry"
import download from "utils/download"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, destroyPaymentRegistryWithFetch }) => {
  const onExportMoToXlsx = async (id) => {
    const { data } = await PaymentRegistryApi.exportMoToXlsx({ id })
    download(data, "payment_registry_mo.xlsx")
  }
  const onExportTinkoffToXlsx = async (id) => {
    const { data } = await PaymentRegistryApi.exportTinkoffToXlsx({ id })
    download(data, "payment_registry_tinkoff.xlsx")
  }
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">Дата и время содания</th>
          <th scope="col">Оплата</th>
          <th scope="col">Статус</th>
          <th scope="col">Сумма</th>
          <th scope="col"></th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr key={`paymentRegistries-${index}`}>
            <td>{formatDateTime(i.createdAt)}</td>
            <td>{i.kind.label}</td>
            <td>{i.status.label}</td>
            <td>{formatMoney(i.paymentSum)}</td>
            <td>
              {i.kind.value === "mo" && (
                <button
                  type="button"
                  title="Скачать"
                  className="btn btn-link btn-action destroy"
                  onClick={() => onExportMoToXlsx(i.id)}
                >
                  <i className="fas fa-download"></i>
                </button>
              )}
              {i.kind.value === "tinkoff" && (
                <button
                  type="button"
                  title="Скачать"
                  className="btn btn-link btn-action destroy"
                  onClick={() => onExportTinkoffToXlsx(i.id)}
                >
                  <i className="fas fa-download"></i>
                </button>
              )}
            </td>
            <td>
              <Edit url={`/payment_registries/${i.id}/edit`} />
              <Destroy
                action={() => destroyPaymentRegistryWithFetch({ id: i.id })}
                disabled={i.status.value === "executed"}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
