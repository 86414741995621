import React, { Fragment } from "react"
import Breadcrumbs from "pages/Positions/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import usePositions from "hooks/usePositions"
import Table from "./components/Table"

const List = (props) => {
  const { positions, fetchPositions, destroyPositionWithFetch } = usePositions(props.positions)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/positions/new">Создать</Button>
      </Card>
      <Table collection={positions} destroyPositionWithFetch={destroyPositionWithFetch} />
      <Paginate collection={positions} fetch={fetchPositions} />
    </Fragment>
  )
}

export default List
