import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"
import Show from "./containers/Show"
import Contacts from "./containers/Contacts"
import Agreements from "./containers/Agreements"
import Entities from "./containers/Entities"
import Documents from "./containers/Documents"
import Stories from "./containers/Stories"

const Clients = () => (
  <Routes>
    <Route
      path="/clients"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/clients/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/edit"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
    <Route path="/clients/:clientId/contacts" component={Contacts} />
    <Route path="/clients/:clientId/agreements" component={Agreements} />
    <Route path="/clients/:clientId/entities" component={Entities} />
    <Route path="/clients/:clientId/documents" component={Documents} />
    <Route path="/clients/:clientId/stories" component={Stories} />
  </Routes>
)
export default Clients
