import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import Form from "./components/Form"

const EditProjectStaffModal = ({ projectStaff, isOpen, closeModal, updateProjectStaffWithRefresh }) => {
  const handleSubmit = async (data) => {
    await updateProjectStaffWithRefresh(data)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Персонал</ModalHeader>
      <ModalBody>
        <Form entity={projectStaff} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button variant="success" form="my-form" type="submit">
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditProjectStaffModal
