import instance from "connection/instance"

const ReservationRequisite = {
  createCollection: (data) => instance.post("/reservation_requisites", { data }),
  update: (data) => instance.put(`/reservation_requisites/${data.id}`, { data }),
  // updateCollection: (data) => instance.put("/reservation_requisites", { data: data.map((i) => prepare(i)) }),
  // destroy: (data) => instance.delete(`/reservation_requisites/${data.id}`),
  // destroyCollection: (data) => instance.delete("/reservation_requisites", { data: { data } })
}

export default ReservationRequisite
