import { useState } from "react"
import PaymentRegistryApi from "api/paymentRegistry"

const usePaymentRegistry = (collection) => {
  const [paymentRegistry, setPaymentRegistry] = useState(collection)

  const createPaymentRegistry = async (params) => {
    const { data } = await PaymentRegistryApi.create(params)
    setPaymentRegistry(data.paymentRegistry)
    return data
  }

  const updatePaymentRegistry = async (params) => {
    const { data } = await PaymentRegistryApi.update(params)
    setPaymentRegistry(data.paymentRegistry)
    return data
  }

  return { paymentRegistry, createPaymentRegistry, updatePaymentRegistry }
}

export default usePaymentRegistry
