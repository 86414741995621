import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useEstimates from "hooks/useEstimates"
import TabsBlock from "pages/Projects/components/TabsBlock"
import FinancesTabsBlock from "pages/Projects/components/FinancesTabsBlock"
import Table from "./components/Table"

const List = ({ project, ...props }) => {
  const { estimates, fetchEstimates, cloneEstimateWithFetch, destroyEstimateWithFetch } = useEstimates(
    props.estimates,
    `/projects/${project.id}`
  )
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Финансы</BreadcrumbItem>
        <BreadcrumbItem active>Сметы</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <FinancesTabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/estimates/new`}>Создать</Button>
      </Card>
      <Table
        collection={estimates}
        cloneEstimateWithFetch={cloneEstimateWithFetch}
        destroyEstimetWithFetch={destroyEstimateWithFetch}
        project={project}
      />
      <Paginate collection={estimates} fetch={fetchEstimates} />
    </DisabledPage>
  )
}

export default List
