import { useState } from "react"
import AgreementApi from "api/agreement"

const useAgreement = (collection) => {
  const [agreement, setAgreement] = useState(collection)

  const createAgreement = async (params) => {
    const { data } = await AgreementApi.create(params)
    setAgreement(data.agreement)
    return data
  }

  const updateAgreement = async (params) => {
    const { data } = await AgreementApi.update(params)
    setAgreement(data.agreement)
    return data
  }

  return { agreement, createAgreement, updateAgreement }
}

export default useAgreement
