import { useContext, useEffect, useState } from "react"
import AuthContext from "contexts/AuthContext"

const usePermissions = (permissions = []) => {
  const [allow, setAllow] = useState(true)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    setAllow(user.roles.map((i) => i.name).filter((i) => permissions.includes(i)).length > 0)
  }, [permissions])
  return allow
}

export default usePermissions
