import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useEmployment from "hooks/useEmployment"
import Form from "pages/Projects/containers/Employments/components/Form"

const Edit = ({ project, manager, ...props }) => {
  const { employment, updateEmployment } = useEmployment(props.employment)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem active>Менеджеры</BreadcrumbItem>
        <BreadcrumbItem active>{manager.user.fullName}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/managers/${manager.id}/employments`}>Занятость</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...employment, projectId: project.id }}
        handleSubmit={async (values) => updateEmployment({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
