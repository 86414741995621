import React, { Fragment } from "react"
import Breadcrumbs from "pages/Cashes/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useCash from "hooks/useCash"
import Form from "pages/Cashes/components/Form"

const Edit = ({ cashOptions, ...props }) => {
  const { cash, updateCash } = useCash(props.cash)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{cash.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={cash} options={cashOptions} handleSubmit={async (values) => updateCash(values)} />
    </Fragment>
  )
}

export default Edit
