import React, { Fragment, useState } from "react"
import { withFormik } from "formik"
import { AddressInput, SelectInput, Submit, TextEditor, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import ContactApi from "api/contact"
import EntityApi from "api/entity"
import EntitySelect from "components/Common/EntitySelect"
import UserSelect from "components/Common/UserSelect"
import usePermissions from "hooks/usePermissions"
import ClientSelectWithCreating from "./components/ClientSelectWithCreating"
import ContactSelectWithCreating from "./components/ContactSelectWithCreating"
import EntitySelectWithCreating from "./components/EntitySelectWithCreating"

const Form = ({ options, values, setFieldValue, handleSubmit }) => {
  const allow = usePermissions(["ADMIN", "ACCOUNT_MANAGER", "FINANCES"])
  const allowAccountManager = usePermissions(["ACCOUNT_MANAGER"])
  const [contacts, setContacts] = useState(options.contacts)
  const [entities, setEntities] = useState(options.entities)

  const afterClientChange = async (option) => {
    if (option === null) {
      setFieldValue("contact", null)
      setFieldValue("entity", null)
      setContacts([])
      setEntities([])
    } else {
      const {
        data: { contacts }
      } = await ContactApi.setBaseURL(`/clients/${option.value}`).suggest({})
      const {
        data: { entities }
      } = await EntityApi.setBaseURL(`/clients/${option.value}`).suggest({})
      setFieldValue("contact", null)
      setContacts(contacts)
      setFieldValue("entity", null)
      setEntities(entities)
    }
  }

  const onClientAdded = (option) => {
    setFieldValue("client", option)
    setFieldValue("contact", null)
    setContacts([])
  }

  const onContactAdded = (option) => setFieldValue("contact", option)
  const onEntityAdded = (option) => setFieldValue("entity", option)

  return (
    <form onSubmit={handleSubmit}>
      <TextInput name="name" label="Название" required />
      <ClientSelectWithCreating
        defaultOptions={options.clients}
        options={{ users: options.users }}
        afterChange={afterClientChange}
        onClientAdded={onClientAdded}
        required
      />
      <EntitySelectWithCreating
        defaultOptions={entities}
        clientId={values.client?.value}
        onEntityAdded={onEntityAdded}
      />
      <ContactSelectWithCreating
        defaultOptions={contacts}
        clientId={values.client?.value}
        onContactAdded={onContactAdded}
      />
      <SelectInput
        name="status.value"
        label="Статус"
        options={options.statuses}
        isOptionDisabled={(option) => ["finished_and_paid"].includes(option.value) && allowAccountManager}
        disabled={!allow}
      />
      {values.status.value === "failure" && (
        <Fragment>
          <SelectInput name="failureReason.value" label="Причина отказа" options={options.failureReasons} />
          <TextEditor name="statusComment" label="Комментарий к статусу" />
        </Fragment>
      )}
      <AddressInput name="address" label="Место проведения" />
      <SelectInput name="methodOfPayment.value" label="Форма оплаты" options={options.methodOfPayments} />
      <UserSelect name="accountManager" label="Аккаунт-менеджер" required />
      <TextEditor name="brief" label="Бриф" />
      <TextEditor name="feedback" label="Результат/Отзыв клиента" />
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity: _entity }) => {
    const { accountManager, client, contact, entity } = _entity
    return {
      ..._entity,
      accountManager: accountManager ? { label: accountManager.fullName, value: accountManager.id } : null,
      client: client ? { label: client.name, value: client.id } : null,
      contact: contact ? { label: contact.fullName, value: contact.id } : null,
      entity: entity ? { label: entity.name, value: entity.id } : null
    }
  },
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, ({ project: { id } }) => `/projects/${id}`)
})(Form)
