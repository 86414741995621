import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requests/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useModal from "hooks/useModal"
import formatPhone from "utils/formatPhone"
import formatDateTime from "utils/formatDateTime"
import truncate from "utils/truncate"
import { Link } from "components/Router"
import BriefModal from "./components/BriefModal"

const Show = ({ request }) => {
  const [isBriefModalOpen, openBriefModal, closeBriefModal] = useModal(false)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{request.id}</BreadcrumbItem>
      </Breadcrumbs>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">Дата и время создания</td>
            <td>{formatDateTime(request.createdAt)}</td>
          </tr>
          <tr>
            <td className="col-6">Имя</td>
            <td>{request.name}</td>
          </tr>
          <tr>
            <td className="col-6">Телефон</td>
            <td>{formatPhone(request.phone)}</td>
          </tr>
          <tr>
            <td className="col-6">E-mail</td>
            <td>{request.email}</td>
          </tr>
          <tr>
            <td className="col-6">Статус</td>
            <td>{request.status.label}</td>
          </tr>
          <tr>
            <td className="col-6">Тип</td>
            <td>{request.kind.label}</td>
          </tr>
          <tr>
            <td className="col-6">Аккаунт-Менеджер</td>
            <td>{request.accountManager?.fullName}</td>
          </tr>
          <tr>
            <td className="col-6">Предмет запроса</td>
            <td onClick={openBriefModal} dangerouslySetInnerHTML={{ __html: truncate(request.brief) }} />
          </tr>
          {request.project && (
            <tr>
              <td className="col-6">Проект</td>
              <td>
                <Link to={`/projects/${request.project.id}`}>{request.project.name}</Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <BriefModal isOpen={isBriefModalOpen} closeModal={closeBriefModal}>
        {request.brief}
      </BriefModal>
    </Fragment>
  )
}

export default Show
