import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Show from "./containers/Show"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const Couriers = () => (
  <Routes>
    <Route
      path="/couriers"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/couriers`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/couriers/:courierId"
      fetchData={async ({ courierId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/couriers/${courierId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/couriers/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/couriers/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/couriers/:courierId/edit"
      fetchData={async ({ courierId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/couriers/${courierId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Couriers
