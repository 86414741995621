import { useState } from "react"
import Sidebar from "components/UI/Sidebar"
import Topbar from "components/UI/Topbar"

import AuthContext from "contexts/AuthContext"

import { Router, Routes, Route } from "components/Router"
import LoadingBar from "react-top-loading-bar"
import { Forbidden, NotFound, InternalError, Loader } from "components/UI"
import Analytics from "pages/Analytics"
import Calendars from "pages/Calendars"
import Cashes from "pages/Cashes"
import Clients from "pages/Clients"
import Couriers from "pages/Couriers"
import Hrs from "pages/Hrs"
import Positions from "pages/Positions"
import Presentations from "pages/Presentations"
import PaymentRegistries from "pages/PaymentRegistries"
import Projects from "pages/Projects"
import Requests from "pages/Requests"
import Requisites from "pages/Requisites"
import Reservations from "pages/Reservations"
import ServiceSets from "pages/ServiceSets"
import Skills from "pages/Skills"
import Staffs from "pages/Staffs"
import Users from "pages/Users"
import Login from "pages/Login"

import useAuth from "hooks/useAuth"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import classNames from "classnames"

const App = () => {
  const [progress, setProgress] = useState(0)
  const [isSidebarShow, setIsSidebarShow] = useState(false)
  const toggleIsSidebarShow = () => setIsSidebarShow(!isSidebarShow)

  const { user, setUser, login, logout, sendSms } = useAuth()

  if (user) {
    return (
      <AuthContext.Provider value={{ user, login, logout }}>
        <LoadingBar
          color="#f11946"
          height={3}
          progress={progress}
          waitingTime={200}
          onLoaderFinished={() => setProgress(0)}
        />
        <Topbar toggleIsSidebarShow={toggleIsSidebarShow} />
        <div className="container-fluid">
          <ToastContainer />
          <div className="row">
            <Sidebar isSidebarShow={isSidebarShow} setIsSidebarShow={setIsSidebarShow} />
            <main className={classNames(["ms-sm-auto", isSidebarShow ? "col-lg-10" : "col-lg-12"])}>
              <div id="content-wrapper" className="d-flex flex-column">
                <div className="container-fluid content">
                  <Router
                    // initPath={window.location.path}
                    beforeFetchData={() => setProgress(20)}
                    afterFetchData={() => setProgress(100)}
                    onFetchDataError={(data) => {
                      setProgress(100)
                      if (data.response.status === 401) {
                        setUser(null)
                        window.history.pushState(null, "", "/login")
                      }
                      if (data.response.status === 403) {
                        return <Forbidden />
                      }
                      if (data.response.status === 404) {
                        return <NotFound />
                      }
                      if (data.response.status === 500 || data.response.status === 504) {
                        return <InternalError />
                      }
                      return null
                    }}
                    wrapper={<Loader />}
                  >
                    <Routes>
                      <Route path="*" component={NotFound} exact />
                      <Route path="/analytics" component={Analytics} />
                      <Route path="/calendars" component={Calendars} />
                      <Route path="/cashes" component={Cashes} />
                      <Route path="/clients" component={Clients} />
                      <Route path="/couriers" component={Couriers} />
                      <Route path="/hrs" component={Hrs} />
                      <Route path="/positions" component={Positions} />
                      <Route path="/presentations" component={Presentations} />
                      <Route path="/payment_registries" component={PaymentRegistries} />
                      <Route path="/projects" component={Projects} />
                      <Route path="/requests" component={Requests} />
                      <Route path="/requisites" component={Requisites} />
                      <Route path="/reservations" component={Reservations} />
                      <Route path="/service_sets" component={ServiceSets} />
                      <Route path="/skills" component={Skills} />
                      <Route path="/staffs" component={Staffs} />
                      <Route path="/users" component={Users} />
                      <Route path="/" component={Projects} />
                    </Routes>
                  </Router>
                </div>
              </div>
            </main>
          </div>
        </div>
      </AuthContext.Provider>
    )
  } else {
    return (
      <AuthContext.Provider value={{ user, login, sendSms }}>
        <Router beforeFetchData={() => setProgress(20)} afterFetchData={() => setProgress(100)} wrapper={<Loader />}>
          <Routes>
            <Route path="*" component={Login} exact />
            <Route path="/login" component={Login} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    )
  }
}

export default App
