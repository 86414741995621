import instance from "connection/instance"

const prepare = (data = {}) => {
  const { client, staffs, ...other } = data
  return {
    ...other,
    clientId: client?.value || null,
    staffs: staffs.map((i) => {
      return {
        id: i.id,
        _destroy: i._destroy
      }
    })
  }
}

const Presentation = {
  fetch: (params) => instance.get("/presentations", { params }),
  create: (data) => instance.post("/presentations", { data: prepare(data) }),
  update: (data) => instance.put(`/presentations/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/presentations/${data.id}`),
  exportToPdf: (data) =>
    instance.get(`/presentations/${data.id}/export_to_pdf`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    })
}

export default Presentation
