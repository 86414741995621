import { useState } from "react"
import DocumentApi from "api/document"

const useDocument = (collection) => {
  const [document, setDocument] = useState(collection)

  const createDocument = async (params) => {
    const { data } = await DocumentApi.create(params)
    setDocument(data.document)
    return data
  }

  const updateDocument = async (params) => {
    const { data } = await DocumentApi.update(params)
    setDocument(data.document)
    return data
  }

  return { document, createDocument, updateDocument }
}

export default useDocument
