import { useState } from "react"
import PaymentApi from "api/payment"

const usePayment = (collection) => {
  const [payment, setPayment] = useState(collection)

  const createPayment = async (params) => {
    const { data } = await PaymentApi.create(params)
    setPayment(data.payment)
    return data
  }

  const updatePayment = async (params) => {
    const { data } = await PaymentApi.update(params)
    setPayment(data.payment)
    return data
  }

  return { payment, createPayment, updatePayment }
}

export default usePayment
