import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"

const Hrs = () => (
  <Routes>
    <Route
      path="/hrs"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/hrs`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
  </Routes>
)
export default Hrs
