import React, { useContext } from "react"
import AuthContext from "contexts/AuthContext"

const Topbar = ({ toggleIsSidebarShow }) => {
  const { logout } = useContext(AuthContext)
  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <button className="navbar-toggler" type="button" onClick={toggleIsSidebarShow}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <a className="nav-link px-3" href="#" onClick={logout}>
            Выйти
          </a>
        </div>
      </div>
    </header>
  )
}

export default Topbar
