import React, { useState } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card } from "components/UI"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import EstimateApi from "api/estimate"
import download from "utils/download"
import Table from "./componets/Table"

const Show = ({ project, estimate, estimateOptions }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async (collapse = false) => {
    setDisabled(true)
    const { data } = await EstimateApi.export({ id: estimate.id, collapse })
    setDisabled(false)
    download(data, `${estimate.name}.docx`)
  }
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Сметы</BreadcrumbItem>
        <BreadcrumbItem active>{estimate.name}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/projects/${project.id}/estimates/${estimate.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <DropdownButton title="Экспорт в .docx" variant="success" disabled={disabled}>
          <Dropdown.Item onClick={() => onExport(false)}>Полная смета</Dropdown.Item>
          <Dropdown.Item onClick={() => onExport(true)}>Краткая смета</Dropdown.Item>
        </DropdownButton>
      </Card>
      <Table estimate={estimate} kinds={estimateOptions.services.kinds} />
    </DisabledPage>
  )
}

export default Show
