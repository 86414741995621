import React from "react"

const InternalError = () => (
  <div>
    <h1>500</h1>
    <p>К сожалению, произошла небольшая ошибка. Мы уже работаем над этим.</p>
  </div>
)

export default InternalError
