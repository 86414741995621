import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import usePayments from "hooks/usePayments"
import TabsBlock from "pages/Projects/components/TabsBlock"
import FinancesTabsBlock from "pages/Projects/components/FinancesTabsBlock"
import Table from "./components/Table"

const List = ({ project, ...props }) => {
  const { payments, fetchPayments, destroyPaymentWithFetch } = usePayments(props.payments, `/projects/${project.id}`)
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Финансы</BreadcrumbItem>
        <BreadcrumbItem active>Платежи</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <FinancesTabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/payments/new`}>Создать</Button>
      </Card>
      <Table collection={payments} destroyProjectWithFetch={destroyPaymentWithFetch} project={project} />
      <Paginate collection={payments} fetch={fetchPayments} />
    </DisabledPage>
  )
}

export default List
