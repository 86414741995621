import { useState } from "react"
import ProjectStaffApi from "api/projectStaff"

const useProjectStaffs = (collection) => {
  const [projectStaffs, setProjectStaffs] = useState(collection)

  const createProjectStaff = async (params) => await ProjectStaffApi.create(params)
  const updateProjectStaffs = async (params) => await ProjectStaffApi.updateCollection(params)
  const updateProjectStaff = async (params) => await ProjectStaffApi.update(params)
  const destroyProjectStaff = async (params) => await ProjectStaffApi.destroy(params)
  const destroyProjectStaffs = async (params) => await ProjectStaffApi.destroyCollection(params)
  const createProjectStaffWithRefresh = async (params) => {
    const { data } = await createProjectStaff(params)
    setProjectStaffs(projectStaffs.concat(data.projectStaff))
    return data
  }
  const updateProjectStaffWithRefresh = async (params) => {
    const { data } = await updateProjectStaff(params)

    setProjectStaffs(
      projectStaffs.map((i) => {
        if (i.id === data.projectStaff.id) {
          return data.projectStaff
        }
        return i
      })
    )
  }
  const updateProjectStaffsWithRefresh = async (params) => {
    try {
      const { data } = await updateProjectStaffs(params)

      setProjectStaffs(
        projectStaffs.map((projectStaff) => {
          const updatedProjectStaff = data.projectStaffs.find((i) => i.id === projectStaff.id)
          if (updatedProjectStaff) {
            return updatedProjectStaff
          }
          return projectStaff
        })
      )
    } catch(err) {
      alert(err); // TypeError: failed to fetch
    }

    
  }

  const destroyProjectStaffWithRefresh = async (params) => {
    await destroyProjectStaff(params)
    setProjectStaffs(projectStaffs.filter((i) => i.id !== params.id))
  }
  const destroyProjectStaffsWithRefresh = async (params) => {
    await destroyProjectStaffs(params)
    const ids = params.map((i) => i.id)
    setProjectStaffs(projectStaffs.filter((i) => !ids.includes(i.id)))
  }

  const fetchMoreProjectStaffs = async (params) => {
    const { data } = await ProjectStaffApi.fetchMoreProjectStaffs(params)
    setProjectStaffs((prev) => [...prev, ...data.projectStaffs])
  
    return data.projectStaffs
  }


  return {
    projectStaffs,
    createProjectStaffWithRefresh,
    updateProjectStaffWithRefresh,
    updateProjectStaffsWithRefresh,
    destroyProjectStaffWithRefresh,
    destroyProjectStaffsWithRefresh,
    fetchMoreProjectStaffs
  }
}

export default useProjectStaffs
