import { useState } from "react"
import ProjectApi from "api/project"

const useProject = (collection) => {
  const [project, setProject] = useState(collection)

  const createProject = async (params) => {
    const { data } = await ProjectApi.create(params)
    setProject(data.project)
    return data
  }

  const updateProject = async (params) => {
    const { data } = await ProjectApi.update(params)
    setProject(data.project)
    return data
  }

  return { project, createProject, updateProject }
}

export default useProject
