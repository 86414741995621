import { useState } from "react"

const useSelectAll = (totalCount = 0) => {
  const [selected, setSelected] = useState({ type: "manual", ids: [] })

  const isChecked = (id) => {
    const isIdsIncludesId = selected.ids.includes(id)
    return selected.type === "manual" ? isIdsIncludesId : !isIdsIncludesId
  }

  const onChecked = (id) => {
    const checkedSelected = selected.type === "manual" ? isChecked(id) : !isChecked(id)
    const ids = checkedSelected ? selected.ids.filter((i) => i !== id) : selected.ids.concat([id])
    setSelected({ ...selected, ids })
  }

  const isCheckedAll =
    (selected.type === "all" && selected.ids.length === 0) ||
    (selected.type === "manual" && selected.ids.length === totalCount)

  const onCheckedAll = (e) => {
    const type = e.target.checked ? "all" : "manual"
    setSelected({ type, ids: [] })
  }

  const totalSelected = selected.type === "manual" ? selected.ids.length : totalCount - selected.ids.length

  const resetSelected = () => setSelected({ type: "manual", ids: [] })

  return [selected, totalSelected, isChecked, onChecked, isCheckedAll, onCheckedAll, resetSelected]
}

export default useSelectAll
