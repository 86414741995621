import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import usePortfolios from "hooks/usePortfolios"
import TabsBlock from "pages/Staffs/components/TabsBlock"
import Table from "./components/Table"

const List = ({ staff, ...props }) => {
  const { portfolios, fetchPortfolios, destroyPortfolioWithFetch } = usePortfolios(
    props.portfolios,
    `/staffs/${staff.id}`
  )
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Портфолио</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock staff={staff} />
      <Card>
        <Button url={`/staffs/${staff.id}/portfolios/new`}>Создать</Button>
      </Card>
      <Table collection={portfolios} destroyStaffWithFetch={destroyPortfolioWithFetch} staff={staff} />
      <Paginate collection={portfolios} fetch={fetchPortfolios} />
    </Fragment>
  )
}

export default List
