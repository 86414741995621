import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"
import Show from "./containers/Show"

const Users = () => (
  <Routes>
    <Route
      path="/users"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/users`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/users/:userId"
      fetchData={async ({ userId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/users/${userId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/users/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/users/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/users/:userId/edit"
      fetchData={async ({ userId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/users/${userId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Users
