import instance from "connection/instance"

const Staff = {
  fetch: (params) => instance.get("/skills", { params }),
  create: (data) => instance.post("/skills", { data }),
  update: (data) => instance.put(`/skills/${data.id}`, { data }),
  destroy: (data) => instance.delete(`/skills/${data.id}`)
}

export default Staff
