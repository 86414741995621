import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useServiceSets from "hooks/useServiceSets"
import Table from "./components/Table"

const List = (props) => {
  const { serviceSets, fetchServiceSets, destroyServiceSetWithFetch } = useServiceSets(props.serviceSets)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/service_sets/new">Создать</Button>
      </Card>
      <Table collection={serviceSets} destroyServiceSetWithFetch={destroyServiceSetWithFetch} />
      <Paginate collection={serviceSets} fetch={fetchServiceSets} />
    </Fragment>
  )
}

export default List
