import React from "react"
import { Button } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import ContactSelect from "components/Common/ContactSelect"
import ContactModal from "./components/ContactModal"

const ContactSelectWithCreating = ({ clientId, name = "contact", options, onContactAdded, ...props }) => {
  const [isContactModalOpen, contactModalData, openContactModal, closeContactModal] = useModalTrigger()
  return (
    <div className="mb-3">
      <ContactSelect {...props} clientId={clientId} isDisabled={!clientId} />
      <Button onClick={openContactModal} disabled={!clientId} size="sm">
        Создать контактное лицо
      </Button>
      <ContactModal
        clientId={clientId}
        isOpen={isContactModalOpen}
        closeModal={closeContactModal}
        onContactAdded={onContactAdded}
      />
    </div>
  )
}

export default ContactSelectWithCreating
