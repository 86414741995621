import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.css"
import "styles/index.scss"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://ca92c49c706c4deeaac0e5eaff95ab38@o46317.ingest.sentry.io/5795538",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
