import React, { useEffect, useContext, useState } from "react"
import UrlPattern from "url-pattern"
import RouterСontext from "./RouterСontext"

const eventPopstate = "popstate"
const eventPushState = "pushState"
const eventReplaceState = "replaceState"

let patched = 0
let controller = null

const patchHistoryEvents = () => {
  if (patched) return
  ;[eventPushState, eventReplaceState].map((type) => {
    const original = window.history[type]

    window.history[type] = function () {
      const result = original.apply(this, arguments)
      const event = new Event(type)
      event.arguments = arguments

      dispatchEvent(event)
      return result
    }
  })

  return (patched = 1)
}

const matchPath = (path, location) => new UrlPattern(path).match(location)

const Router = ({ children: routes, beforeFetchData, afterFetchData, onFetchDataError, wrapper, initPath = null }) => {
  const [currentPath, setCurrentPath] = useState(initPath || window.location.pathname)
  const [currentComponent, setCurrentComponent] = useState(wrapper)
  const [warning, setWarning] = useState(() => () => false)

  useEffect(() => {
    patchHistoryEvents()

    window.addEventListener("popstate", function (a) {
      setCurrentPath(window.location.pathname)
    })
    window.addEventListener("pushState", function (a) {
      setCurrentPath(window.location.pathname)
    })
    window.addEventListener("replaceState", function (a) {
      setCurrentPath(window.location.pathname)
    })
  })

  let match = null

  const matcher = (routes) => {
    const result = []
    // console.log(routes) TODO make interable if we have just one route in routes

    for (let route of [routes].flat(4)) {
      if (route.props) {
        let { exact, path, component: children } = route.props
        if (!exact) {
          path = `${path}(/*)`
        }

        match = matchPath(path, currentPath)

        if (match && !match["_"]) {
          result.push(route)
        }
        if (match && route.props.component && typeof route.props.component === "function" && !route.props.fetchData) {
          result.push([route, matcher(route.props.component().props.children)])
        }
        if (match && match["_"]) {
          result.push(route)
        }
      }
    }
    return result
  }

  const result = matcher([routes.props.children]).flat(4)
  // console.log(result)

  useEffect(() => {  
    if (controller) {
      controller.abort()
    }
    let ff = result.filter((i) => i !== undefined)

    if (ff.length > 1 && currentComponent != wrapper) {
      ff = result
      // ff = [result.slice(-1)[0]]
    }
    // console.log(ff)

    if (currentComponent != wrapper) {
      beforeFetchData()
    }
    const last = ff.pop()

    match = matchPath(last.props.path, currentPath)

    controller = new AbortController()

    const prom = last.props.fetchData
      ? last.props.fetchData(match, last.props.componentProps, { signal: controller.signal })
      : Promise.resolve()
    prom
      .then((data) => {
        const aa = React.createElement(last.props.component, data)
        setCurrentComponent(aa)
        if (currentComponent != wrapper) {
          afterFetchData()
        }
        window.scrollTo({
          top: 0,
          behavior: "instant"
        })
        setWarning(() => () => false)
      })
      .catch((data) => {
        if (!data.response) return
        const aa = onFetchDataError(data)
        if (aa) setCurrentComponent(aa)
      })
  }, [currentPath, window.location.search])

  return (
    <RouterСontext.Provider value={{ currentPath, match, warning, setWarning }}>
      {currentComponent}
    </RouterСontext.Provider>
  )
}

const Routes = ({ children }) => {
  return children
}

Routes.displayName = "Routes"

const Route = ({ exact = false, path, children, fetchData = null }) => children

Route.displayName = "Route"

const useRouter = () => {
  return {}
}

const useLocation = () => {
  const { currentPath, match } = useContext(RouterСontext)
  return {
    location: currentPath,
    match,
    setLocation: (location) => window.history.pushState({ prevUrl: window.location.href }, "", location)
  }
}

const Link = ({ className, activeClassName, ...props }) => {
  let { to: href, children, onClick } = props
  const { warning, setWarning } = useContext(RouterСontext)
  const { location, setLocation } = useLocation()

  const handleClick = (e) => {
    e.preventDefault()

    if (warning()) {
      if (!window.confirm("У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?")) {
        return false
      }
    }

    setLocation(href)
  }

  const extraProps = { href: href, onClick: handleClick, to: null }

  if (location === href) {
    className = className + " " + activeClassName
  }

  return (
    <a {...props} className={className} {...extraProps}>
      {children}
    </a>
  )
}

const Prompt = ({ sss, children }) => {
  const { setWarning } = useContext(RouterСontext)
  setWarning(() => sss)

  useEffect(() => {
    function onBeforeUnload(e) {
      if (sss()) {
        e.preventDefault()
        e.returnValue = ""
        return
      }

      delete e["returnValue"]
    }

    window.addEventListener("beforeunload", onBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  })

  return children
}

export { Router, Routes, Route, useRouter, useLocation, Link, Prompt }
