import React from "react"
import { Link } from "components/Router"
import classnames from "classnames"

const Button = ({ children, variant = "primary", url, size = null, className = null, disabled, ...props }) => {
  const classNames = classnames("btn", { [`btn-${size}`]: size }, `btn-${variant}`, { disabled: disabled }, className)

  if (url) {
    return (
      <Link className={classNames} to={url} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <button type="button" className={classNames} {...props}>
      {children}
    </button>
  )
}

export default Button
