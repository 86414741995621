import React from "react"
import { SelectInput, TextInput, ResetButton, Submit } from "components/UI"
import { withFormik } from "formik"

const Filter = ({ collection, options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <TextInput name="byName" label="Наименование" />
      </div>
      <div className="col-3">
        <SelectInput name="byKind" label="Тип" options={options.kinds} />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
    &nbsp;&nbsp;&nbsp;Найдено: <strong>{collection.pagination.totalCount}</strong>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, { props }) => props.handleSubmit(values)
})(Filter)
