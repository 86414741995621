import { useState } from "react"
import RequisiteApi from "api/requisite"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useRequisites = (collection) => {
  const [requisites, setRequisites] = useState(collection)

  const fetchRequisites = async (params) => {
    const { data } = await RequisiteApi.fetch(params)
    setRequisites(data.requisites)
    return data
  }

  const destroyRequisite = async (params) => RequisiteApi.destroy(params)
  const destroyRequisiteWithFetch = async (params) => afterDestroyFetch(requisites, fetchRequisites, destroyRequisite, params)

  return { requisites, fetchRequisites, destroyRequisite, destroyRequisiteWithFetch }
}

export default useRequisites
