import React, { Fragment } from "react"
import Breadcrumbs from "pages/Analytics/components/Breadcrumbs"
import TabsBlock from "pages/Analytics/components/TabsBlock"
import { BreadcrumbItem, Card } from "components/UI"
import useServicesAnalytic from "hooks/useServicesAnalytic"
import Filter from "./components/Filter"
import Table from "./components/Table"

const SalesByServices = ({ client, serviceOptions, ...props }) => {
  const { services, fetchServicesAnalytic } = useServicesAnalytic(
    props.services,
    `/analytics/clients/${client.id}/sales_by_services`
  )
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to="/analytics/clients">Клиенты</BreadcrumbItem>
        <BreadcrumbItem active>{client.name}</BreadcrumbItem>
        <BreadcrumbItem active>Продажи по позициям</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock client={client} />
      <Card>
        <Filter
          options={serviceOptions}
          collection={services}
          handleSubmit={async (values) => fetchServicesAnalytic({ filter: values })}
        />
      </Card>
      <Table collection={services} />
    </Fragment>
  )
}

export default SalesByServices
