import React, { Fragment } from "react"
import { Destroy, Edit } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import classnames from "classnames"
import formatMoney from "utils/formatMoney"
import truncate from "utils/truncate"
import CommentModal from "./components/CommentModal"

const Table = ({ openEditOverworkModal, destroyOverworkWithRefresh, overworks }) => {
  const [isCommentModalOpen, commentModalData, openCommentModal, closeCommentModal] = useModalTrigger({ comment: null })
  return (
    <Fragment>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">Описание</th>
            <th scope="col">Сумма</th>
            <th scope="col" className="actions two" />
          </tr>
        </thead>
        <tbody>
          {overworks.map((overwork) => (
            <tr
              key={`overwork-${overwork.id}`}
              className={classnames({
                "table-success": overwork.paymentIsIssued
              })}
            >
              <td>
                <a
                  href="#"
                  className="open-modal"
                  onClick={() => openCommentModal({ comment: overwork.comment })}
                  dangerouslySetInnerHTML={{ __html: truncate(overwork.comment) }}
                />
              </td>

              <td>{formatMoney(overwork.payment)}</td>
              <td>
                <Edit
                  onClick={() => openEditOverworkModal(overwork)}
                  disabled={overwork.paymentIsIssued || overwork.cash?.status == "issued"}
                />
                <Destroy
                  action={() => destroyOverworkWithRefresh({ id: overwork.id })}
                  disabled={overwork.paymentIsIssued || overwork.cash?.status == "issued"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CommentModal isOpen={isCommentModalOpen} closeModal={closeCommentModal} comment={commentModalData.comment} />
    </Fragment>
  )
}

export default Table
