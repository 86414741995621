import React, { Fragment } from "react"
import { withFormik } from "formik"
import { Card, Submit, CurrencyInput, SelectInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import { Link } from "components/Router"
import usePermissions from "hooks/usePermissions"

const Form = ({ options, values, handleSubmit }) => {
  const allow = usePermissions(["ADMIN", "FINANCES"])
  return (
    <Fragment>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-lg-6">ID проекта</td>
            <td>
              <Link to={`/projects/${values.project.id}/expenses`}>{values.project.id}</Link>
            </td>
          </tr>
          <tr>
            <td>Даты проекта</td>
            <td>
              {formatDate(values.project.startedOn)} - {formatDate(values.project.finishedOn)}
            </td>
          </tr>
          <tr>
            <td>Аккаунт-менеджер</td>
            <td>{values.project.accountManager.fullName}</td>
          </tr>
          <tr>
            <td>Получатель</td>
            <td>{values.recipient.label}</td>
          </tr>
          <tr>
            <td>Дата выдачи</td>
            <td>{formatDate(values.issuedOn)}</td>
          </tr>
        </tbody>
      </table>
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <SelectInput label="Статус" name="status.value" options={options.statuses} disabled={!allow} />
            </div>
            <div className="col-lg-6">
              <CurrencyInput label="Сдано" name="cashback" />
            </div>
          </div>
          <Submit />
        </form>
      </Card>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Наименование внутреннее</th>
            <th>Наименование клиентское</th>
            <th>Фактический расход</th>
            <th>Расход в смете</th>
            <th>Выдано</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{values.orderName}</td>
            <td>{values.outerName}</td>
            <td>{formatMoney(values.payment)}</td>
            <td>{formatMoney(values.innerCost)}</td>
            <td>{formatMoney(values.cost)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => ({
    ...entity,
    recipient: { label: entity.recipient.fullName, value: entity.recipient.id }
  }),
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/cashes")
})(Form)
