import React from "react"

const Table = ({ collection }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ФИО</th>
        <th scope="col">Позиция</th>
        <th scope="col">Работа супервайзера</th>
        <th scope="col">Условия труда</th>
        <th scope="col">Комментарий</th>
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`feedbacks-${index}`}>
          <td>{i.staff.fullName}</td>
          <td>{i.service.innerName}</td>
          <td>{i.managerRating}</td>
          <td>{i.staffRating}</td>
          <td>{i.comment}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
