import React from "react"
import { withFormik } from "formik"
import { TextEditor, Submit } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ values, handleSubmit }) => {
  return (
    <form id="my-form" onSubmit={handleSubmit}>
      <TextEditor name="brief" />
      <Submit/>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
