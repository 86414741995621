import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useExpense from "hooks/useExpense"
import Form from "pages/Projects/containers/Expenses/components/Form"

const Edit = ({ project, requisiteOptions, requisites, ...props }) => {
  const { expense, updateExpense } = useExpense(props.expense)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/expenses`}>Расходы</BreadcrumbItem>
        <BreadcrumbItem active>{expense.description}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...expense, projectId: project.id }}
        requisites={requisites}
        requisiteOptions={requisiteOptions}
        handleSubmit={async (values) => updateExpense({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
