import React from "react"
import { Destroy, Edit } from "components/UI"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, destroyServiceSetWithFetch, serviceSet }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Внутреннее название</th>
        <th scope="col">Внутренняя стоимость за час</th>
        <th scope="col">Клиентское название</th>
        <th scope="col">Клиентская стоимость за час</th>
        <th scope="col">Скидка</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`service-${index}`}>
          <td>{i.innerName}</td>
          <td>{formatMoney(i.innerCostPerHour)}</td>
          <td>{i.outerName}</td>
          <td>{formatMoney(i.outerCostPerHour)}</td>
          <td>{i.discount}</td>
          <td>
            <Edit url={`/service_sets/${serviceSet.id}/services/${i.id}/edit`} />
            <Destroy action={() => destroyServiceSetWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
