import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useEntity from "hooks/useEntity"
import Form from "pages/Clients/containers/Entities/components/Form"

const Edit = ({ client, ...props }) => {
  const { entity, updateEntity } = useEntity(props.entity)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/entities`}>Юридические лица</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...entity, clientId: client.id }}
        handleSubmit={async (values) => updateEntity({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
