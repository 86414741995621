import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import From from "./componets/From"

const CreateEmploymentModal = ({ closeModal, isOpen, staffId, createEmploymentWithRefresh }) => {
  const handleSubmit = async (values) => {
    await createEmploymentWithRefresh(values)
    closeModal()
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        <From entity={{ employmentableType: "Staff", employmentableId: staffId }} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateEmploymentModal
