import { useState } from "react"
import AgreementApi from "api/agreement"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useAgreements = (collection, url = null) => {
  const [agreements, setAgreements] = useState(collection)

  const fetchAgreements = async (params) => {
    const { data } = await AgreementApi.setBaseURL(url).fetch(params)
    setAgreements(data.agreements)
    return data
  }

  const destroyAgreement = async (params) => AgreementApi.destroy(params)
  const destroyAgreementWithFetch = async (params) =>
    afterDestroyFetch(agreements, fetchAgreements, destroyAgreement, params)

  return { agreements, fetchAgreements, destroyAgreement, destroyAgreementWithFetch }
}

export default useAgreements
