import React from "react"
import UserApi from "api/user"
import { AsyncSelectInput } from "components/UI"

const UserSelect = ({ name = "user", label = "Пользователь", required, ...props }) => {
  const loadOptions = async (value) => {
    const { data } = await UserApi.suggest({ query: value })
    return data.users
  }
  return (
    <AsyncSelectInput
      name={name}
      label={label}
      required={required}
      defaultOptions={false}
      noOptionsMessage={() => null}
      placeholder=""
      isSearchable
      loadOptions={loadOptions}
      {...props}
    />
  )
}

export default UserSelect
