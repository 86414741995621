import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import ServiceApi from "api/service"
import Dates from "./components/Dates"

const CloneModal = ({ id, isOpen, closeModal, onServicesAdd }) => {
  useEffect(() => {
    setData({ excludeDays: [], startedAt: null, finishedAt: null })
  }, [isOpen])

  const onSave = () => ServiceApi.clone({ ...data, id }).then(({ data }) => onServicesAdd(data))

  const [data, setData] = useState({ excludeDays: [], startedAt: null, finishedAt: null })

  const isCheckedExcludeDays = (value) => data.excludeDays.includes(value)

  const onCheckedExcludeDays = (value) => {
    const excludeDays = isCheckedExcludeDays(value)
      ? data.excludeDays.filter((i) => i !== value)
      : data.excludeDays.concat([value])
    setData({ ...data, excludeDays })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Клонирование услуги</ModalHeader>
      <ModalBody>
        <Dates
          isCheckedExcludeDays={isCheckedExcludeDays}
          onCheckedExcludeDays={onCheckedExcludeDays}
          data={data}
          setData={setData}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSave} variant="success" disabled={data.excludeDays.length === 7}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CloneModal
