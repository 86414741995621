import React from "react"

const Table = ({ templates, isChecked, onChecked }) => (
  <table className="table">
    <thead className="thead-light">
      <tr>
        <th className="actions one"></th>
        <th>Услуга</th>
      </tr>
    </thead>
    <tbody>
      {templates.map((row, index) => (
        <tr key={`template-${index}`}>
          <td>
            <input type="checkbox" checked={isChecked(row.id)} onChange={() => onChecked(row.id)} />
          </td>
          <td>{row.innerName}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
