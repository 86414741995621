import instance from "connection/instance"

const prepare = (data = {}) => {
  const { user, ...other } = data
  return {
    ...other,
    userId: user?.value || null
  }
}

const Manager = {
  fetch: (params) => instance.get("/managers", { params }),
  create: (data) => instance.post("/managers", { data: prepare(data) }),
  update: (data) => instance.put(`/managers/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/managers/${data.id}`)
}

export default Manager
