import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Expenses = () => (
  <Routes>
    <Route
      path="/projects/:projectId/expenses"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/expenses`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/expenses/new"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/expenses/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/expenses/:expenseId/edit"
      fetchData={async ({ projectId, expenseId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/expenses/${expenseId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Expenses
