import instance from "connection/instance"

const ServiceSet = {
  fetch: (params) => instance.get("/service_sets", { params }),
  create: (data) => instance.post("/service_sets", { data }),
  update: (data) => instance.put(`/service_sets/${data.id}`, { data }),
  destroy: (data) => instance.delete(`/service_sets/${data.id}`)
}

export default ServiceSet
