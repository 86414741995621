import React, { Fragment } from "react"
import { DateInput, TextInput, ResetButton, SelectInput, Submit, PhoneInput } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import { withFormik } from "formik"
import filterSubmitHandler from "utils/filterSubmitHandler"
import formatMoney from "utils/formatMoney"

const Filter = ({ collection, options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <TextInput name="byIdOrName" label="Название" />
      </div>
      <div className="col-3">
        <TextInput name="byClientName" label="Клиент" />
      </div>
      <div className="col-3">
        <SelectInput name="byStatus" label="Статус" options={options.statuses} isMulti />
      </div>
      <div className="col-3">
        <DateInput name="byFinishFrom" label="Дата окончания проекта с" />
      </div>
      <div className="col-3">
        <DateInput name="byFinishTo" label="Дата окончания проекта по" />
      </div>
      <div className="col-3">
        <UserSelect name="byManager" label="Менеджер" />
      </div>
      <div className="col-3">
        <PhoneInput name="byContactPhone" label="Телефон представителя заказчика" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
    &nbsp;&nbsp;&nbsp;Найдено: <strong>{collection.pagination.totalCount}</strong>
    {collection.statistics.map((i, index) => (
      <Fragment key={`statistic-${index}`}>
        &nbsp;&nbsp;&nbsp;{i[0]}: <strong>{formatMoney(i[1])}</strong>
      </Fragment>
    ))}
  </form>
)

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
})(Filter)
