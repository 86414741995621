import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn } from "formik"

const RadioInput = ({ name, label, value, ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const _value = getIn(values, name, false)
  const className = classnames("form-check-input", { "is-invalid": error })

  return (
    <div className="form-check-inline form-check">
      <input
        checked={String(value).toString() === String(getIn(_value, "value", _value)).toString()}
        value={value}
        onBlur={handleBlur}
        onChange={(option) => {
          // handleChange(option) 
          setFieldValue(name, { label, value })
          typeof props.afterChange === "function" && props.afterChange(option)
        }}
        type="radio"
        {...{ className, id, name }}
        // {...{ className, id, name, ...props }}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default connect(RadioInput)
