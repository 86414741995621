import React from "react"
import { Destroy, Edit } from "components/UI"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, destroyCashWithFetch, project, expense }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Дата выдачи</th>
        <th scope="col">Сумма</th>
        <th scope="col">Получатель</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`estimates-${index}`}>
          <td>{formatDate(i.issuedOn)}</td>
          <td>{formatMoney(i.cost)}</td>
          <td>{i.recipient.fullName}</td>
          <td>
            <Edit url={`/projects/${project.id}/cashes/${expense.type}/${expense.id}/${i.id}/edit`} disabled={i.status.value === "issued"} />
            <Destroy action={() => destroyCashWithFetch({ id: i.id })} disabled={i.status.value === "issued"} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
