import { useState } from "react"
import PortfolioApi from "api/portfolio"

const usePortfolio = (collection) => {
  const [portfolio, setPortfolio] = useState(collection)

  const createPortfolio = async (params) => {
    const { data } = await PortfolioApi.create(params)
    setPortfolio(data.portfolio)
    return data
  }

  const updatePortfolio = async (params) => {
    const { data } = await PortfolioApi.update(params)
    setPortfolio(data.portfolio)
    return data
  }

  return { portfolio, createPortfolio, updatePortfolio }
}

export default usePortfolio
