import React from "react"
import { withFormik } from "formik"
import { Button, Submit, TextInput, Destroy } from "components/UI"
import ClientSelect from "components/Common/ClientSelect"
import formSubmitHandler from "utils/formSubmitHandler"
import useModal from "hooks/useModal"
import StaffModal from "./components/StaffModal"

const Form = ({ presentationOptions, staffOptions, staffs, values, handleSubmit, setFieldValue }) => {
  const [isStaffModalOpen, openStaffModal, closeStaffModal] = useModal(false)
  const addStaffToPresentation = (staffs) => {
    setFieldValue("staffs", values.staffs.concat(staffs))
  }

  const markStaffForDestroy = (id) => {
    const index = values.staffs.findIndex((i) => i.id === id)
    setFieldValue(`staffs.${index}`, { ...values.staffs[index], _destroy: true })
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextInput name="name" label="Название" required />
      <ClientSelect defaultOptions={presentationOptions.clients} />
      <div className="mb-3">
        <Button onClick={openStaffModal}>Добавить персонал</Button>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="col-6">ID</th>
            <th>Имя</th>
            <th className="actions one"></th>
          </tr>
        </thead>
        <tbody>
          {values.staffs
            .filter((i) => !i._destroy)
            .map((i) => (
              <tr key={`staff-${i.id}`}>
                <td>{i.id}</td>
                <td>{i.fullName}</td>
                <td>
                  <Destroy action={() => markStaffForDestroy(i.id)} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <StaffModal
        addStaffToPresentation={addStaffToPresentation}
        closeModal={closeStaffModal}
        isOpen={isStaffModalOpen}
        options={staffOptions}
        staffs={staffs}
      />
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { client } = entity
    return {
      ...entity,
      client: client ? { label: client.name, value: client.id } : null
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/presentations")
})(Form)
