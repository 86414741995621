import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Show from "./containers/Show"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const Requests = () => (
  <Routes>
    <Route
      path="/requests"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/requests`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/requests/:requestId"
      fetchData={async ({ requestId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/requests/${requestId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/requests/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/requests/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/requests/:requestId/edit"
      fetchData={async ({ requestId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/requests/${requestId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Requests
