import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useAgreement from "hooks/useAgreement"
import Form from "pages/Clients/containers/Agreements/components/Form"

const Add = ({ client, agreementOptions, ...props }) => {
  const { agreement, createAgreement } = useAgreement(props.agreement)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/agreements`}>Договоры</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...agreement, clientId: client.id }}
        options={agreementOptions}
        handleSubmit={async (values) => createAgreement({ ...values })}
      />
    </Fragment>
  )
}

export default Add
