import React, { Fragment, useState } from "react"
import Card from "react-bootstrap/Card"
import { Button } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import useManagers from "hooks/useManagers"
import CreateModal from "./components/CreateModal"
import ManagersByPosition from "./components/ManagersByPosition"

const Managers = ({ project, positions, users, ...props }) => {
  const [isCreateModalOpen, { positionId }, openCreateModal, closeCreateModal] = useModalTrigger({ positionId: null })

  const {managers, createManagerWithRefresh, destroyManagerWithRefresh} = useManagers(props.managers)

  return (
    <Fragment>
      {positions.map((position) => (
        <Card key={`managers-by-position-${position.id}`}>
          <Card.Header>{position.name}</Card.Header>
          <Card.Body>
            <Card>
              <Card.Body>
                <Button variant="success" onClick={() => openCreateModal({ positionId: position.id })}>
                  Создать
                </Button>
              </Card.Body>
            </Card>
            <ManagersByPosition
              managers={managers.filter((i) => i.position.id === position.id)}
              project={project}
              destroyManager={destroyManagerWithRefresh}
            />
          </Card.Body>
        </Card>
      ))}
      <CreateModal
        isOpen={isCreateModalOpen}
        closeModal={closeCreateModal}
        positionId={positionId}
        projectId={project.id}
        users={users}
        createManagerWithRefresh={createManagerWithRefresh}
      />
    </Fragment>
  )
}

export default Managers
