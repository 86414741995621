import React, { Fragment } from "react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useUser from "hooks/useUser"
import Form from "pages/Users/components/Form"

const Edit = ({ userOptions, ...props }) => {
  const { user, updateUser } = useUser(props.user)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/users/${user.id}`}>{user.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={user} options={userOptions} handleSubmit={async (values) => updateUser(values)} />
    </Fragment>
  )
}

export default Edit
