import instance from "connection/instance"

const prepare = (data = {}) => {
  const { contact, kind, ...other } = data
  return {
    ...other,
    contactId: contact?.value || null,
    kind: kind?.value || null
  }
}

const Story = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/stories`, { params })
  },
  create: (data) => instance.post("/stories", { data: prepare(data) }),
  update: (data) => instance.put(`/stories/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/stories/${data.id}`)
}

export default Story
