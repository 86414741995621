import React from "react"
import AsyncSelect from "react-select/async"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { getIn, useField } from "formik"

const AsyncSelectInput = ({
  name,
  label,
  options,
  required = false,
  isMulti = false,
  noOptionsMessage = () => "Нет элементов для выбора",
  placeholder = "Выберите элементы",
  ...props
}) => {
  const id = nanoid()
  const [{ onBlur }, { value, error }, { setValue }] = useField(name)
  const className = classnames("react-select", { "is-invalid": error })
  const errorStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e74a3b"
    })
  }

  return (
    <div className={classnames("mb-3", { required: required })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <AsyncSelect
        escapeClearsValue={false}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={(option) => {
          if (option) {
            // console.log(option.map(({ value }) => value))
            Array.isArray(option) ? setValue(option) : setValue(option)
          } else {
            setValue(option)
          }
          typeof props.afterChange === "function" && props.afterChange(option)
        }}
        defaultOptions={options}
        placeholder={placeholder}
        value={value || null}
        styles={error && errorStyles}
        isMulti={isMulti}
        isClearable={true}
        {...{ className, id, name, ...props }}
      />
      {error && <div className="invalid-feedback">{error[0]}</div>}
    </div>
  )
}

export default AsyncSelectInput
