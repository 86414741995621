import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form className="login" onSubmit={handleSubmit}>
    <TextInput name="password" label="Пароль" type="password" autocomplete="one-time-code" autoFocus />
    <Submit>Войти</Submit>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/")
})(Form)
