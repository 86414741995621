import instance from "connection/instance"

const ServicesAnalytic = {
  baseURL: "/analytics/services",
  setBaseURL(baseURL) {
    if (baseURL) {
      this.baseURL = baseURL
    }
    return this
  },
  fetch(params) {
    return instance.get(this.baseURL, { params })
  }
}

export default ServicesAnalytic
