import instance from "connection/instance"

const prepare = (data = {}) => {
  const { status, ...other } = data
  return {
    ...other,
    // items: items.map((i) => {
    //   return {
    //     id: i.id,
    //     _destroy: i._destroy
    //   }
    // }),
    status: { value: status }
  }
}

const PaymentRegistry = {
  fetch: (params) => instance.get("/payment_registries", { params }),
  create: (data) => instance.post("/payment_registries", { data: prepare(data) }),
  update: (data) => instance.put(`/payment_registries/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/payment_registries/${data.id}`),
  exportMoToXlsx: (data) =>
    instance.get(`/payment_registries/${data.id}/export_mo_to_xlsx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-excel"
      }
    }),
  exportTinkoffToXlsx: (data) =>
    instance.get(`/payment_registries/${data.id}/export_tinkoff_to_xlsx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-excel"
      }
    })
}

export default PaymentRegistry
