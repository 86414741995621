import { useState } from "react"
import EntityApi from "api/entity"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useEntities = (collection, url = null) => {
  const [entities, setEntities] = useState(collection)

  const fetchEntities = async (params) => {
    const { data } = await EntityApi.setBaseURL(url).fetch(params)
    setEntities(data.entities)
    return data
  }

  const createEntity = async (data) => EntityApi.create(data)

  const destroyEntity = async (params) => EntityApi.destroy(params)

  const destroyEntityWithFetch = async (params) =>
    afterDestroyFetch(entities, fetchEntities, destroyEntity, params)

  return { entities, fetchEntities, createEntity, destroyEntity, destroyEntityWithFetch }
}

export default useEntities
