import React from "react"
import { ImagePreview, Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatDate from "utils/formatDate"
import formatPhone from "utils/formatPhone"

const classNameByStatus = (status) => {
  return {
    active: "",
    black_list: "table-danger",
    archive: "table-info",
    under_consideration: "table-warning",
    in_processing: "table-success"
  }[status.value]
}

const Table = ({ collection, destroyStaffWithFetch }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th className="col-lg-2">Имя</th>
        <th className="col-lg-1">Возраст</th>
        <th className="col-lg-2">Телефон</th>
        <th>Фото</th>
        <th className="col-lg-1">Рейтинг</th>
        <th className="col-lg-2">Дата создания</th>
        <th className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((row, index) => (
        <tr key={`staff-${index}`} className={classNameByStatus(row.status)}>
          <td>
            <Link to={`/staffs/${row.id}`}>{row.fullName}</Link>
          </td>
          <td>{row.age}</td>
          <td>
            {row.phones.map((i, index) => [
              index > 0 && ", ",
              <a key={`phone-${i.id}`} href={`tel:${i.value}`}>
                {formatPhone(i.value)}
              </a>
            ])}
          </td>
          <td>
            <ImagePreview
              src={row.avatar}
              width="75"
              height="100"
              className={row.selfemployedRequisite.isSelfemployed ? "selfemployed-avatar" : ""}
            />
          </td>
          <td>{row.rating}</td>
          <td>{formatDate(row.createdAt)}</td>
          <td>
            <Edit url={`/staffs/${row.id}/edit`} />
            <Destroy action={() => destroyStaffWithFetch({ id: row.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
