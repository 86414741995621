import React, { Fragment } from "react"
import Breadcrumbs from "pages/Reservations/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import useReservations from "hooks/useReservations"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ reservationOptions, ...props }) => {
  const { reservations, fetchReservations, destroyReservationWithFetch } = useReservations(props.reservations)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter
          options={reservationOptions}
          collection={reservations}
          handleSubmit={async (values) => fetchReservations({ filter: values })}
        />
      </Card>
      <Table collection={reservations} destroyReservationWithFetch={destroyReservationWithFetch} />
      <Paginate collection={reservations} fetch={fetchReservations} />
    </Fragment>
  )
}

export default List
