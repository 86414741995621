import instance from "connection/instance"

const prepare = (data = {}) => {
  const { calculationKind, ...other } = data
  console.log(calculationKind)
  return {
    ...other,
    calculationKind: calculationKind?.value || null
  }
}

const Estimate = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/estimates`, { params })
  },
  create: (data) => instance.post("/estimates", { data: prepare(data) }),
  update: (data) => instance.put(`/estimates/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/estimates/${data.id}`),
  clone: (data) => instance.post(`/estimates/${data.id}/clone`),
  export: (data) =>
    instance.get(`/estimates/${data.id}/export`, {
      params: { collapse: data.collapse },
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-word"
      }
    })
}

export default Estimate
