import React, { Fragment } from "react"
import { Button, Destroy, Edit, ImagePreview } from "components/UI"
import { Link } from "components/Router"
import groupBy from "lodash.groupby"
import CountBadge from "components/Common/CountBadge"
import useModalTrigger from "hooks/useModalTrigger"
import classnames from "classnames"
import formatMoney from "utils/formatMoney"
import truncate from "utils/truncate"
import ServiceModal from "./components/ServiceModal"
import CommentModal from "./components/CommentModal"

const ProjectStaffsByService = ({
  destroyProjectStaffWithRefresh,
  destroyProjectStaffsWithRefresh,
  isServiceIdChecked,
  isServiceIdCheckedAll,
  onServiceIdChecked,
  onServiceIdCheckedAll,
  openEditProjectStaffModal,
  projectStaffs,
  resetSelectedServiceIds,
  selectedServiceIds,
  services,
  availableServicesIds,
  updateServiceWithRefresh,
  fetchMoreProjectStaffs,
  reduceServicesProjectStaffsCount
}) => {
  const projectStaffsByServiceId = groupBy(projectStaffs, "serviceId")
  const [isServiceModalOpen, serviceModalData, openServiceModal, closeServiceModal] = useModalTrigger({ service: null })
  const [isCommentModalOpen, commentModalData, openCommentModal, closeCommentModal] = useModalTrigger({ comment: null })

  const projectStaffsByService = (serviceId) => projectStaffsByServiceId[serviceId] || []

  const onDestroyProjectStaffs = async (service) => {
    const collection = projectStaffsByService(service.id)
      .filter((i) => !i.paymentIsIssued)
      .map(({ id }) => {
        return {
          id
        }
      })
    await destroyProjectStaffsWithRefresh(collection)

    const services = [
      {
        id: parseInt(service.id, 10),
        projectStaffsCount: projectStaffsByService(service.id).length
      }
    ]
    reduceServicesProjectStaffsCount(services)

    resetSelectedServiceIds()
  }

  const onDestroyProjectStaff = (service, projectStaff) => {
    destroyProjectStaffWithRefresh({ id: projectStaff.id })

    const services = [
      {
        id: parseInt(service.id, 10),
        projectStaffsCount: projectStaffsByService(service.id).length
      }
    ]
    reduceServicesProjectStaffsCount(services)
  }

  const onLoadMore = ({ service }) => {
    const offset = projectStaffsByService(service.id).length
    fetchMoreProjectStaffs({ serviceId: service.id, offset, isReserve: false })
  }

  return (
    <Fragment>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="actions one">
              <input
                type="checkbox"
                checked={isServiceIdCheckedAll}
                onChange={onServiceIdCheckedAll}
                disabled={availableServicesIds.length === 0}
              />
            </th>
            <th scope="col" colSpan="6"></th>
            <th scope="col" className="actions two" />
          </tr>
        </thead>
        <tbody>
          {services.map((service, index) => (
            <Fragment key={`service-${index}`}>
              <tr className="table-warning">
                <td className="actions one">
                  <input
                    type="checkbox"
                    checked={isServiceIdChecked(service.id)}
                    onChange={() => onServiceIdChecked(service.id)}
                    disabled={(service.brief || "").replace(/<\/?[^>]+(>|$)/g, "").trim().length === 0}
                  />
                </td>
                <td colSpan="6">
                  <strong>{service.innerName}</strong>&nbsp;({service.orderName}) &nbsp;&nbsp;&nbsp;
                  {service.working}
                  &nbsp;&nbsp;&nbsp;
                  <CountBadge projectStaffsCount={service.projectStaffsCount} quantity={service.quantity} />
                  <span className="thead-actions">
                    <Button size="sm" onClick={() => openServiceModal({ service: service })}>
                      Бриф
                    </Button>
                  </span>
                </td>
                <th scope="col" className="actions one text-right">
                  <Destroy
                    action={() => onDestroyProjectStaffs(service)}
                    disabled={!selectedServiceIds.includes(service.id)}
                  />
                </th>
              </tr>
              <tr className="table-primary">
                <th scope="col" className="actions one"></th>
                <th scope="col">№</th>
                <th scope="col">Фото</th>
                <th scope="col">ФИО</th>
                <th scope="col">Выдано</th>
                <th scope="col">Оценка</th>
                <th scope="col">Отзыв</th>
                <th scope="col" className="actions two" />
              </tr>
              {projectStaffsByService(service.id).map((projectStaff) => (
                <tr
                  key={`project-staff-${projectStaff.id}`}
                  className={classnames({
                    "table-success": projectStaff.paymentIsIssued
                  })}
                >
                  <td className="actions one"></td>
                  <td>{projectStaff.staff.id}</td>
                  <td>
                    <ImagePreview
                      src={projectStaff.staff.avatar}
                      width="75"
                      height="100"
                      className={projectStaff.staff.selfemployedRequisite.isSelfemployed ? "selfemployed-avatar" : ""}
                    />
                  </td>
                  <td>
                    <Link to={`/staffs/${projectStaff.staff.id}`}>{projectStaff.staff.fullName}</Link>
                  </td>
                  <td data-title={projectStaff.paymentHint}>
                    {projectStaff.isRegularTime ? "Штатное время" : formatMoney(projectStaff.payment)}
                  </td>
                  <td>{projectStaff.rating == 0 ? "Не пришел" : projectStaff.rating}</td>
                  <td>
                    <a
                      href="#"
                      className="open-modal"
                      onClick={() => openCommentModal({ comment: projectStaff.comment })}
                      dangerouslySetInnerHTML={{ __html: truncate(projectStaff.comment) }}
                    />
                  </td>
                  <td className="text-right">
                    <Edit onClick={() => openEditProjectStaffModal(projectStaff)} />
                    <Destroy
                      action={() => onDestroyProjectStaff(service, projectStaff)}
                      disabled={projectStaff.paymentIsIssued}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={8}>
                  <Button
                    variant="warning"
                    title="Загрузить еще"
                    onClick={() => onLoadMore({ service })}
                    disabled={service.projectStaffsCount <= projectStaffsByService(service.id).length}
                  >
                    <i className="fas fa-sync-alt"></i>
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      <ServiceModal
        isOpen={isServiceModalOpen}
        closeModal={closeServiceModal}
        service={serviceModalData.service}
        updateServiceWithRefresh={updateServiceWithRefresh}
      />
      <CommentModal isOpen={isCommentModalOpen} closeModal={closeCommentModal} comment={commentModalData.comment} />
    </Fragment>
  )
}

export default ProjectStaffsByService
