import React, { useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Paginate, Card } from "components/UI"
import useStaffs from "hooks/useStaffs"
import useMultipageSelect from "hooks/useMultipageSelect"
import Table from "./components/Table"
import Filter from "./components/Filter"

const StaffModal = ({ addStaffToPresentation, closeModal, isOpen, options, ...props }) => {
  const { staffs, fetchStaffs } = useStaffs(props.staffs)

  useEffect(() => {
    resetSelected()
  }, [isOpen])

  const [
    selectedStaffs,
    totalSelectedStaff,
    isCheckedStaff,
    onCheckedStaff,
    isCheckedAllStaffs,
    onCheckedAllStaffs,
    resetSelected
  ] = useMultipageSelect(staffs.data)

  const onSave = () => {
    addStaffToPresentation(selectedStaffs)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        <Card>
          <Filter
            options={options}
            collection={staffs}
            handleSubmit={async (values) => fetchStaffs({ filter: values })}
          />
        </Card>
        <Table
          collection={staffs}
          isCheckedStaff={isCheckedStaff}
          onCheckedStaff={onCheckedStaff}
          isCheckedAllStaffs={isCheckedAllStaffs}
          onCheckedAllStaffs={onCheckedAllStaffs}
        />
        <Paginate collection={staffs} fetch={fetchStaffs} modifyPath={false} />
      </ModalBody>
      <ModalFooter>
        <p>Выбрано: {totalSelectedStaff} чел.</p>
        <Button onClick={onSave} variant="success">
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default StaffModal
