import React from "react"
import { withFormik } from "formik"
import { TextInput, Submit } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const AddForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="projectIds" label="ID проектов" />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/payment_registries")
})(AddForm)
