import React, { useEffect, useState } from "react"
import { ButtonWithSpinner, Modal, ModalBody, ModalFooter, ModalHeader, Paginate, Card } from "components/UI"
import useRequisites from "hooks/useRequisites"
import useSelectAll from "hooks/useSelectAll"
import ReservationRequisiteApi from "api/reservationRequisite"
import Table from "./components/Table"
import Filter from "./components/Filter"

const RequisiteModal = ({ closeModal, isOpen, options, onReservationRequisitesAdded, ...props }) => {
  const [loading, setLoading] = useState(false)
  const { requisites, fetchRequisites } = useRequisites(props.requisites)

  useEffect(() => {
    resetSelected()
  }, [isOpen])

  const [
    selectedRequisites,
    totalSelectedRequisite,
    isCheckedRequisite,
    onCheckedRequisite,
    isCheckedAllRequisites,
    onCheckedAllRequisites,
    resetSelected
  ] = useSelectAll(requisites.pagination.totalCount)

  const onSave = async () => {
    setLoading(true)
    const { data } = await ReservationRequisiteApi.createCollection({
      requisiteIds: selectedRequisites.ids,
      type: selectedRequisites.type,
      searchId: requisites.searchId
    })
    setLoading(false)
    onReservationRequisitesAdded(data)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Реквизит</ModalHeader>
      <ModalBody>
        <Card>
          <Filter
            options={options}
            collection={requisites}
            handleSubmit={async (values) => fetchRequisites({ filter: values })}
          />
        </Card>
        <Table
          collection={requisites}
          isCheckedRequisite={isCheckedRequisite}
          onCheckedRequisite={onCheckedRequisite}
          isCheckedAllRequisites={isCheckedAllRequisites}
          onCheckedAllRequisites={onCheckedAllRequisites}
        />
        <Paginate collection={requisites} fetch={fetchRequisites} modifyPath={false} />
      </ModalBody>
      <ModalFooter>
        <p>Выбрано: {totalSelectedRequisite}</p>
        <ButtonWithSpinner onClick={onSave} variant="success" loading={loading}>
          Сохранить
        </ButtonWithSpinner>
      </ModalFooter>
    </Modal>
  )
}

export default RequisiteModal
