import { useState } from "react"

const useModalTrigger = (defaultData = null) => {
  const [modalTrigger, setModalTrigger] = useState({ isOpen: false, data: defaultData })

  const openModal = (data = null) => setModalTrigger({ isOpen: true, data: data && data["_reactName"] ? null : data })
  const closeModal = () => setModalTrigger({ isOpen: false, data: defaultData })

  return [modalTrigger.isOpen, modalTrigger.data, openModal, closeModal]
}

export default useModalTrigger
