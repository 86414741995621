import React, { Fragment } from "react"
import Breadcrumbs from "pages/Analytics/components/Breadcrumbs"
import TabsBlock from "pages/Analytics/components/TabsBlock"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

const ProjectsByContacts = ({ client, contacts, projects, ...props }) => (
  <Fragment>
    <Breadcrumbs>
      <BreadcrumbItem to="/analytics/clients">Клиенты</BreadcrumbItem>
      <BreadcrumbItem active>{client.name}</BreadcrumbItem>
      <BreadcrumbItem active>Проекты по контактам</BreadcrumbItem>
    </Breadcrumbs>
    <TabsBlock client={client} />
    <Table contacts={contacts} projects={projects} />
  </Fragment>
)

export default ProjectsByContacts
