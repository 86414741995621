import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useServices from "hooks/useServices"
import TabsBlock from "pages/ServiceSets/components/TabsBlock"
import Filter from "./components/Filter"
import Table from "./components/Table"

const List = ({ serviceSet, serviceOptions, ...props }) => {
  const { services, fetchServices, destroyServiceWithFetch } = useServices(props.services, `/service_sets/${serviceSet.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/service_sets/${serviceSet.id}`}>{serviceSet.name}</BreadcrumbItem>
        <BreadcrumbItem active>Услуги</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock serviceSet={serviceSet} />
      <Card>
        <Button url={`/service_sets/${serviceSet.id}/services/new`}>Создать</Button>
      </Card>
      <Card>
        <Filter
          options={serviceOptions}
          collection={services}
          handleSubmit={async (values) => fetchServices({ filter: values })}
        />
      </Card>
      <Table collection={services} destroyServiceSetWithFetch={destroyServiceWithFetch} serviceSet={serviceSet} />
      <Paginate collection={services} fetch={fetchServices} />
    </Fragment>
  )
}

export default List
