import React, { Fragment } from "react"
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import ProjectStaffForm from "./components/ProjectStaffForm"
import EmploymentForm from "./components/EmploymentForm"
import OverworkForm from "./components/OverworkForm"

const EditProjectStaffModal = ({
  staff,
  projectStaffs,
  employments,
  overworks,
  isOpen,
  closeModal,
  updateProjectStaffsWithRefresh,
  updateEmploymentsWithRefresh,
  updateOverworksWithRefresh
}) => {
  const projectStaffsHandleSubmit = async (data) => {
    await updateProjectStaffsWithRefresh(data)
  }

  const employmentsHandleSubmit = async (data) => {
    await updateEmploymentsWithRefresh(data)
  }

  const overworksHandleSubmit = async (data) => {
    await updateOverworksWithRefresh(data)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Персонал</ModalHeader>
      <ModalBody>
        <Card>
          <strong>Номер карты:</strong>&nbsp;{staff.cardNumber}
          <br /> <strong>Имя владельца:</strong>&nbsp;{staff.cardHolder}
        </Card>
        <ProjectStaffForm entity={projectStaffs} staff={staff} handleSubmit={projectStaffsHandleSubmit} />
        {staff.kind == "user" && (
          <Fragment>
            <br />
            <br />
            <EmploymentForm entity={employments} staff={staff} handleSubmit={employmentsHandleSubmit} />
          </Fragment>
        )}
        <br />
        <br />
        {overworks.length > 0 && <OverworkForm entity={overworks} staff={staff} handleSubmit={overworksHandleSubmit} />}
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>Закрыть</Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditProjectStaffModal
