import { useState } from "react"
import CourierApi from "api/courier"

const useCourier = (collection) => {
  const [courier, setCourier] = useState(collection)

  const createCourier = async (params) => {
    const { data } = await CourierApi.create(params)
    setCourier(data.courier)
    return data
  }

  const updateCourier = async (params) => {
    const { data } = await CourierApi.update(params)
    setCourier(data.courier)
    return data
  }

  return { courier, createCourier, updateCourier }
}

export default useCourier
