import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import usePermissions from "hooks/usePermissions"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, destroyProjectWithFetch }) => {
  const allow = usePermissions(["ADMIN"])
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Даты проекта</th>
          <th scope="col">Клиент</th>
          <th scope="col">Название</th>
          <th scope="col">Статус</th>
          <th scope="col">Аккаунт-Менеджер</th>
          <th scope="col">Менеджеры проекта</th>
          <th scope="col">Сумма проекта</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr key={`projects-${index}`}>
            <td>{i.id}</td>
            <td>{[formatDate(i.startedOn), formatDate(i.finishedOn)].filter(Boolean).join(" - ")}</td>
            <td>
              <Link to={`/clients/${i.client.id}`}>{i.client.name}</Link>
            </td>
            <td>
              <Link to={`/projects/${i.id}`}>{i.name}</Link>
            </td>
            <td>{i.status.label}</td>
            <td>{i.accountManager.fullName}</td>
            <td>{i.projectManagers}</td>
            <td>
              {formatMoney(i.estimate?.outerCost)}
              {i.estimate?.calculationKind?.value === "nds" && " НДС"}
            </td>
            <td>
              <Edit url={`/projects/${i.id}/edit`} disabled={!allow && (i.status?.value || i.status) === "finished_and_paid"} />
              <Destroy
                action={() => destroyProjectWithFetch({ id: i.id })}
                disabled={!allow && (i.status?.value || i.status) === "finished_and_paid"}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
