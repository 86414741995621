import React from "react"
import { withFormik } from "formik"
import { Button, Submit, DateTimeInput } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import formSubmitHandler from "utils/formSubmitHandler"
import RequisiteModal from "./components/RequisiteModal"
import ReservationRequisiteModal from "./components/ReservationRequisiteModal"
import ReservationRequisites from "./components/ReservationRequisites"

const Form = ({ requisites, requisiteOptions, values, handleSubmit, setFieldValue, ...props }) => {
  const [isRequisiteModalOpen, requisiteModalData, openRequisiteModal, closeRequisiteModal] = useModalTrigger()
  const [
    isReservationRequisiteModalOpen,
    reservationRequisiteModalData,
    openReservationRequisiteModal,
    closeReservationRequisiteModal
  ] = useModalTrigger()

  const onReservationRequisitesAdded = ({ reservationRequisites }) =>
    setFieldValue("reservationRequisites", values.reservationRequisites.concat(reservationRequisites))

  const onReservationRequisiteUpdate = (reservationRequisite) => {
    const index = values.reservationRequisites.findIndex(({ id }) => id === reservationRequisite.id)
    setFieldValue(`reservationRequisites.${index}`, reservationRequisite)
  }

  const markReservationRequisiteForDestroy = (id) => {
    const index = values.reservationRequisites.findIndex((i) => i.id === id)
    setFieldValue(`reservationRequisites.${index}`, { ...values.reservationRequisites[index], _destroy: true })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DateTimeInput name="startedAt" label="Отгрузка со склада" required />
      <DateTimeInput name="finishedAt" label="Возврат на склад" required />
      <div className="mb-3">
        <Button onClick={openRequisiteModal}>Добавить реквизит</Button>
      </div>
      <RequisiteModal
        closeModal={closeRequisiteModal}
        onReservationRequisitesAdded={onReservationRequisitesAdded}
        isOpen={isRequisiteModalOpen}
        options={requisiteOptions}
        requisites={requisites}
      />

      <ReservationRequisiteModal
        reservationRequisite={reservationRequisiteModalData}
        isOpen={isReservationRequisiteModalOpen}
        closeModal={closeReservationRequisiteModal}
        onReservationRequisiteUpdate={onReservationRequisiteUpdate}
      />

      <ReservationRequisites
        values={values}
        openReservationRequisiteModal={openReservationRequisiteModal}
        markReservationRequisiteForDestroy={markReservationRequisiteForDestroy}
      />
      <Submit/>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/projects/${values.projectId}/reservations`)
})(Form)
