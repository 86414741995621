import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useServiceSet from "hooks/useServiceSet"
import Form from "pages/ServiceSets/components/Form"

const Add = (props) => {
  const { serviceSet, createServiceSet } = useServiceSet(props.serviceSet)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={serviceSet} handleSubmit={async (values) => createServiceSet(values)} />
    </Fragment>
  )
}

export default Add
