import React from "react"
import { Destroy, Edit } from "components/UI"

const Table = ({ collection, destroyDocumentWithFetch, client }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Файл</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`document-${index}`}>
          <td>
            <a href={i.file?.file} target="_blank">{i.name}</a>
          </td>
          <td>
            <Edit url={`/clients/${client.id}/documents/${i.id}/edit`} />
            <Destroy action={() => destroyDocumentWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
