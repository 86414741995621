import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"
import Show from "./containers/Show"
import Estimates from "./containers/Estimates"
import Expenses from "./containers/Expenses"
import Teams from "./containers/Teams"
import AccountPayments from "./containers/AccountPayments"
import Employments from "./containers/Employments"
import Reservations from "./containers/Reservations"
import Cashes from "./containers/Cashes"
import Services from "./containers/Services"
import Payments from "./containers/Payments"
import Documents from "./containers/Documents"
import Feedbacks from "./containers/Feedbacks"

const Projects = () => (
  <Routes>
    <Route
      path="/"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/projects/new"
      fetchData={async (params, _, { signal }) => {
        const { pathname, search } = window.location
        const { data } = await pageInstance.get(`${pathname}${search}`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/edit"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
    <Route path="/projects/:projectId/estimates" component={Estimates} />
    <Route path="/projects/:projectId/expenses" component={Expenses} />
    <Route path="/projects/:projectId/managers/:managerId/employments" component={Employments} />
    <Route path="/projects/:projectId/account_payments" component={AccountPayments} />
    <Route path="/projects/:projectId/reservations" component={Reservations} />
    <Route path="/projects/:projectId/teams" component={Teams} />
    <Route path="/projects/:projectId/cashes" component={Cashes} />
    <Route path="/projects/:projectId/services" component={Services} />
    <Route path="/projects/:projectId/payments" component={Payments} />
    <Route path="/projects/:projectId/documents" component={Documents} />
    <Route path="/projects/:projectId/feedbacks" component={Feedbacks} />
  </Routes>
)
export default Projects
