import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Paginate, Card } from "components/UI"
import useStaffs from "hooks/useStaffs"
import useMultipageSelect from "hooks/useMultipageSelect"
import Table from "./components/Table"
import Filter from "./components/Filter"

const StaffModal = ({
  closeModal,
  createVacancyWithRefresh,
  isOpen,
  options,
  vacancies,
  serviceIds,
  increaseServicesVacanciesCount,
  ...props
}) => {
  const { staffs, fetchStaffs } = useStaffs(props.staffs)

  useEffect(() => {
    resetSelected()
  }, [isOpen])

  const [disabled, setDisabled] = useState(false)

  const [
    selectedStaffs,
    totalSelectedStaff,
    isCheckedStaff,
    onCheckedStaff,
    isCheckedAllStaffs,
    onCheckedAllStaffs,
    resetSelected
  ] = useMultipageSelect(staffs.data)

  const onSave = async () => {
    setDisabled(true)
    const data = await createVacancyWithRefresh({
      serviceIds,
      staffIds: selectedStaffs.map(({ id }) => id)
    })

    // {
    //   id: 1,
    //   vacanciesCount: 10
    // }

    const groupedByServiceId = data.vacancy.reduce((a, b) => {
      a[b.serviceId] = a[b.serviceId] || []
      a[b.serviceId].push(b)
      return a
    }, {})

    const services = Object.keys(groupedByServiceId).map(id => {
      return {
        id,
        vacanciesCount: groupedByServiceId[id].length
      }
    })

    increaseServicesVacanciesCount(services)
    setDisabled(false)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader disabled={disabled} />
      <ModalBody>
        <Card>
          <Filter
            options={options}
            collection={staffs}
            handleSubmit={async (values) => fetchStaffs({ filter: values })}
          />
        </Card>
        <Table
          collection={staffs}
          isCheckedStaff={isCheckedStaff}
          onCheckedStaff={onCheckedStaff}
          isCheckedAllStaffs={isCheckedAllStaffs}
          onCheckedAllStaffs={onCheckedAllStaffs}
        />
        <Paginate collection={staffs} fetch={fetchStaffs} modifyPath={false} />
      </ModalBody>
      <ModalFooter>
        <p>Выбрано: {totalSelectedStaff} чел.</p>
        <Button onClick={onSave} variant="success" disabled={disabled}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default StaffModal
