import React from "react"
import { Tab, Tabs } from "components/UI"

const TabsBlock = ({ project: { id } }) => {
  const mappings = [
    ["Карточка проекта", `/projects/${id}`, `/projects/${id}`],
    [
      "Команда",
      `/projects/${id}/teams`,
      (path) => /\/projects\/\d+\/teams/.test(path) || /\/projects\/\d+\/managers\/\d+\/employments/.test(path)
    ],
    [
      "Финансы",
      `/projects/${id}/estimates`,
      (path) =>
        /\/projects\/\d+\/estimates/.test(path) ||
        /\/projects\/\d+\/expenses/.test(path) ||
        /\/projects\/\d+\/payments/.test(path) ||
        /\/projects\/\d+\/cashes/.test(path)
    ],
    [
      "Выплаты",
      `/projects/${id}/account_payments`,
      (path) => /\/projects\/\d+\/account_payments/.test(path) || /\/projects\/\d+\/manager_payments/.test(path)
    ],
    ["Реквизит", `/projects/${id}/reservations`, `/projects/${id}/reservations`],
    ["Документы", `/projects/${id}/documents`, `/projects/${id}/documents`],
    ["Отзывы", `/projects/${id}/feedbacks`, `/projects/${id}/feedbacks`]
  ]

  const paths = Object.keys(mappings)
  const onSelect = (i) => window.history.pushState(null, "", mappings[i][1])
  const activeKey = paths.findIndex((i) => {
    const mapping = mappings[i][2]
    if (typeof mapping === "function") {
      return mapping(window.location.pathname.replace(/\/+$/, ""))
    }
    return mapping === window.location.pathname.replace(/\/+$/, "")
  })

  return (
    <Tabs activeKey={activeKey} onSelect={onSelect}>
      {mappings.map((i, index) => (
        <Tab key={index} eventKey={index} title={i[0]} />
      ))}
    </Tabs>
  )
}

export default TabsBlock
