import { useState } from "react"
import RequestApi from "api/request"

const useRequest = (collection) => {
  const [request, setRequest] = useState(collection)

  const createRequest = async (params) => {
    const { data } = await RequestApi.create(params)
    setRequest(data.request)
    return data
  }

  const updateRequest = async (params) => {
    const { data } = await RequestApi.update(params)
    setRequest(data.request)
    return data
  }

  return { request, createRequest, updateRequest }
}

export default useRequest
