import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"

const Reservations = () => (
  <Routes>
    <Route
      path="/reservations"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/reservations`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/reservations/:reservationId/edit"
      fetchData={async ({ reservationId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/reservations/${reservationId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Reservations
