import React from "react"
import Form from "pages/Clients/containers/Entities/components/Form"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import useEntity from "hooks/useEntity"

const EntityModal = ({ clientId, isOpen, closeModal, onEntityAdded }) => {
  const { createEntity } = useEntity({})

  const handleSubmit = async (values) => {
    const { entity } = await createEntity(values)
    onEntityAdded({ label: entity.name, value: entity.id })
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать юридическое лицо</ModalHeader>
      <ModalBody>
        <Form entity={{ clientId }} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EntityModal
