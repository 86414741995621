import React from "react"
import { withFormik } from "formik"
import { DateInput, SelectInput, Submit, CurrencyInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import formatMoney from "utils/formatMoney"

const Form = ({ paymentOptions, values, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <CurrencyInput name="cost" label="Сумма" hint={`Не более ${formatMoney(values.availableCost)}`} required />
    <DateInput name="dateOn" label="Дата" required />
    <SelectInput name="status" label="Статус" options={paymentOptions.statuses} />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => ({ ...entity, status: entity.status.value }),
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/projects/${values.projectId}/payments`)
})(Form)
