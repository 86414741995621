import { useState } from "react"
import StaffEmploymentsCalendarApi from "api/staffEmployments"

const useStaffEmployments = (collection, url = null) => {
  const [employments, setEmployments] = useState(collection)

  const fetchStaffEmploymentsCalendar = async (params) => {
    const { data } = await StaffEmploymentsCalendarApi.setBaseURL(url).fetch(params)
    setEmployments(data.employments)
    return data
  }

  const createEmployment = async (params) => await StaffEmploymentsCalendarApi.create(params)
  const createEmploymentWithRefresh = async (params) => {
    const { data } = await createEmployment(params)
    setEmployments({
      ...employments,
      data: employments.data.concat([data.employment])
    })
  }

  const destroyEmployment = async (params) => await StaffEmploymentsCalendarApi.destroy(params)
  const destroyEmploymentWithRefresh = async (params) => {
    const { data } = await destroyEmployment(params)
    setEmployments({
      ...employments,
      data: employments.data.filter((i) => i.id !== data.employment.id)
    })
  }

  return { employments, fetchStaffEmploymentsCalendar, createEmploymentWithRefresh, destroyEmploymentWithRefresh }
}

export default useStaffEmployments
