import instance from "connection/instance"

const prepare = (data = {}) => {
  const { client, entity, contact, ...other } = data
  return {
    ...other,
    clientId: client?.value || null,
    entityId: entity?.value || null,
    contactId: contact?.value || null
  }
}

const Courier = {
  fetch: (params) => instance.get("/couriers", { params }),
  create: (data) => instance.post("/couriers", { data: prepare(data) }),
  update: (data) => instance.put(`/couriers/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/couriers/${data.id}`),
  exportToPdf: (data) =>
    instance.get(`/couriers/${data.id}/export_to_pdf`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    })
}

export default Courier
