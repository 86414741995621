import React, { Fragment, useState } from "react"
import { withFormik } from "formik"
import { ImagePreview, Card, SelectInput, Submit, Button } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import ReservationApi from "api/reservation"
import download from "utils/download"
import usePermissions from "hooks/usePermissions"

const Form = ({ options, values, handleSubmit }) => {
  const allowRealizationDepartament = usePermissions(["ADMIN", "WAREHOUSE"])
  const [disabled, setDisabled] = useState(false)

  const onExport = async () => {
    setDisabled(true)
    const { data } = await ReservationApi.export({ id: values.id })
    setDisabled(false)
    download(data, `${values.id}.pdf`)
  }
  return (
    <Fragment>
      <Card>
        <Button variant="warning" className="float-right" onClick={onExport} disabled={disabled}>
          Экспорт в .pdf
        </Button>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-lg-6">Отгрузка - Возврат</td>
            <td>
              {formatDateTime(values.startedAt)} - {formatDateTime(values.finishedAt)}
            </td>
          </tr>
          <tr>
            <td>Дата заказа</td>
            <td>{formatDate(values.createdAt)}</td>
          </tr>
          <tr>
            <td>Проект</td>
            <td>{values.project.name}</td>
          </tr>
          <tr>
            <td>Аккаунт-менеджер</td>
            <td>{values.project.accountManager.fullName}</td>
          </tr>
        </tbody>
      </table>
      <Card>
        <form onSubmit={handleSubmit}>
          <SelectInput label="Статус" name="status.value" options={options.statuses} disabled={!allowRealizationDepartament} />
          <Submit />
        </form>
      </Card>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Изображение</th>
            <th>Наименование</th>
            <th>Забронировано</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {values.reservationRequisites.map((i) => (
            <tr key={`reservation-requisite-${i.id}`}>
              <td>{i.requisite.name}</td>
              <td>
                <ImagePreview src={i.requisite.file} />
              </td>
              <td>{i.count}</td>
              <td>{i.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/reservations")
})(Form)
