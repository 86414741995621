import { historyPushToStore } from "utils/historyPushWithStore"

const filterSubmitHandler = async (values, formikBag) => {
  const {
    props: { handleSubmit },
    setSubmitting
  } = formikBag

  try {
    const data = await handleSubmit(values)
    historyPushToStore(window.location.pathname, `?search_id=${data.searchId}`)
    return data
  } finally {
    setSubmitting(false)
  }
}

export default filterSubmitHandler
