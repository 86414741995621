import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useDocument from "hooks/useDocument"
import Form from "pages/Projects/containers/Documents/components/Form"

const Edit = ({ project, documentOptions, ...props }) => {
  const { document, updateDocument } = useDocument(props.document)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/documents`}>Документы</BreadcrumbItem>
        <BreadcrumbItem active>{document.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...document, projectId: project.id }}
        options={documentOptions}
        handleSubmit={async (values) => updateDocument({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
