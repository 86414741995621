import React, { Fragment } from "react"
import Breadcrumbs from "pages/Skills/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useSkill from "hooks/useSkill"
import Form from "pages/Skills/components/Form"

const Edit = (props) => {
  const { skill, updateSkill } = useSkill(props.skill)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{skill.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={skill} handleSubmit={async (values) => updateSkill(values)} />
    </Fragment>
  )
}

export default Edit
