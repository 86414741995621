import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useClient from "hooks/useClient"
import Form from "pages/Clients/components/Form"

const Edit = ({ clientOptions, ...props }) => {
  const { client, updateClient } = useClient(props.client)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={client} options={clientOptions} handleSubmit={async (values) => updateClient(values)} />
    </Fragment>
  )
}

export default Edit
