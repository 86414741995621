import React, { useState, useEffect, useCallback } from "react"
import { withFormik } from "formik"
import { Button, Submit, CheckboxInput, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import { Prompt } from "components/Router"
import TemplatesModal from "./components/TemplatesModal"
import CloneModal from "./components/CloneModal"
import ServiceFormModal from "./components/ServiceFormModal"
import Table from "./components/Table"

const Form = ({ options, values, handleSubmit, setFieldValue, initialValues }) => {
  const hasChanges = () => initialValues !== values
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false)
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  const [isServiceFormModalOpen, setIsServiceFormModalOpen] = useState(false)
  const [serviceId, setServiceId] = useState(null)
  const openTemplatesModal = () => setIsTemplatesModalOpen(true)
  const closeTemplatesModal = () => setIsTemplatesModalOpen(false)

  const onTemplatesServicesAdded = ({ services }) => {
    closeTemplatesModal()
    setFieldValue("services", values.services.concat(services))
  }

  const openCloneModal = (id) => {
    setServiceId(id)
    setIsCloneModalOpen(true)
  }

  const closeCloneModal = () => setIsCloneModalOpen(false)

  const onCloneServicesAdded = ({ services }) => {
    closeCloneModal()
    setFieldValue("services", values.services.concat(services))
  }

  const openServiceFormModal = (id) => {
    setServiceId(id)
    setIsServiceFormModalOpen(true)
  }

  const closeServiceFormModal = () => setIsServiceFormModalOpen(false)

  const onServiceUpdate = (service) => {
    const index = values.services.findIndex(({ id }) => id === service.id)
    setFieldValue(`services.${index}`, service)
    closeServiceFormModal()
  }

  const markServiceForDestroy = (id) => {
    const index = values.services.findIndex((i) => i.id === id)
    setFieldValue(`services.${index}`, { ...values.services[index], _destroy: true })
  }

  const onCopyServicesAdded = ({ services }) => {
    setFieldValue("services", values.services.concat(services))
  }

  return (
    <Prompt sss={() => hasChanges()}>
      <form onSubmit={handleSubmit}>
        <TextInput name="name" label="Название" required />
        <div className="mb-3">
          <CheckboxInput name="isDefault" label="Основная смета" />
        </div>
        <CheckboxInput name="isWithAc" label="Агентская комиссия" />
        <TextInput
          name="ac"
          type="number"
          min="0"
          max="100"
          label="Размер агентской комиссии, %"
          disabled={!values.isWithAc}
        />
        <RadioButtonGroup label="Тип расчета" name="calculationKind">
          {options.calculationKinds.map(({ label, value }) => (
            <RadioInput key={`calculationKind-${value}`} label={label} value={value} />
          ))}
        </RadioButtonGroup>
        <div className="mb-3">
          <Button onClick={openTemplatesModal}>Добавить услуги</Button>
        </div>
        <TemplatesModal
          services={options.services}
          isOpen={isTemplatesModalOpen}
          closeModal={closeTemplatesModal}
          onServicesAdd={onTemplatesServicesAdded}
        />
        <CloneModal
          id={serviceId}
          isOpen={isCloneModalOpen}
          closeModal={closeCloneModal}
          onServicesAdd={onCloneServicesAdded}
        />
        <ServiceFormModal
          service={values.services.find((i) => i.id === serviceId)}
          isOpen={isServiceFormModalOpen}
          closeModal={closeServiceFormModal}
          onServiceUpdate={onServiceUpdate}
        />
        <Table
          kinds={options.services.kinds}
          values={values}
          openServiceFormModal={openServiceFormModal}
          openCloneModal={openCloneModal}
          markServiceForDestroy={markServiceForDestroy}
          onCopyServicesAdded={onCopyServicesAdded}
        />
        <Submit />
      </form>
    </Prompt>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/projects/${values.projectId}/estimates`)
})(Form)
