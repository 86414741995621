import React, { useState } from "react"
import Card from "react-bootstrap/Card"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button } from "components/UI"
import useVacancies from "hooks/useVacancies"
import useModal from "hooks/useModal"
import useSelect from "hooks/useSelect"
import useServices from "hooks/useServices"
import TabsBlock from "pages/Projects/components/TabsBlock"
import isAfter from "date-fns/isAfter"
import StaffModal from "./components/StaffModal"
import VacanciesByService from "./components/VacanciesByService"

const Vacancies = ({ project, staffs, staffOptions, overworks, ...props }) => {
  const [isStaffModalOpen, openStaffModal, closeStaffModal] = useModal(false)
  const {
    services,
    updateServiceWithRefresh,
    increaseServicesVacanciesCount,
    increaseServicesAcceptVacanciesCount,
    reduceServicesAcceptVacanciesCount
  } = useServices(props.services)

  const [intersectingServices, setIntersectingServices] = useState(props.intersectingServices)

  const {
    vacancies,
    createVacancyWithRefresh,
    destroyVacancyWithRefresh,
    destroyVacanciesWithRefresh,
    closeVacanciesWithRefresh,
    acceptVacanciesWithRefresh,
    rejectVacanciesWithRefresh,
    reserveVacanciesWithRefresh,
    fetchMoreVacancies
  } = useVacancies(props.vacancies)

  const availableServicesIds = services
    .filter(
      (i) => (i.brief || "").replace(/<\/?[^>]+(>|$)/g, "").trim().length !== 0 && !isAfter(new Date(), i.startedAt)
    )
    .map((i) => i.id)

  const [
    selectedServiceIds,
    totalServiceIdsSelected,
    isServiceIdChecked,
    onServiceIdChecked,
    isServiceIdCheckedAll,
    onServiceIdCheckedAll,
    resetSelectedServiceIds
  ] = useSelect(availableServicesIds)

  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem active>Вакансии</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Card.Body>
          <Button variant="success" disabled={selectedServiceIds.length === 0} onClick={openStaffModal}>
            Создать
          </Button>
        </Card.Body>
      </Card>
      <VacanciesByService
        destroyVacancyWithRefresh={destroyVacancyWithRefresh}
        destroyVacanciesWithRefresh={destroyVacanciesWithRefresh}
        isServiceIdChecked={isServiceIdChecked}
        isServiceIdCheckedAll={isServiceIdCheckedAll}
        onServiceIdChecked={onServiceIdChecked}
        onServiceIdCheckedAll={onServiceIdCheckedAll}
        vacancies={vacancies}
        resetSelectedServiceIds={resetSelectedServiceIds}
        selectedServiceIds={selectedServiceIds}
        services={services}
        availableServicesIds={availableServicesIds}
        intersectingServices={intersectingServices}
        setIntersectingServices={setIntersectingServices}
        updateServiceWithRefresh={updateServiceWithRefresh}
        closeVacanciesWithRefresh={closeVacanciesWithRefresh}
        acceptVacanciesWithRefresh={acceptVacanciesWithRefresh}
        rejectVacanciesWithRefresh={rejectVacanciesWithRefresh}
        reserveVacanciesWithRefresh={reserveVacanciesWithRefresh}
        fetchMoreVacancies={fetchMoreVacancies}
        increaseServicesAcceptVacanciesCount={increaseServicesAcceptVacanciesCount}
        reduceServicesAcceptVacanciesCount={reduceServicesAcceptVacanciesCount}
        overworks={overworks}
      />
      <StaffModal
        closeModal={() => {
          resetSelectedServiceIds()
          closeStaffModal()
        }}
        createVacancyWithRefresh={createVacancyWithRefresh}
        isOpen={isStaffModalOpen}
        options={staffOptions}
        vacancies={vacancies}
        serviceIds={selectedServiceIds}
        staffs={staffs}
        increaseServicesVacanciesCount={increaseServicesVacanciesCount}
      />
    </DisabledPage>
  )
}

export default Vacancies
