import React from "react"
import { Destroy, Edit } from "components/UI"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, destroyProjectWithFetch, project }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Сумма</th>
        <th scope="col">Дата</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`payments-${index}`}>
          <td>{formatMoney(i.cost)}</td>
          <td>{formatDate(i.dateOn)}</td>
          <td>{i.status.label}</td>
          <td>
            <Edit url={`/projects/${project.id}/payments/${i.id}/edit`} />
            <Destroy action={() => destroyProjectWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
