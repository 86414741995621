import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { Card, Button, BreadcrumbItem } from "components/UI"
import useOverworks from "hooks/useOverworks"
import useModalTrigger from "hooks/useModalTrigger"
import TabsBlock from "pages/Projects/components/TabsBlock"
import EditOverworkModal from "./components/EditOverworkModal"
import Table from "./components/Table"

const Overworks = ({ project, service, staff, ...props }) => {
  const { overworks, createOverworkWithRefresh, updateOverworkWithRefresh, destroyOverworkWithRefresh } = useOverworks(props.overworks)

  const [isEditOverworkModalOpen, editProjectStaffModalData, openEditOverworkModal, closeEditOverworkModal] =
    useModalTrigger()

  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams/overworks/${service.id}`}>Переработки</BreadcrumbItem>
        <BreadcrumbItem active>{service.innerName}</BreadcrumbItem>
        <BreadcrumbItem active>{staff.fullName}</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Button
          onClick={() => openEditOverworkModal({ project_id: project.id, staff_id: staff.id, service_id: service.id })}
        >
          Создать
        </Button>
      </Card>
      <Table
        openEditOverworkModal={openEditOverworkModal}
        destroyOverworkWithRefresh={destroyOverworkWithRefresh}
        project={project}
        service={service}
        overworks={overworks}
      />
      <EditOverworkModal
        closeModal={closeEditOverworkModal}
        isOpen={isEditOverworkModalOpen}
        overwork={editProjectStaffModalData}
        createOverworkWithRefresh={createOverworkWithRefresh}
        updateOverworkWithRefresh={updateOverworkWithRefresh}
      />
    </DisabledPage>
  )
}

export default Overworks
