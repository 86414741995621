import React from "react"
import { Link } from "components/Router"
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"

const BreadcrumbItem = ({ active = false, to, children }) => (
  <BootstrapBreadcrumb.Item active={active} href={to} linkAs={(i) => <Link to={to}>{children}</Link>}>
    {children}
  </BootstrapBreadcrumb.Item>
)

export default BreadcrumbItem
