import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import formatMoney from "utils/formatMoney"

const ShowCostModal = ({ closeModal, isOpen, data }) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal}>
    <ModalHeader />
    <ModalBody>
      {data.extendedProps.additional && (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>ID проекта</th>
              <th>Аккаунт-менеджер</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {data.extendedProps.additional.map((i, index) => (
              <tr key={`service-${index}`}>
                <td>{i.projectId}</td>
                <td>{i.accountManager}</td>
                <td>{formatMoney(i.innerCost)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </ModalBody>
    <ModalFooter>
      <Button onClick={closeModal} variant="secondary">
        Закрыть
      </Button>
    </ModalFooter>
  </Modal>
)

export default ShowCostModal
