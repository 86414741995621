import React, { useState } from "react"
import { Button, EmailInput, PhoneInput, TextInput, ResetButton, Submit } from "components/UI"
import { withFormik } from "formik"
import UserSelect from "components/Common/UserSelect"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ClientApi from "api/client"
import download from "utils/download"
import usePermissions from "hooks/usePermissions"

const Filter = ({ collection, handleSubmit }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async () => {
    try {
      setDisabled(true)
      const { data } = await ClientApi.export()
      download(data, "Список клиентов.xlsx")
    } finally {
      setDisabled(false)
    }
  }

  const allow = usePermissions(["ADMIN"])
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="byName" label="Название" />
        </div>
        <div className="col-3">
          <PhoneInput name="byPhone" label="Телефон" />
        </div>
        <div className="col-3">
          <EmailInput name="byEmail" label="Email" />
        </div>
        <div className="col-3">
          <TextInput name="byContact" label="Контактное лицо" />
        </div>
        <div className="col-3">
          <UserSelect name="byManager" label="Менеджер" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;
      <Button disabled={!allow || disabled} onClick={() => onExport("xlsx", false)}>
        Выгрузить в Excel
      </Button>
      &nbsp;&nbsp;&nbsp;Найдено: <strong>{collection.pagination.totalCount}</strong>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
})(Filter)
