import React from "react"
import { withFormik } from "formik"
import { TextInput, CurrencyInput, Submit } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="description" label="Наименование внутреннее" required />
    <CurrencyInput name="cost" label="Фактический расход" required />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/projects/${values.projectId}/expenses`)
})(Form)
