import { useState } from "react"
import CourierApi from "api/courier"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useCouriers = (collection) => {
  const [couriers, setCouriers] = useState(collection)

  const fetchCouriers = async (params) => {
    const { data } = await CourierApi.fetch(params)
    setCouriers(data.couriers)
    return data.couriers
  }

  const destroyCourier = async (params) => CourierApi.destroy(params)
  const destroyCourierWithFetch = async (params) => afterDestroyFetch(couriers, fetchCouriers, destroyCourier, params)

  return { couriers, fetchCouriers, destroyCourier, destroyCourierWithFetch }
}

export default useCouriers
