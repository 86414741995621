import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Estimates = () => (
  <Routes>
    <Route
      path="/projects/:projectId/estimates"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/estimates`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/estimates/:estimateId"
      fetchData={async ({ projectId, estimateId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/estimates/${estimateId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/projects/:projectId/estimates/new"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/estimates/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/estimates/:estimateId/edit"
      fetchData={async ({ projectId, estimateId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/estimates/${estimateId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Estimates
