import React from "react"
import formatDate from "utils/formatDate"
import groupBy from "lodash.groupby"
import Badge from "react-bootstrap/Badge"

const Table = ({ contacts, projects }) => {
  const groupByContactId = groupBy(projects, "contact.id")
  return contacts.map((contact) => (
    <table key={`contact-${contact.id}`} className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th colSpan={5}>
            {contact.fullName}&nbsp;&nbsp;&nbsp;<Badge>{(groupByContactId[contact.id] || []).length}</Badge>
          </th>
        </tr>
        <tr>
          <th>Название</th>
          <th className="col-lg-3">Аккаунт-Менеджер</th>
          <th className="col-lg-3">Статус</th>
          <th className="col-lg-3">Даты проекта</th>
        </tr>
      </thead>
      <tbody>
        {(groupByContactId[contact.id] || []).map((row, index) => (
          <tr key={`projects-${index}`}>
            <td>{row.name}</td>
            <td>{row.accountManager.fullName}</td>
            <td>{row.status.label}</td>
            <td>{[formatDate(row.startedOn), formatDate(row.finishedOn)].filter(Boolean).join(" - ")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ))
}

export default Table
