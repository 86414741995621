import React from "react"
import { withFormik } from "formik"
import {
  AddressInput,
  CheckboxInput,
  CodeInput,
  DateInput,
  EmailInput,
  PhoneInput,
  RadioButtonGroup,
  RadioInput,
  SelectInput,
  Submit,
  TextInput,
  UploadImage,
  UploadInput
} from "components/UI"
import Card from "react-bootstrap/Card"
import formSubmitHandler from "utils/formSubmitHandler"
import Emails from "components/Common/Emails"
import Phones from "components/Common/Phones"
import usePermissions from "hooks/usePermissions"

const Form = ({ options, handleSubmit }) => {
  const allow = usePermissions(["ADMIN"])
  const { citizenships, positions, roles, sexes } = options
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Card.Header>Учетная запись</Card.Header>
        <Card.Body>
          <PhoneInput name="account.login" label="Логин" />
          <CheckboxInput name="account.isActive" label="Учетная запись активна?" />
        </Card.Body>
      </Card>
      <TextInput name="passport.lastName" label="Фамилия" />
      <TextInput name="passport.firstName" label="Имя" />
      <TextInput name="passport.middleName" label="Отчество" />
      <RadioButtonGroup label="Пол" name="passport.sex">
        {sexes.map(({ label, value }) => (
          <RadioInput key={`passport-sex-${value}`} label={label} value={value} />
        ))}
      </RadioButtonGroup>
      <SelectInput name="passport.citizenship" label="Гражданство" options={citizenships} isClearable />
      <DateInput name="passport.birthdayOn" label="Дата рождения" />
      <TextInput name="passport.placeOfBirth" label="Место рождения" />
      <TextInput name="passport.number" label="Номер и серия" />
      <TextInput name="passport.issuedBy" label="Паспорт выдан" />
      <DateInput name="passport.issuedOn" label="Дата выдачи" />
      <CodeInput name="passport.code" label="Код подразделения" />
      <AddressInput name="passport.registration" label="Регистрация" />
      <UploadInput name="passport.firstScan" label="Скан разворот" />
      <UploadInput name="passport.secondScan" label="Скан прописка" />

      <SelectInput name="positionIds" label="Позиция" options={positions} isMulti isClearable />
      <SelectInput name="roleIds" label="Роли" options={roles} isMulti isClearable disabled={!allow} />
      <TextInput name="comment" label="Комментарий" />
      <AddressInput name="address" label="Фактический адрес" />
      <TextInput name="staffId" label="ID раздела «Персонал»" type="number" min="1" />
      <UploadImage name="avatar" label="Фотография" />

      <Emails />
      <Phones />
      <EmailInput name="workEmail" label="Рабочий email" />
      <PhoneInput name="workPhone" label="Рабочий телефон" />
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { passport, positions, roles } = entity
    return {
      ...entity,
      passport: passport ? { ...passport, citizenship: passport.citizenship.value } : {},
      positionIds: positions ? positions.map((i) => i.id) : [],
      roleIds: roles ? roles.map((i) => i.id) : []
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/users")
})(Form)
