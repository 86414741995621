import React, { Fragment } from "react"
import Breadcrumbs from "pages/Reservations/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useReservation from "hooks/useReservation"
import Form from "pages/Reservations/components/Form"

const Edit = ({ reservationOptions, ...props }) => {
  const { reservation, updateReservation } = useReservation(props.reservation)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{reservation.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={reservation} options={reservationOptions} handleSubmit={async (values) => updateReservation(values)} />
    </Fragment>
  )
}

export default Edit
