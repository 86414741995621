import { useState } from "react"
import RequisiteApi from "api/requisite"

const useRequisite = (collection) => {
  const [requisite, setRequisite] = useState(collection)

  const createRequisite = async (params) => {
    const { data } = await RequisiteApi.create(params)
    setRequisite(data.requisite)
    return data
  }

  const updateRequisite = async (params) => {
    const { data } = await RequisiteApi.update(params)
    setRequisite(data.requisite)
    return data
  }

  return { requisite, createRequisite, updateRequisite }
}

export default useRequisite
