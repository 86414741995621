import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const Skills = () => (
  <Routes>
    <Route
      path="/skills"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/skills`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/skills/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/skills/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/skills/:skillId/edit"
      fetchData={async ({ skillId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/skills/${skillId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Skills
