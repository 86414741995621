import React from "react"
import { withFormik } from "formik"
import { UploadInput, Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название" required />
    <UploadInput name="file" label="Файл" />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/clients/${values.clientId}/documents`)
})(Form)
