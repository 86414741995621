import React from "react"
import { Link } from "components/Router"
import Card from "react-bootstrap/Card"

const Vacancies = ({ vacancies, project }) => (
  <Card>
    <Card.Header>Вакансии</Card.Header>
    <Card.Body>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">Позиция</th>
            <th scope="col" className="col-3">
              Количество (набрано)
            </th>
          </tr>
        </thead>
        <tbody>
          {vacancies.map((i, index) => (
            <tr key={`vacancy-${index}`}>
              <td>
                <Link to={`/projects/${project.id}/teams/vacancies/${i.id}`}>{i.innerName}</Link>
              </td>
              <td>
                {i.projectStaffsCount} из {i.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card.Body>
  </Card>
)

export default Vacancies
