import React from "react"
import { withFormik } from "formik"
import { DateInput, SelectInput, TextInput, Submit } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="number" label="Номер" required />
    <SelectInput name="entity.id" label="Заказчик" options={options.entities} />
    <SelectInput name="executor.id" label="Исполнитель" options={options.executors} />
    <DateInput name="dateOn" label="От" />
    <TextInput name="comment" label="Комментарий" />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/clients/${values.clientId}/agreements`)
})(Form)
