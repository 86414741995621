import React from "react"
import { withFormik } from "formik"
import { CheckboxInput, CurrencyInput, TextEditor, SelectInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ values, handleSubmit }) => {
  const ratingOptions = [{ label: "Не пришел", value: 0 }].concat(
    Array(5)
      .fill()
      .map((_, index) => {
        return { label: index + 1, value: index + 1 }
      })
  )
  return (
    <form id="my-form" onSubmit={handleSubmit}>
      <TextEditor
        name="comment"
        label="Комментарий"
        required={values.rating >= 0 && values.rating < 5}
        disabled={values.isRegularTime}
      />
      <SelectInput name="rating" label="Оценка" options={ratingOptions} disabled={values.isRegularTime} isClearable />
      <CurrencyInput name="payment" label="Выдано" hint={values.paymentHint} disabled={values.paymentIsIssued || values.isRegularTime} />
      <CheckboxInput name="isRegularTime" label="Штатное время" />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
