import React from "react"
import { Destroy, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import formatDateTime from "utils/formatDateTime"

const ShowEmploymentModal = ({ closeModal, isOpen, data, destroyEmploymentWithRefresh }) => {
  const onDestroyClick = (e) => {
    e.preventDefault()
    if (window.confirm("Уверены?")) {
      destroyEmploymentWithRefresh({ id: data.id })
      closeModal()
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        {data.extendedProps.additional?.projectId && (
          <div>
            <strong>ID проекта:</strong> {data.extendedProps.additional?.projectId}
            <br />
          </div>
        )}
        {data.extendedProps.additional?.position && (
          <div>
            <strong>Позиция:</strong> {data.extendedProps.additional?.position} {data.extendedProps.additional?.isReserve === 1 && <span style={{color: 'red'}}>(резерв)</span>}
            <br />
          </div>
        )}
        <strong>Описание:</strong> {data.extendedProps.additional?.description}
        <br />
        <strong>Начало:</strong> {formatDateTime(data.start)}
        <br />
        <strong>Окончание:</strong> {formatDateTime(data.end)}
        <br />
      </ModalBody>
      <ModalFooter>
        {data.extendedProps.additional?.employmentableType === "Staff" && (
          <Button onClick={onDestroyClick} variant="danger">
            Удалить
          </Button>
        )}
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ShowEmploymentModal
