import instance from "connection/instance"
import format from "date-fns/format"

const prepareParams = (params = {}) => {
  const { byFinishFrom, byFinishTo, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byFinishFrom: byFinishFrom && format(byFinishFrom, "yyyy-MM-dd"),
      byFinishTo: byFinishTo && format(byFinishTo, "yyyy-MM-dd")
    }
  }
}

const prepare = (data = {}) => {
  const {
    accountManager,
    client,
    contact,
    entity,
    failureReason: { value: failureReason },
    status: { value: status },
    methodOfPayment: { value: methodOfPayment },
    ...other
  } = data
  return {
    ...other,
    accountManagerId: accountManager?.value || null,
    clientId: client?.value || null || null,
    contactId: contact?.value || null || null,
    entityId: entity?.value || null || null,
    failureReason,
    status,
    methodOfPayment
  }
}

const Project = {
  fetch: (params) => instance.get("/projects", { params: prepareParams(params) }),
  create: (data) => instance.post("/projects", { data: prepare(data) }),
  update: (data) => instance.put(`/projects/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/projects/${data.id}`),
  export: (data) =>
    instance.get(`/projects/${data.id}/export.${data.format}`, {
      params: { collapse: data.collapse },
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    }),
  exportTeamToXlsx: (data) =>
    instance.get(`/projects/${data.id}/export_team_to_xlsx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-excel"
      }
    }),
  exportTeamForFsoToXlsx: (data) =>
    instance.get(`/projects/${data.id}/export_team_for_fso_to_xlsx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-excel"
      }
    }),
  exportPresentationToPdf: (data) =>
    instance.get(`/projects/${data.id}/export_presentation_to_pdf`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    })
}

export default Project
