import { useState } from "react"
import PositionApi from "api/position"
import afterDestroyFetch from "utils/afterDestroyFetch"

const usePositions = (collection) => {
  const [positions, setPositions] = useState(collection)

  const fetchPositions = async (params) => {
    const { data } = await PositionApi.fetch(params)
    setPositions(data.positions)
    return data
  }

  const destroyPosition = async (params) => PositionApi.destroy(params)
  const destroyPositionWithFetch = async (params) => afterDestroyFetch(positions, fetchPositions, destroyPosition, params)

  return { positions, fetchPositions, destroyPosition, destroyPositionWithFetch }
}

export default usePositions
