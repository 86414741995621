import React, { Fragment, useState } from "react"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import CourierApi from "api/courier"
import download from "utils/download"
import formatMoney from "utils/formatMoney"
import formatPhone from "utils/formatPhone"

const Show = ({ courier }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async () => {
    try {
      setDisabled(true)
      const { data } = await CourierApi.exportToPdf({ id: courier.id })
      download(data, `${courier.id}.pdf`)
    } finally {
      setDisabled(false)
    }
  }
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{courier.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/couriers/${courier.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <Button variant="warning" className="float-right" onClick={onExport} disabled={disabled}>
          Экспорт в .pdf
        </Button>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">ID</td>
            <td>{courier.id}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={2}>Отправитель</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-6">Юридическое лицо</td>
            <td>{courier.senderCompany}</td>
          </tr>
          <tr>
            <td className="col-6">ФИО</td>
            <td>{courier.senderName}</td>
          </tr>
          <tr>
            <td className="col-6">Телефон</td>
            <td>{formatPhone(courier.senderPhone)}</td>
          </tr>
          <tr>
            <td className="col-6">Адрес</td>
            <td>{courier.senderAddress}</td>
          </tr>
          {courier.senderAddressComment?.trim()?.length !== 0 && (
            <tr>
              <td className="col-6">Комментарий к адресу</td>
              <td>{courier.senderAddressComment}</td>
            </tr>
          )}
          <tr>
            <td className="col-6">Описание отправления</td>
            <td>{courier.senderDescription}</td>
          </tr>
          <tr>
            <td className="col-6">Примечание</td>
            <td>{courier.senderNote}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={2}>Получатель</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-6">Юридическое лицо</td>
            <td>{courier.entity?.name}</td>
          </tr>
          <tr>
            <td className="col-6">ФИО</td>
            <td>{courier.recipientName}</td>
          </tr>
          <tr>
            <td className="col-6">Телефон</td>
            <td>{formatPhone(courier.recipientPhone)}</td>
          </tr>
          <tr>
            <td className="col-6">Адрес</td>
            <td>{courier.recipientAddress}</td>
          </tr>
          {courier.recipientAddressComment?.trim()?.length !== 0 && (
            <tr>
              <td className="col-6">Комментарий к адресу</td>
              <td>{courier.recipientAddressComment}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  )
}

export default Show
