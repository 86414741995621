import React, { useRef } from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn } from "formik"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const TextEditor = ({ name, label, required = false, ...props }) => {
  const id = nanoid()
  const { errors, values, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { required }, { "is-invalid": error })

  const editorRef = useRef(null)

  return (
    <div className={classnames("mb-3", { required, error })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <ReactQuill theme="snow" value={value} onChange={(value) => setFieldValue(name, value)} />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export default connect(TextEditor)
