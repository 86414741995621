import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn, ErrorMessage } from "formik"
import { IMaskInput } from "react-imask"

const KppInput = ({ name, label, ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">{label}</label>
      <IMaskInput
        mask="000000000"
        value={value}
        unmask={true}
        onAccept={(value) => setFieldValue(name, value)}
        onBlur={handleBlur}
        {...{ className, id, name, ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(KppInput)
