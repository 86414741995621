import React from "react"
import ManagerApi from "api/manager"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import Form from "./components/Form"

const CreateModal = ({ isOpen, closeModal, positionId, projectId, users, createManagerWithRefresh }) => {
  const handleSubmit = async (values) => {
    await createManagerWithRefresh(values)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать менеджера</ModalHeader>
      <ModalBody>
        <Form entity={{ user: null, positionId, projectId }} options={{ users: users }} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateModal
