import { useState } from "react"
import EstimateApi from "api/estimate"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useEstimates = (collection, url = null) => {
  const [estimates, setEstimates] = useState(collection)

  const fetchEstimates = async (params) => {
    const { data } = await EstimateApi.setBaseURL(url).fetch(params)
    setEstimates(data.estimates)
    return data
  }

  const destroyEstimate = async (params) => EstimateApi.destroy(params)
  const destroyEstimateWithFetch = async (params) => afterDestroyFetch(estimates, fetchEstimates, destroyEstimate, params)
  
  const cloneEstimate = async (params) => EstimateApi.clone(params)
  const cloneEstimateWithFetch = async (params) => afterDestroyFetch(estimates, fetchEstimates, cloneEstimate, params)

  return { estimates, fetchEstimates, destroyEstimate, destroyEstimateWithFetch, cloneEstimateWithFetch }
}

export default useEstimates
