import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"

const ProjectStaffPayments = () => (
  <Routes>
    <Route
      path="/projects/:projectId/account_payments"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/account_payments`, {
          signal
        })
        return data
      }}
      component={List}
    />
  </Routes>
)
export default ProjectStaffPayments
