import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput } from "components/UI"
import Emails from "components/Common/Emails"
import Phones from "components/Common/Phones"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="lastName" label="Фамилия" required />
    <TextInput name="firstName" label="Имя" required />
    <TextInput name="middleName" label="Отчество" />
    <TextInput name="position" label="Позиция" />
    <Emails />
    <Phones />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/clients/${values.clientId}/contacts`)
})(Form)
