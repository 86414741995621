import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Clients = () => (
  <Routes>
    <Route
      path="/clients/:clientId/agreements"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/agreements`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId/agreements/:agreementId"
      fetchData={async ({ clientId, agreementId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/agreements/${agreementId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/clients/:clientId/agreements/new"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/agreements/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/agreements/:agreementId/edit"
      fetchData={async ({ clientId, agreementId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/agreements/${agreementId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Clients
