import React from "react"
import { withFormik } from "formik"
import { CheckboxInput, RadioButtonGroup, RadioInput, Submit, UploadImage, UploadInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, values, handleSubmit, setFieldValue }) => {
  const aspects = { full_height: 9 / 16, portrait: 3 / 4 }
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup
        label="Тип"
        name="kind"
        afterChange={(option) => {
          if (!["portrait", "full_height"].includes(option.target.value)) {
            setFieldValue("file", {})
          }
        }}
      >
        {options.kinds.map(({ label, value }) => (
          <RadioInput key={`kind-${value}`} label={label} value={value} />
        ))}
      </RadioButtonGroup>
      {values.kind.value !== "video" && (
        <div className="mb-3">
          <CheckboxInput name="isDefault" label="Основная фотография" />
        </div>
      )}
      {values.kind.value !== "video" && (
        <UploadImage name="file" label="Фотография" aspect={aspects[values.kind.value]} />
      )}
      {values.kind.value === "video" && (
        <UploadInput
          name="file"
          label="Видео"
          accept="video/mp4"
          fileFormatHint="(Загружены могут быть только файлы формата .mp4)"
        />
      )}
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/staffs/${values.staffId}/portfolios`)
})(Form)
