import React, { Fragment } from "react"
import Breadcrumbs from "pages/Hrs/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useHrs from "hooks/useHrs"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ hrsFilterOptions, ...props }) => {
  const { hrs, fetchHrs } = useHrs(props.hrs)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter
          options={hrsFilterOptions}
          collection={hrs}
          handleSubmit={async (values) => fetchHrs({ filter: values })}
        />
      </Card>
      <Table collection={hrs} />
      <Paginate collection={hrs} fetch={fetchHrs} />
    </Fragment>
  )
}

export default List
