import { camelizeKeys } from "humps"

const success = (response) => {
  if (response.data && response.headers["content-type"] === "application/json; charset=utf-8") {
    response.data = camelizeKeys(response.data)
  }
  return response
}
const error = (error) => {
  if (error.response && error.response.data) {
    error.response.data = camelizeKeys(error.response.data)
  }
  return Promise.reject(error)
}

export default [success, error]
