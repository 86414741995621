import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useAgreements from "hooks/useAgreements"
import TabsBlock from "pages/Clients/components/TabsBlock"
import Table from "./components/Table"

const List = ({ client, ...props }) => {
  const { agreements, fetchAgreements, destroyAgreementWithFetch } = useAgreements(props.agreements, `/clients/${client.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem active>Договоры</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock client={client} />
      <Card>
        <Button url={`/clients/${client.id}/agreements/new`}>Создать</Button>
      </Card>
      <Table collection={agreements} destroyClientWithFetch={destroyAgreementWithFetch} client={client} />
      <Paginate collection={agreements} fetch={fetchAgreements} />
    </Fragment>
  )
}

export default List
