import { useState } from "react"
import ContactApi from "api/contact"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useContacts = (collection, url = null) => {
  const [contacts, setContacts] = useState(collection)

  const fetchContacts = async (params) => {
    const { data } = await ContactApi.setBaseURL(url).fetch(params)
    setContacts(data.contacts)
    return data
  }

  const destroyContact = async (params) => ContactApi.destroy(params)
  const destroyContactWithFetch = async (params) => afterDestroyFetch(contacts, fetchContacts, destroyContact, params)

  return { contacts, fetchContacts, destroyContact, destroyContactWithFetch }
}

export default useContacts
