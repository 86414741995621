import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import TabsBlock from "pages/Staffs/components/TabsBlock"
import { BreadcrumbItem, Button, Card } from "components/UI"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import useStaffEmployments from "hooks/useStaffEmployments"
import useModal from "hooks/useModal"
import useModalTrigger from "hooks/useModalTrigger"
import usePermissions from "hooks/usePermissions"
import CreateEmploymentModal from "./components/CreateEmploymentModal"
import ShowEmploymentModal from "./components/ShowEmploymentModal"

const Employments = ({ staff, ...props }) => {
  const allow = usePermissions(["ADMIN", "HR_MANAGER"])
  const { employments, fetchStaffEmploymentsCalendar, createEmploymentWithRefresh, destroyEmploymentWithRefresh } =
    useStaffEmployments(props.employments, `/staffs/${staff.id}`)

  const [isCreateEmploymentModalOpen, openCreateEmploymentModal, closeCreateEmploymentModal] = useModal()
  const [isShowEmploymentModalOpen, editModalData, openShowEmploymentModal, closeShowEmploymentModal] = useModalTrigger(
    { extendedProps: {} }
  )
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Календарь занятости</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock staff={staff} />
      <Card>
        <Button
          onClick={openCreateEmploymentModal}
          disabled={!allow}
        >
          Создать
        </Button>
      </Card>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ru"
        firstDay={1}
        dayMaxEventRows={3}
        initialDate={new Date()}
        datesSet={({ startStr, endStr }) => fetchStaffEmploymentsCalendar({ filter: { startStr, endStr } })}
        eventClick={({ event }) => openShowEmploymentModal(event)}
        events={employments.data}
        moreLinkContent="Еще"
        displayEventTime={false}
        buttonText={{
          today: "Сегодня"
        }}
      />
      <CreateEmploymentModal
        isOpen={isCreateEmploymentModalOpen}
        closeModal={closeCreateEmploymentModal}
        staffId={staff.id}
        createEmploymentWithRefresh={createEmploymentWithRefresh}
      />
      <ShowEmploymentModal
        isOpen={isShowEmploymentModalOpen}
        closeModal={closeShowEmploymentModal}
        data={editModalData}
        destroyEmploymentWithRefresh={destroyEmploymentWithRefresh}
      />
    </Fragment>
  )
}

export default Employments
