import React, { Fragment } from "react"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useCouriers from "hooks/useCouriers"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = (props) => {
  const { couriers, fetchCouriers, destroyCourierWithFetch } = useCouriers(props.couriers)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/couriers/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          collection={couriers}
          handleSubmit={async (values) => fetchCouriers({ filter: values })}
        />
      </Card>
      <Table collection={couriers} destroyCourierWithFetch={destroyCourierWithFetch} />
      <Paginate collection={couriers} fetch={fetchCouriers} />
    </Fragment>
  )
}

export default List
