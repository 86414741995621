import React from "react"
import { withFormik } from "formik"
import { Submit, SelectInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <SelectInput name="status" label="Статус" errorName="0.status" options={options.statuses} />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/users")
})(Form)
