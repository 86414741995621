import React from "react"
import { ImagePreview } from "components/UI"
import classnames from "classnames"
import formatMoney from "utils/formatMoney"

const Table = ({
  collection,
  projectStaffsGroupByStaffId,
  managersGroupByEmploymentableId,
  overworksGroupByStaffId,
  openEditProjectStaffModal
}) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Фото</th>
        <th scope="col">ФИО</th>
        <th scope="col">Сумма к выплате</th>
      </tr>
    </thead>
    <tbody>
      {collection.map((i, index) => (
        <tr
          key={`projects-${index}`}
          className={classnames({
            "table-success":
              (projectStaffsGroupByStaffId[i.staffId] || []).every((i) => i.paymentIsIssued) &&
              (managersGroupByEmploymentableId[i.employmentableId] || []).every((i) => i.paymentIsIssued || parseInt(i.payment) === 0) &&
              (overworksGroupByStaffId[i.staffId] || []).every((i) => i.paymentIsIssued || parseInt(i.payment) === 0)
          })}
        >
          <td>
            <ImagePreview
              src={i.avatar}
              width="75"
              height="100"
              className={i.isSelfemployed == 1 ? "selfemployed-avatar" : ""}
            />
          </td>
          <td>
            <a className="open-modal" onClick={() => openEditProjectStaffModal(i)}>
              {i.fullName}
            </a>
          </td>
          <td>{formatMoney(i.paymentSum)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
