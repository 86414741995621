import React, { Fragment } from "react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useUser from "hooks/useUser"
import Form from "pages/Users/components/Form"

const Add = ({userOptions, ...props}) => {
  const { user, createUser } = useUser(props.user)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={user}
        options={userOptions}
        handleSubmit={async (values) => createUser(values)}
      />
    </Fragment>
  )
}

export default Add
