import React from "react"
import { Destroy } from "components/UI"
import { Link } from "components/Router"
import formatMoney from "utils/formatMoney"

const ManagersByPosition = ({ managers, project, destroyManager }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>Имя</th>
        <th className="col-6">Сумма к выдаче</th>
        <th className="actions one"></th>
      </tr>
    </thead>
    <tbody>
      {managers.map((i) => (
        <tr key={`project-manager-${i.id}`}>
          <td>
            <Link to={`/projects/${project.id}/managers/${i.id}/employments`}>{i.user.fullName}</Link>
          </td>
          <td>{formatMoney(i.paymentSum)}</td>
          <td>
            <Destroy action={() => destroyManager({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default ManagersByPosition
