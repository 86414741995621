import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requisites/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useRequisite from "hooks/useRequisite"
import Form from "pages/Requisites/components/Form"

const Edit = ({ requisiteOptions, ...props }) => {
  const { requisite, updateRequisite } = useRequisite(props.requisite)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{requisite.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={requisite} options={requisiteOptions} handleSubmit={async (values) => updateRequisite(values)} />
    </Fragment>
  )
}

export default Edit
