import React from "react"
import { withFormik } from "formik"
import { RadioButtonGroup, RadioInput, Submit, TextInput, UploadImage } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <RadioButtonGroup label="Тип" name="kind">
      {options.kinds.map(({ label, value }) => (
        <RadioInput key={`kind-${value}`} label={label} value={value} />
      ))}
    </RadioButtonGroup>
    <TextInput name="name" label="Наименование" required />
    <UploadImage name="file" label="Изображение" />
    <TextInput name="count" type="number" label="Всего" required />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/requisites")
})(Form)
