import React from "react"
import MetroStationApi from "api/metroStation"
import { AsyncSelectInput } from "components/UI"

const MetroStationSelect = ({
  name = "metroStation",
  label = "Станция метро",
  isMulti = false,
  required,
  ...props
}) => {
  const loadOptions = async (value) => {
    const { data } = await MetroStationApi.suggest({ query: value })
    return data.metroStations
  }
  return (
    <AsyncSelectInput
      name={name}
      label={label}
      required={required}
      defaultOptions={props.defaultOptions}
      isSearchable
      loadOptions={loadOptions}
      isMulti={isMulti}
      // {...props}
    />
  )
}

export default MetroStationSelect
