import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useServiceSet from "hooks/useServiceSet"
import Form from "pages/ServiceSets/components/Form"

const Edit = (props) => {
  const { serviceSet, updateServiceSet } = useServiceSet(props.serviceSet)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{serviceSet.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={serviceSet} handleSubmit={async (values) => updateServiceSet(values)} />
    </Fragment>
  )
}

export default Edit
