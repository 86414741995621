import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useEmployments from "hooks/useEmployments"
import TabsBlock from "pages/Projects/components/TabsBlock"
import Table from "./components/Table"

const List = ({ project, manager, ...props }) => {
  const { employments, fetchEmployments, destroyEmploymentWithFetch } = useEmployments(
    props.employments,
    `/managers/${manager.id}`
  )
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem active>Менеджеры</BreadcrumbItem>
        <BreadcrumbItem active>{manager.user.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Занятость</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/managers/${manager.id}/employments/new`}>Создать</Button>
      </Card>
      <Table
        collection={employments}
        destroyEmploymentWithFetch={destroyEmploymentWithFetch}
        project={project}
        manager={manager}
      />
      <Paginate collection={employments} fetch={fetchEmployments} />
    </DisabledPage>
  )
}

export default List
