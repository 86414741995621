import { useState } from "react"
import EmploymentApi from "api/employment"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useEmployments = (collection, url = null) => {
  const [employments, setEmployments] = useState(collection)

  const fetchEmployments = async (params) => {
    const { data } = await EmploymentApi.setBaseURL(url).fetch(params)
    setEmployments(data.employments)
    return data
  }

  const updateEmployments = async (params) => await EmploymentApi.updateCollection(params)
  const updateEmploymentsWithRefresh = async (params) => {
    const { data } = await updateEmployments(params)

    setEmployments(
      employments.map((employment) => {
        const updatedEmployment = data.employments.find((i) => i.id === employment.id)
        if (updatedEmployment) {
          return updatedEmployment
        }
        return employment
      })
    )
  }

  const destroyEmployment = async (params) => EmploymentApi.destroy(params)
  const destroyEmploymentWithFetch = async (params) =>
    afterDestroyFetch(employments, fetchEmployments, destroyEmployment, params)

  return {
    employments,
    fetchEmployments,
    updateEmploymentsWithRefresh,
    destroyEmployment,
    destroyEmploymentWithFetch
  }
}

export default useEmployments
