import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useStaffs from "hooks/useStaffs"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ staffFilterOptions, ...props }) => {
  const { staffs, fetchStaffs, destroyStaffWithFetch } = useStaffs(props.staffs)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/staffs/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          options={staffFilterOptions}
          collection={staffs}
          handleSubmit={async (values) => fetchStaffs({ filter: values })}
        />
      </Card>
      <Table collection={staffs} destroyStaffWithFetch={destroyStaffWithFetch} />
      <Paginate collection={staffs} fetch={fetchStaffs} />
    </Fragment>
  )
}

export default List
