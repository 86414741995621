import React from "react"
import { Destroy, Edit } from "components/UI"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import usePermissions from "hooks/usePermissions"

const Table = ({
  collection,
  destroyCashWithFetch,
  isCheckedAllCashes,
  isCheckedCash,
  onCheckedAllCashes,
  onCheckedCash,
  openEditModal,
  totalSelectedCash
}) => {
  const allow = usePermissions(["ADMIN", "FINANCES"])
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllCashes} onChange={onCheckedAllCashes} />
          </th>
          <th scope="col">ID проекта</th>
          <th scope="col">Дата выдачи</th>
          <th scope="col">Аккаунт-менеджер</th>
          <th scope="col">Получатель</th>
          <th scope="col">Наименование</th>
          <th scope="col">Сумма</th>
          <th scope="col">Статус</th>
          <th scope="col">Сдано</th>
          <th className="actions two">
            <Edit action={() => openEditModal({})} disabled={!allow || totalSelectedCash === 0} />
          </th>
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr
            key={`cash-${index}`}
            className={{ treatment: "table-danger", ready: "table-warning", issued: "table-success" }[i.status.value]}
          >
            <td>
              <input type="checkbox" checked={isCheckedCash(i.id)} onChange={() => onCheckedCash(i.id)} />
            </td>
            <td>{i.project.id}</td>
            <td>{formatDate(i.issuedOn)}</td>
            <td>{i.project.accountManager.fullName}</td>
            <td>{i.recipient.fullName}</td>
            <td>{i.orderName}</td>
            <td>{formatMoney(i.cost)}</td>
            <td>{i.status.label}</td>
            <td>{formatMoney(i.cashback)}</td>
            <td>
              <Edit url={`/cashes/${i.id}/edit`} />
              <Destroy
                action={() => destroyCashWithFetch({ id: i.id })}
                disabled={i.status.value === "issued" || !allow}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
