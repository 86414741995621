import instance from "connection/instance"

const prepareParams = (params = {}) => {
  const { byId, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byId: byId?.value || null
    }
  }
}

const prepare = (data = {}) => {
  const { employmentableId, ...other } = data
  return {
    ...other,
    employmentableId: employmentableId?.value || null
  }
}

const EmploymentsCalendar = {
  fetch: (params) => instance.get("/calendars/employments", { params: prepareParams(params) }),
  create: (data) =>
    instance.post("/employments", { data: prepare(data) }).then((response) => {
      const { employment } = response.data
      response.data = {
        employment: {
          additional: { description: employment.description, employmentableType: "User" },
          color: "#dfc2c4",
          end: employment.finishedAt,
          id: employment.id,
          start: employment.startedAt,
          title: employment.fullName
        }
      }
      return Promise.resolve(response)
    }),
  destroy: (data) => instance.delete(`/employments/${data.id}`)
}

export default EmploymentsCalendar
