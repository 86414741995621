import React, { useState } from "react"
import { withFormik } from "formik"
import { AddressInput, PhoneInput, Submit, TextInput, TextArea } from "components/UI"
import ClientSelectWithCreating from "pages/Projects/components/Form/components/ClientSelectWithCreating"
import ContactSelectWithCreating from "pages/Projects/components/Form/components/ContactSelectWithCreating"
import EntitySelect from "components/Common/EntitySelect"
import formSubmitHandler from "utils/formSubmitHandler"
import ContactApi from "api/contact"
import EntityApi from "api/entity"
import Card from "react-bootstrap/Card"

const Form = ({ courierOptions, values, handleSubmit, setFieldValue }) => {
  const [contacts, setContacts] = useState(courierOptions.contacts)
  const [entities, setEntities] = useState(courierOptions.entities)

  const afterClientChange = async (option) => {
    if (option === null) {
      setFieldValue("recipientAddress", "")
      setFieldValue("contact", null)
      setContacts([])
    } else {
      setFieldValue("recipientAddress", option.data.address)
      const {
        data: { contacts }
      } = await ContactApi.setBaseURL(`/clients/${option.value}`).suggest({})
      const {
        data: { entities }
      } = await EntityApi.setBaseURL(`/clients/${option.value}`).suggest({})
      setFieldValue("contact", null)
      setContacts(contacts)
      setFieldValue("entity", null)
      setEntities(entities)
    }
  }

  const onClientAdded = (option) => {
    setFieldValue("client", option)
    setFieldValue("contact", null)
    setContacts([])
  }

  const onContactAdded = (option) => setFieldValue("contact", option)

  const afterContactChange = (option) => {
    setFieldValue("recipientName", option.label)
    setFieldValue("recipientPhone", option.data.phone)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Card.Header>Отправитель</Card.Header>
        <Card.Body>
          <TextArea name="senderCompany" label="Юридическое лицо" rows="3" />
          <TextInput name="senderName" label="ФИО" required />
          <PhoneInput name="senderPhone" label="Телефон" />
          <AddressInput name="senderAddress" label="Адрес" />
          <TextArea name="senderAddressComment" label="Комментарий к адресу" rows="2" />
          <TextArea name="senderDescription" label="Описание отправления" rows="2" />
          <TextArea name="senderNote" label="Примечание" rows="2" />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Получатель</Card.Header>
        <Card.Body>
          <ClientSelectWithCreating
            defaultOptions={courierOptions.clients}
            options={{ users: courierOptions.users }}
            afterChange={afterClientChange}
            onClientAdded={onClientAdded}
          />
          <EntitySelect isDisabled={!values.client?.value} defaultOptions={entities} />
          <ContactSelectWithCreating
            defaultOptions={contacts}
            clientId={values.client?.value}
            onContactAdded={onContactAdded}
            afterChange={afterContactChange}
          />
          <TextInput name="recipientName" label="ФИО" />
          <PhoneInput name="recipientPhone" label="Телефон" />
          <AddressInput name="recipientAddress" label="Адрес" />
          <TextArea name="recipientAddressComment" label="Комментарий к адресу" rows="2" />
        </Card.Body>
      </Card>
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity: _entity }) => {
    const { client, contact, entity } = _entity
    return {
      ..._entity,
      client: client ? { label: client.name, value: client.id } : null,
      contact: contact ? { label: contact.fullName, value: contact.id } : null,
      entity: entity ? { label: entity.name, value: entity.id } : null
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/couriers")
})(Form)
