import React, { Fragment } from "react"
import Breadcrumbs from "pages/PaymentRegistries/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import usePaymentRegistries from "hooks/usePaymentRegistries"
import Table from "./components/Table"

const List = (props) => {
  const { paymentRegistries, fetchPaymentRegistries, destroyPaymentRegistryWithFetch } = usePaymentRegistries(
    props.paymentRegistries
  )
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/payment_registries/new">
          Создать
        </Button>
      </Card>
      <Table collection={paymentRegistries} destroyPaymentRegistryWithFetch={destroyPaymentRegistryWithFetch} />
      <Paginate collection={paymentRegistries} fetch={fetchPaymentRegistries} />
    </Fragment>
  )
}

export default List
