import React, { Fragment, useState } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import TabsBlock from "pages/Projects/components/TabsBlock"
import ProjectApi from "api/project"
import download from "utils/download"
import formatDate from "utils/formatDate"
import truncate from "utils/truncate"
import HTMLTextModal from "components/Common/HTMLTextModal"
import useModalTrigger from "hooks/useModalTrigger"
import formatPhone from "utils/formatPhone"

const Show = ({ project }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async (format = "pdf", collapse = false) => {
    setDisabled(true)
    const { data } = await ProjectApi.export({ id: project.id, format, collapse })
    setDisabled(false)
    download(data, `Заказ-наряд № ${project.id}.${format}`)
  }

  const [isContactModalOpen, contactModalData, openСontactModal, closeContactModal] = useModalTrigger("")
  const [isBriefModalOpen, briefModalData, openBriefModal, closeBriefModal] = useModalTrigger("")
  const [isFeedbackModalOpen, feedbackModalData, openFeedbackModal, closeFeedbackModal] = useModalTrigger("")
  const [isStatusCommentModalOpen, statusCommentModalData, openStatusCommentModal, closeStatusCommentModal] =
    useModalTrigger("")
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem active>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Карточка проекта</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <DropdownButton title="Экспорт" variant="success" disabled={disabled}>
          <Dropdown.Item onClick={() => onExport("pdf", false)}>Полный заказ-наряд в .pdf</Dropdown.Item>
          <Dropdown.Item onClick={() => onExport("pdf", true)}>Краткий заказ-наряд в .pdf</Dropdown.Item>
          <Dropdown.Item onClick={() => onExport("xlsx", false)}>Полный заказ-наряд в .xlsx</Dropdown.Item>
        </DropdownButton>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">№ проекта</td>
            <td>{project.id}</td>
          </tr>
          <tr>
            <td className="col-6">Дата создания</td>
            <td>{formatDate(project.createdAt)}</td>
          </tr>
          <tr>
            <td className="col-lg-6">Дата проекта</td>
            <td>
              {formatDate(project.startedOn)} - {formatDate(project.finishedOn)}
            </td>
          </tr>
          <tr>
            <td className="col-6">Название</td>
            <td>{project.name}</td>
          </tr>
          <tr>
            <td className="col-6">Юридическое лицо</td>
            <td>{project.entity?.name}</td>
          </tr>
          <tr>
            <td className="col-6">Статус</td>
            <td>{project.status.label}</td>
          </tr>
          {project.status.value === "failure" && (
            <Fragment>
              <tr>
                <td className="col-6">Причина отказа</td>
                <td>{project.failureReason.label}</td>
              </tr>
              <tr>
                <td className="col-6">Комментарий к статусу</td>
                <td className="col-6">
                  <span
                    className="dashed"
                    onClick={() => openStatusCommentModal(project.statusComment)}
                    dangerouslySetInnerHTML={{ __html: truncate(project.statusComment) }}
                  />
                </td>
              </tr>
            </Fragment>
          )}
          <tr>
            <td className="col-6">Место проведения</td>
            <td>{project.address}</td>
          </tr>
          <tr>
            <td className="col-6">Форма оплаты</td>
            <td>{project.methodOfPayment.label}</td>
          </tr>
          <tr>
            <td className="col-6">Представитель заказчика</td>
            <td className="col-6">
              <span
                className="dashed"
                onClick={() => openСontactModal(project.contact?.fullName)}
                dangerouslySetInnerHTML={{ __html: truncate(project.contact?.fullName) }}
              />
            </td>
          </tr>
          <tr>
            <td className="col-6">Аккаунт-менеджер</td>
            <td>{project.accountManager.fullName}</td>
          </tr>
          <tr>
            <td className="col-6">Бриф</td>
            <td className="col-6">
              <span
                className="dashed"
                onClick={() => openBriefModal(project.brief)}
                dangerouslySetInnerHTML={{ __html: truncate(project.brief) }}
              />
            </td>
          </tr>
          <tr>
            <td className="col-6">Результат/Отзыв клиента</td>
            <td className="col-6">
              <span
                className="dashed"
                onClick={() => openFeedbackModal(project.feedback)}
                dangerouslySetInnerHTML={{ __html: truncate(project.feedback) }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Modal isOpen={isContactModalOpen} onRequestClose={closeContactModal}>
        <ModalHeader />
        <ModalBody>
          {project.contact && (
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Телефоны</td>
                  <td>
                    {project.contact.phones.map((i, index) => [
                      index > 0 && ", ",
                      <a key={`phone-${i.id}`} href={`tel:${i.value}`}>
                        {formatPhone(i.value)}
                      </a>
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>Emails</td>
                  <td>
                    {project.contact.emails.map((i, index) => [
                      index > 0 && ", ",
                      <a key={`email-${i.id}`} href={`mailto:${i.value}`}>
                        {i.value}
                      </a>
                    ])}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeContactModal} variant="secondary">
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
      <HTMLTextModal isOpen={isBriefModalOpen} closeModal={closeBriefModal}>
        {briefModalData}
      </HTMLTextModal>
      <HTMLTextModal isOpen={isFeedbackModalOpen} closeModal={closeFeedbackModal}>
        {feedbackModalData}
      </HTMLTextModal>
      <HTMLTextModal isOpen={isStatusCommentModalOpen} closeModal={closeStatusCommentModal}>
        {statusCommentModalData}
      </HTMLTextModal>
    </DisabledPage>
  )
}

export default Show
