import instance from "connection/instance"

const Vacancy = {
  create: (data) => instance.post("/vacancies", { data }),
  update: (data) => instance.put(`/vacancies/${data.id}`, { data }),
  updateCollection: (data) => instance.put("/vacancies", { data }),
  destroy: (data) => instance.delete(`/vacancies/${data.id}`),
  destroyCollection: (data) => instance.delete("/vacancies", { data: { data } }),
  closeCollection: (data) => instance.post("/vacancies/close", { data }),
  acceptCollection: (data) => instance.post("/vacancies/accept", { data }),
  rejectCollection: (data) => instance.post("/vacancies/reject", { data }),
  reserveCollection: (data) => instance.post("/vacancies/reserve", { data }),
  fetchMoreVacancies: (params) => instance.get("/vacancies", { params }),
}

export default Vacancy
