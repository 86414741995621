import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useService from "hooks/useService"

const Edit = ({ service_set, ...props }) => {
  const { service, updateService } = useService(props.service)
  const { kinds } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/service_sets/${service_set.id}`}>{service_set.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/service_sets/${service_set.id}/services`}>Услуги</BreadcrumbItem>
        <BreadcrumbItem active>{service.fullName}</BreadcrumbItem>
      </Breadcrumbs>
    </Fragment>
  )
}

export default Edit
