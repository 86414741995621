import React, { Fragment } from "react"
import { PhoneInput } from "components/UI"

const Phone = ({ prefix }) => (
  <Fragment>
    <PhoneInput name={`${prefix}.value`} label="Телефон" />
  </Fragment>
)

export default Phone
