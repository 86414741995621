import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useReservation from "hooks/useReservation"
import Form from "pages/Projects/containers/Reservations/components/Form"

const Edit = ({ project, requisiteOptions, requisites, ...props }) => {
  const { reservation, updateReservation } = useReservation(props.reservation)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/reservations`}>Реквизит</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/reservations/${reservation.id}`}>{reservation.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...reservation, projectId: project.id }}
        requisites={requisites}
        requisiteOptions={requisiteOptions}
        handleSubmit={async (values) => updateReservation({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
