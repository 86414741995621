import merge from "lodash.merge"

const convertErrorsObject = (errorsObj = {}) => {
  let newErrorsObj = {}
  Object.keys(errorsObj).forEach((key) => {
    const path = key.replace(/\[(\d+)\]/g, ".$1").split(".")
    const errorObj = path.reverse().reduce((accumulator, current) => {
      return { [current]: accumulator }
    }, errorsObj[key])
    newErrorsObj = merge(newErrorsObj, errorObj)
  })
  return newErrorsObj
}

export default convertErrorsObject
