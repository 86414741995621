import instance from "connection/instance"

const StaffEmployments = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/employments`, { params })
  },
  create: (data) =>
    instance.post("/employments", { data }).then((response) => {
      const { employment } = response.data
      response.data = {
        employment: {
          additional: { description: employment.description, employmentableType: "Staff" },
          color: "#dfc2c4",
          end: employment.finishedAt,
          id: employment.id,
          start: employment.startedAt,
          title: employment.fullName
        }
      }
      return Promise.resolve(response)
    }),
  destroy: (data) => instance.delete(`/employments/${data.id}`)
}

export default StaffEmployments
