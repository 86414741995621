const formatMoney = (value) => {
  value = parseFloat(value)
  if (typeof value === "number") {
    value = ((value * 100).toFixed(2) / 100).toFixed(2)
  }

  return String(value)
    .replace(/[^\d.\-{1}]/g, "")
    .replace(/\d(?=(\d{3})+\.)/g, "$& ")
    .replace(".", ",")
}

export default formatMoney
