import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem, Paginate } from "components/UI"
import useFeedbacks from "hooks/useFeedbacks"
import TabsBlock from "pages/Projects/components/TabsBlock"
import Table from "./components/Table"

const List = ({ project, ...props }) => {
  const { feedbacks, fetchFeedbacks, destroyFeedbackWithFetch } = useFeedbacks(props.feedbacks, `/projects/${project.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Отзывы</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Table collection={feedbacks} destroyProjectWithFetch={destroyFeedbackWithFetch} project={project} />
      <Paginate collection={feedbacks} fetch={fetchFeedbacks} />
    </Fragment>
  )
}

export default List
