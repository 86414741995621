import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import instance from "connection/instance"

const CropModal = ({ name, label = "Фото", file, isOpen, closeModal, onCropAdded, aspect = 3 / 4, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageRef, setImageRef] = useState(null)
  const [cropped, setCropped] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: aspect
  })

  useEffect(() => {
    setUploadProgress(0)
    setCrop({
      unit: "%",
      width: 50,
      x: 25,
      y: 25,
      aspect: aspect
    })
  }, [isOpen])

  const onSave = () => {
    const formData = new FormData()
    formData.append("data[file]", file)
    formData.append("data[processing][crop]", JSON.stringify(cropped))
    formData.append("data[attachmentable_column]", name)
    setIsLoading(true)
    instance
      .post("/attachments", formData, {
        onUploadProgress: (event) => {
          const percentCompleted = Math.round((event.loaded * 100) / event.total)
          setUploadProgress(percentCompleted)
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data: { attachment } }) => {
        setIsLoading(false)
        onCropAdded(attachment)
        closeModal()
      })
      .catch((error) => setIsLoading(false))
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image)

    setCrop({
      unit: "%",
      width: 50,
      x: 25,
      y: 25,
      aspect: aspect
    })
  }

  const onCropComplete = (crop) => {
    makeClientCrop(crop)
  }

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop({ crop: percentCrop });
    setCrop(crop)
  }

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      setCropped(getCroppedImg(imageRef, crop))
    }
  }

  const getCroppedImg = (image, crop) => {
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    return {
      x: crop.x * scaleX,
      y: crop.y * scaleY,
      width: crop.width * scaleX,
      height: crop.height * scaleY
    }
  }

  // const { crop, croppedImageUrl, src } = data

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        {props.src && (
          <ReactCrop
            src={props.src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        )}
        {uploadProgress > 0 && (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSave} variant="success" disabled={isLoading}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CropModal
