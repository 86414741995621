import { useState } from "react"
import CashApi from "api/cash"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useCashes = (collection, url = null) => {
  const [cashes, setCashes] = useState(collection)

  const fetchCashes = async (params) => {
    const { data } = await CashApi.setBaseURL(url).fetch(params)
    setCashes(data.cashes)
    return data
  }

  const updateCashes = async (params) => await CashApi.updateCollection(params)
  const updateCashesWithRefresh = async (params) => {
    const { data } = await updateCashes(params)
    setCashes({
      ...cashes, 
      data: cashes.data.map((cash) => {
        const updatedCash = data.cashes.find((i) => i.id === cash.id)
        if (updatedCash) {
          return updatedCash
        }
        return cash
      })
    })
  }

  const destroyCash = async (params) => CashApi.destroy(params)
  const destroyCashWithFetch = async (params) => afterDestroyFetch(cashes, fetchCashes, destroyCash, params)

  return { cashes, fetchCashes, updateCashesWithRefresh, destroyCash, destroyCashWithFetch }
}

export default useCashes
