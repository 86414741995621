import React from "react"
import { Modal, ModalHeader, ModalBody } from "components/UI"

const Lightbox = ({ isOpen = false, src, contentType = null, closeModal = () => {} }) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal}>
    <ModalHeader />
    <ModalBody>
      <div className="modal-image">
        {contentType === "video/mp4" ? (
          <video controls="controls">
            <source src={src} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          </video>
        ) : (
          <img src={src} className="img-fluid" />
        )}
      </div>
    </ModalBody>
  </Modal>
)

export default Lightbox
