import { useState } from "react"
import EmploymentApi from "api/employment"

const useEmployment = (collection) => {
  const [employment, setEmployment] = useState(collection)

  const createEmployment = async (params) => {
    const { data } = await EmploymentApi.create(params)
    setEmployment(data.employment)
    return data
  }

  const updateEmployment = async (params) => {
    const { data } = await EmploymentApi.update(params)
    setEmployment(data.employment)
    return data
  }

  return { employment, createEmployment, updateEmployment }
}

export default useEmployment
