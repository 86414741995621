import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useCash from "hooks/useCash"
import Form from "pages/Projects/containers/Cashes/components/Form"

const Edit = ({ cashOptions, project, expense, ...props }) => {
  const { cash, updateCash } = useCash(props.cash)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/expenses`}>Расходы</BreadcrumbItem>
        <BreadcrumbItem active>{expense.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/cashes/${expense.type}/${expense.id}`}>
          Денежные средства
        </BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...cash, projectId: project.id, cashableType: expense.type, cashableId: expense.id }}
        options={cashOptions}
        handleSubmit={async (values) => updateCash({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
