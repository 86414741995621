import { useState } from "react"
import ClientApi from "api/client"

const useClient = (collection) => {
  const [client, setClient] = useState(collection)

  const createClient = async (params) => {
    const { data } = await ClientApi.create(params)
    setClient(data.client)
    return data
  }

  const updateClient = async (params) => {
    const { data } = await ClientApi.update(params)
    setClient(data.client)
    return data
  }

  return { client, createClient, updateClient }
}

export default useClient
