import React, { Fragment, useEffect, useState } from "react"
import Breadcrumbs from "pages/Calendars/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import useServicesCalendar from "hooks/useServicesCalendar"
import useModalTrigger from "hooks/useModalTrigger"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import ShowServiceModal from "./components/ShowServiceModal"
import Filter from "./components/Filter"

const Services = ({ serviceOptions, ...props }) => {
  const { services, fetchServicesCalendar } = useServicesCalendar(props.services)

  const [isShowServiceModalOpen, editModalData, openShowServiceModal, closeShowServiceModal] = useModalTrigger({
    extendedProps: {}
  })

  const [filter, setFilter] = useState({})

  useEffect(() => {
    fetchServicesCalendar({
      filter
    })
  }, [filter])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Календарь услуг</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter
          options={serviceOptions}
          collection={services}
          handleSubmit={async (values) => setFilter({ ...filter, byStatus: values.byStatus })}
        />
      </Card>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ru"
        firstDay={1}
        dayMaxEventRows={3}
        initialDate={new Date()}
        datesSet={({ startStr, endStr }) => setFilter({ ...filter, startStr, endStr })}
        eventClick={({ event }) => openShowServiceModal(event)}
        events={services.data}
        moreLinkContent="Еще"
        displayEventTime={false}
        buttonText={{
          today: "Сегодня"
        }}
      />
      <ShowServiceModal isOpen={isShowServiceModalOpen} closeModal={closeShowServiceModal} data={editModalData} />
    </Fragment>
  )
}

export default Services
