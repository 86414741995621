import React from "react"
import Badge from "react-bootstrap/Badge"

const CountBadge = ({ projectStaffsCount, quantity }) => {
  const bg = projectStaffsCount < quantity ? "danger" : "success"
  return (
    <Badge bg={bg}>
      набрано {projectStaffsCount} из {quantity}
    </Badge>
  )
}

export default CountBadge
