import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const Requisites = () => (
  <Routes>
    <Route
      path="/requisites"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/requisites`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/requisites/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/requisites/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/requisites/:requisiteId/edit"
      fetchData={async ({ requisiteId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/requisites/${requisiteId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Requisites
