import React from "react"
import { Modal, ModalBody, ModalHeader } from "components/UI"
import ReservationRequisiteApi from "api/reservationRequisite"
import Form from "./components/Form"

const ReservationRequisiteModal = ({ reservationRequisite, isOpen, closeModal, onReservationRequisiteUpdate, ...props }) => {
  const handleSubmit = async (values) => {
    const { data } = await ReservationRequisiteApi.update(values)
    onReservationRequisiteUpdate(data.reservationRequisite)
    closeModal()
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Редактирование реквизита</ModalHeader>
      <ModalBody>
        <Form entity={reservationRequisite} handleSubmit={handleSubmit} />
      </ModalBody>
    </Modal>
  )
}

export default ReservationRequisiteModal
