import React from "react"
import { withFormik } from "formik"
import { SelectInput, Submit, CurrencyInput, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="innerName" label="Внутреннее название" required />
    <TextInput name="outerName" label="Клиентское название" required />
    <CurrencyInput name="innerCostPerHour" label="Внутренняя стоимость за час" required />
    <CurrencyInput name="outerCostPerHour" label="Клиентская стоимость за час" required />
    <TextInput name="discount" label="Скидка" />
    <SelectInput name="kind.value" label="Тип" options={options.kinds} />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/service_sets/${values.serviceSetId}/services`)
})(Form)
