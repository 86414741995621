import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requisites/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useRequisites from "hooks/useRequisites"
import usePermissions from "hooks/usePermissions"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ requisiteOptions, ...props }) => {
  const allow = usePermissions(["ADMIN"])
  const { requisites, fetchRequisites, destroyRequisiteWithFetch } = useRequisites(props.requisites)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/requisites/new" disabled={!allow}>
          Создать
        </Button>
      </Card>
      <Card>
        <Filter
          options={requisiteOptions}
          collection={requisites}
          handleSubmit={async (values) => fetchRequisites({ filter: values })}
        />
      </Card>
      <Table collection={requisites} destroyRequisiteWithFetch={destroyRequisiteWithFetch} />
      <Paginate collection={requisites} fetch={fetchRequisites} />
    </Fragment>
  )
}

export default List
