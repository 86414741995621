import { useState } from "react"
import RequestApi from "api/request"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useRequests = (collection) => {
  const [requests, setRequests] = useState(collection)

  const fetchRequests = async (params) => {
    const { data } = await RequestApi.fetch(params)
    setRequests(data.requests)
    return data.requests
  }

  const destroyRequest = async (params) => RequestApi.destroy(params)
  const destroyRequestWithFetch = async (params) => afterDestroyFetch(requests, fetchRequests, destroyRequest, params)

  return { requests, fetchRequests, destroyRequest, destroyRequestWithFetch }
}

export default useRequests
