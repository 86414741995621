import React from "react"
import { ImagePreview, Edit, Destroy } from "components/UI"

const ReservationRequisites = ({ values, openReservationRequisiteModal, markReservationRequisiteForDestroy }) => {
  const reservationRequisites = values.reservationRequisites.filter((i) => !i._destroy)

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th>Наименование</th>
          <th>Изображение</th>
          <th>Количество</th>
          <th>Комментарий</th>
          <th className="actions two"></th>
        </tr>
      </thead>
      <tbody>
        {reservationRequisites.map((i) => (
          <tr key={`reservation-requisite-${i.id}`}>
            <td>{i.requisite.name}</td>
            <td><ImagePreview src={i.requisite.file} /></td>
            <td>{i.count}</td>
            <td>{i.comment}</td>
            <td>
              <Edit action={() => openReservationRequisiteModal(i)} />
              <Destroy action={() => markReservationRequisiteForDestroy(i.id)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ReservationRequisites
