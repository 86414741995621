import React, { Fragment } from "react"
import Breadcrumbs from "pages/Analytics/components/Breadcrumbs"
import { BreadcrumbItem, Card, Paginate } from "components/UI"
import useClientsAnalytic from "hooks/useClientsAnalytic"
import Filter from "./components/Filter"
import Table from "./components/Table"

const Clients = ({ clientOptions, ...props }) => {
  const { clients, fetchClientsAnalytic } = useClientsAnalytic(props.clients)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Клиенты</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter
          options={clientOptions}
          collection={clients}
          handleSubmit={async (values) => fetchClientsAnalytic({ filter: values })}
        />
      </Card>
      <Table collection={clients} />
      <Paginate collection={clients} fetch={fetchClientsAnalytic} />
    </Fragment>
  )
}

export default Clients
