import React, { Fragment } from "react"
import Breadcrumbs from "pages/Presentations/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePresentation from "hooks/usePresentation"
import Form from "pages/Presentations/components/Form"

const Edit = ({ presentationOptions, staffOptions, staffs, ...props }) => {
  const { presentation, updatePresentation } = usePresentation(props.presentation)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{presentation.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={presentation}
        presentationOptions={presentationOptions}
        staffOptions={staffOptions}
        staffs={staffs}
        handleSubmit={async (values) => updatePresentation(values)}
      />
    </Fragment>
  )
}

export default Edit
