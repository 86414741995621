import React from "react"
import { useFormikContext } from "formik"

const Submit = ({ children = "Сохранить", variant = "success", url, ...props }) => {
  const { isSubmitting } = useFormikContext()
  return (
    <button type="submit" className={`btn btn-${variant}`} disabled={isSubmitting} {...props}>
      {children}
    </button>
  )
}

export default Submit
