import React, { useState } from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn, ErrorMessage } from "formik"

const FileInput = ({ name, label, type = "file", onChange, ...props }) => {
  const id = nanoid()
  const { errors, values } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })

  const getFileFromUrl = (url) => decodeURI(url.replace(/^.*[\\|/]/, ""))

  const [fileName, setFileName] = useState(getFileFromUrl(value))

  const onClick = () => {
    const clickEvent = document.createEvent("MouseEvents")
    clickEvent.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    document.querySelector(`#${id}`).dispatchEvent(clickEvent)
  }
  const onChangeFile = (e) => {
    onChange(e)
    setFileName(getFileFromUrl(e.target.value))
  }

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        {...{ className, id, name, type, ...props }}
        onChange={onChangeFile}
        style={{ display: "none" }}
        accept="image/jpeg,image/png,image/gif"
      />
      <button type="button" className="btn btn-success" onClick={onClick} style={{ marginLeft: "10px" }}>
        Открыть
      </button>
      <span style={{ marginLeft: "10px" }}>{fileName}</span>
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(FileInput)
