import { useState } from "react"
import StaffApi from "api/staff"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useStaffs = (collection) => {
  const [staffs, setStaffs] = useState(collection)

  const fetchStaffs = async (params) => {
    const { data } = await StaffApi.fetch(params)
    setStaffs(data.staffs)
    return data.staffs
  }

  const destroyStaff = async (params) => await StaffApi.destroy(params)
  const destroyStaffWithFetch = async (params) => afterDestroyFetch(staffs, fetchStaffs, destroyStaff, params)

  return { staffs, fetchStaffs, destroyStaff, destroyStaffWithFetch }
}

export default useStaffs
