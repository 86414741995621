import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"

const BriefModal = ({ closeModal, isOpen, children }) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal}>
    <ModalHeader>Предмет запроса</ModalHeader>
    <ModalBody>
      <div dangerouslySetInnerHTML={{ __html: children }}></div>
    </ModalBody>
    <ModalFooter>
      <Button onClick={closeModal} variant="secondary">
        Закрыть
      </Button>
    </ModalFooter>
  </Modal>
)

export default BriefModal
