import React, { Fragment, useContext } from "react"
import { useLocation } from "components/Router"
import AuthContext from "contexts/AuthContext"

const items = [
  {
    key: "clients",
    name: "Клиенты",
    url: "/clients"
  },
  {
    key: "projects",
    name: "Проекты",
    url: "/projects"
  },
  {
    key: "staffs",
    name: "Персонал",
    url: "/staffs"
  },
  {
    key: "users",
    name: "Штат UP",
    url: "/users"
  },
  {
    key: "requests",
    name: "Запросы",
    url: "/requests"
  },
  {
    name: "Мониторинг HR",
    children: [
      {
        key: "hrs",
        name: "Загрузка HR",
        url: "/hrs"
      }
    ]
  },
  {
    name: "Финансы",
    children: [
      {
        key: "cashes",
        name: "Заявки",
        url: "/cashes"
      }
    ]
  },
  {
    name: "Календари",
    children: [
      {
        key: "employment_calendars",
        name: "Календарь занятости",
        url: "/calendars/employments"
      },
      {
        key: "service_calendars",
        name: "Календарь услуг",
        url: "/calendars/services"
      },
      {
        key: "cost_calendars",
        name: "Календарь затрат",
        url: "/calendars/costs"
      }
    ]
  },
  {
    name: "Аналитика",
    children: [
      {
        key: "client_analytic",
        name: "Клиенты",
        url: "/analytics/clients"
      },
      {
        key: "service_analytic",
        name: "Услуги",
        url: "/analytics/services"
      }
    ]
  },
  {
    name: "Склад",
    children: [
      {
        key: "reservations",
        name: "Заявки",
        url: "/reservations"
      },
      {
        key: "requisites",
        name: "Реквизит",
        url: "/requisites"
      }
    ]
  },
  {
    name: "Рабочие материалы",
    children: [
      {
        key: "presentations",
        name: "Презентации",
        url: "/presentations"
      },
      {
        key: "couriers",
        name: "Курьерские отправления",
        url: "/couriers"
      },
      {
        key: "payment_registries",
        name: "Реестр выплат",
        url: "/payment_registries"
      }
    ]
  },
  {
    name: "Справочники",
    children: [
      {
        key: "skills",
        name: "Навыки",
        url: "/skills"
      },
      {
        key: "positions",
        name: "Позиции",
        url: "/positions"
      },
      {
        key: "service_sets",
        name: "Коллекции услуг",
        url: "/service_sets"
      }
    ]
  }
]

const Link = ({ className, activeClassName, setIsSidebarShow, ...props }) => {
  let { to: href, children, onClick } = props
  const { location, setLocation } = useLocation()

  const handleClick = (e) => {
    e.preventDefault()
    setLocation(href)
    setIsSidebarShow(false)
  }

  const extraProps = { href: href, onClick: handleClick, to: null }

  if (location === href) {
    className = className + " " + activeClassName
  }

  return (
    <a {...props} className={className} {...extraProps}>
      {children}
    </a>
  )
}

const renderItems = (items, menu, setIsSidebarShow) =>
  items.map((i, index) => {
    if (i.key && !menu.includes(i.key)) return null
    return (
      <Fragment key={`item-${index}`}>
        {i.url && (
          <li className="nav-item">
            <Link to={i.url} className="nav-link" activeClassName="active" setIsSidebarShow={setIsSidebarShow}>
              {i.name}
            </Link>
          </li>
        )}
        {!i.url && i.children && i.children.length > 0 && i.children.some((i) => menu.includes(i.key)) && (
          <Fragment key={`item-${index}`}>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>{i.name}</span>
            </h6>
            {renderItems(i.children, menu, setIsSidebarShow)}
          </Fragment>
        )}
      </Fragment>
    )
  })

const Sidebar = ({ isSidebarShow, setIsSidebarShow }) => {
  const { user } = useContext(AuthContext)
  if (!isSidebarShow) return null
  return (
    <nav className="col-lg-2 d-md-block bg-light sidebar">
      <div className="sidebar-sticky pt-3">
        <ul className="nav flex-column">{renderItems(items, user.menu, setIsSidebarShow)}</ul>
      </div>
    </nav>
  )
}

export default Sidebar
