import { useState } from "react"
import FeedbackApi from "api/feedback"

const useFeedbacks = (collection, url = null) => {
  const [feedbacks, setFeedbacks] = useState(collection)

  const fetchFeedbacks = async (params) => {
    const { data } = await FeedbackApi.setBaseURL(url).fetch(params)
    setFeedbacks(data.feedbacks)
    return data
  }
  return { feedbacks, fetchFeedbacks }
}

export default useFeedbacks
