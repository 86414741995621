import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput, DateInput, DateTimeInput, CurrencyInput } from "components/UI"
import addDays from "date-fns/addDays"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ values, handleSubmit, setFieldValue }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-sm">
        <TextInput name="outerName" label="Клиентское название" required />
      </div>
      <div className="col-sm">
        <TextInput name="orderName" label="Наименование в заказ-наряде" required />
      </div>
    </div>
    <div className="row">
      <div className="col-sm">
        <CurrencyInput name="innerCostPerHour" label="Внутренняя стоимость" />
      </div>
      <div className="col-sm">
        <CurrencyInput name="outerCostPerHour" label="Клиентская стоимость" required />
      </div>
    </div>
    <div className="row">
      <div className="col-sm">
        <TextInput name="quantity" label="Количество" required />
      </div>
      <div className="col-sm">
        <TextInput name="discount" label="Скидка" />
      </div>
    </div>
    {values.kind.value === "main" && (
      <div className="row">
        <div className="col-sm">
          <DateTimeInput
            name="startedAt"
            label="Дата начала"
            onChange={(e) => {
              setFieldValue("startedAt", e)
              setFieldValue("finishedAt", addDays(e, 1))
            }}
          />
        </div>
        <div className="col-sm">
          <DateTimeInput
            name="finishedAt"
            label="Дата окончания"
            minDate={values.startedAt && values.startedAt}
            maxDate={values.startedAt && addDays(values.startedAt, 1)}
          />
        </div>
      </div>
    )}
    {values.kind.value !== "main" && (
      <div className="row">
        <div className="col-sm">
          <DateInput
            name="startedAt"
            label="Дата начала"
            onChange={(e) => {
              setFieldValue("startedAt", e)
              setFieldValue("finishedAt", addDays(e, 1))
            }}
          />
        </div>
        <div className="col-sm">
          <DateInput
            name="finishedAt"
            label="Дата окончания"
            minDate={values.kind === "main" && values.startedAt && values.startedAt}
            maxDate={values.kind === "main" && values.startedAt && addDays(values.startedAt, 1)}
          />
        </div>
      </div>
    )}
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
