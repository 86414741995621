import axios from "axios"
import Qs from "qs"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import isValid from "date-fns/isValid"
import convertErrorsObject from "./interceptors/convertErrorsObject"
import camelize from "./interceptors/camelize"
import decamelize from "./interceptors/decamelize"
// import notAuthorized from "./interceptors/notAuthorized"

// dirty hack for axios date serialization

Date.prototype.toJSON = function () {
  return format(this, "yyyy-MM-dd HH:mm:ss")
}

const instance = axios.create({
  baseURL: "/rest",
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "brackets" })
})

function isIsoDateString(value) {
  const parsedDate = parseISO(value)
  return value && typeof value === "string" && value.length > 8 && /\d{4}\-\d{2}\-\d{2}/.test(value) && isValid(parsedDate)
}

export function handleDates(body) {
  if (body === null || body === undefined || typeof body !== "object") return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) body[key] = parseISO(value.replace(/\.000Z$/, ""))
    else if (typeof value === "object") handleDates(value)
  }
}

instance.interceptors.response.use(...convertErrorsObject)
// instance.interceptors.response.use(...notAuthorized)
instance.interceptors.response.use(...camelize)
instance.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data)
  return originalResponse
})
instance.interceptors.request.use(decamelize)

export default instance
