import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Clients = () => (
  <Routes>
    <Route
      path="/clients/:clientId/entities"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/entities`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId/entities/new"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/entities/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/entities/:entityId/edit"
      fetchData={async ({ clientId, entityId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/entities/${entityId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Clients
