import instance from "connection/instance"
import format from "date-fns/format"

const prepareParams = (params = {}) => {
  const { byFinishFrom, byFinishTo, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byFinishFrom: byFinishFrom && format(byFinishFrom, "yyyy-MM-dd"),
      byFinishTo: byFinishTo && format(byFinishTo, "yyyy-MM-dd")
    }
  }
}

const Project = {
  fetch: (params) => instance.get("/hrs", { params: prepareParams(params) })
}

export default Project
