import { useState } from "react"
import ManagerApi from "api/manager"

const useManagers = (collection) => {
  const [managers, setManagers] = useState(collection)

  const createManager = async (params) => await ManagerApi.create(params)
  const destroyManager = async (params) => await ManagerApi.destroy(params)
  const createManagerWithRefresh = async (params) => {
    const { data } = await createManager(params)
    setManagers(managers.concat([data.manager]))
  }
  const destroyManagerWithRefresh = async (params) => {
    await destroyManager(params)
    setManagers(managers.filter((i) => i.id !== params.id))
  }

  return { managers, createManagerWithRefresh, destroyManagerWithRefresh }
}

export default useManagers
