import React from "react"
import { Button } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import EntitySelect from "components/Common/EntitySelect"
import EntityModal from "./components/EntityModal"

const EntitySelectWithCreating = ({ clientId, name = "entity", options, onEntityAdded, ...props }) => {
  const [isEntityModalOpen, entityModalData, openEntityModal, closeEntityModal] = useModalTrigger()
  return (
    <div className="mb-3">
      <EntitySelect {...props} isDisabled={!clientId} />
      <Button onClick={openEntityModal} disabled={!clientId} size="sm">
        Создать юридическое лицо
      </Button>
      <EntityModal
        clientId={clientId}
        isOpen={isEntityModalOpen}
        closeModal={closeEntityModal}
        onEntityAdded={onEntityAdded}
      />
    </div>
  )
}

export default EntitySelectWithCreating
