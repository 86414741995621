import React from "react"
import { Destroy, Edit } from "components/UI"

const Table = ({ collection, destroyEmploymentWithFetch, project, manager }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Описание</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`estimates-${index}`}>
          <td>{i.description}</td>
          <td>
            <Edit url={`/projects/${project.id}/managers/${manager.id}/employments/${i.id}/edit`} />
            <Destroy
              action={() => destroyEmploymentWithFetch({ id: i.id })}
              disabled={i.cashes.filter((i) => i.status === "issued").length > 0}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
