import React, { Fragment } from "react"
import useModal from "hooks/useModal"
import { Lightbox } from "components/UI"
import classNames from "classnames"

const ImagePreview = ({ src, width = "100", height = "100", enableLightbox = true, className = "", ...props }) => {
  const [isLightboxModalOpen, openLightboxModal, closeLightboxModal] = useModal(false)
  const thumbSrc = src?.thumb
  const imageSrc = src?.file

  return thumbSrc ? (
    <Fragment>
      <img
        src={thumbSrc}
        className={classNames(["img-fluid ", "img-thumbnail", className])}
        width={width}
        height={height}
        {...props}
        onClick={openLightboxModal}
      />
      {imageSrc && enableLightbox && (
        <Lightbox
          isOpen={isLightboxModalOpen}
          closeModal={closeLightboxModal}
          src={imageSrc}
          contentType={src.contentType}
        />
      )}
    </Fragment>
  ) : (
    <div style={{ maxWidth: `${width}px`, maxHeight: `${height}px` }}>
      <svg
        className="bd-placeholder-img rounded float-left"
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Placeholder: 200x200"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <title>Изображение отсутствует</title>
        <rect width="100%" height="100%" fill="#6c757d"></rect>
      </svg>
    </div>
  )
}

export default ImagePreview
