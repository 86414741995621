import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import Form from "./components/Form"

const EditModal = ({ cashOptions, cashes, closeModal, isOpen, selectedCashes, updateCashesWithRefresh }) => {
  const handleSubmit = async (values) => {
    await updateCashesWithRefresh({
      data: values,
      query: {
        cashIds: selectedCashes.ids,
        type: selectedCashes.type,
        searchId: cashes.searchId
      }
    })
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader />
      <ModalBody>
        <Form entity={{ status: null }} options={cashOptions} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditModal
