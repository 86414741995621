import React from "react"
import { withFormik } from "formik"
import { SelectInput, DateInput, Submit, TextEditor } from "components/UI"
import ContactSelectWithCreating from "pages/Projects/components/Form/components/ContactSelectWithCreating"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ storyOptions, values, handleSubmit, setFieldValue }) => {
  const onContactAdded = (option) => setFieldValue("contact", option)
  return (
    <form onSubmit={handleSubmit}>
      <ContactSelectWithCreating
        defaultOptions={storyOptions.contacts}
        clientId={values.clientId}
        onContactAdded={onContactAdded}
      />
      <DateInput name="treatmentOn" label="Дата обращения" />
      <SelectInput name="kind.value" label="Тип" options={storyOptions.kinds} />
      <TextEditor name="subject" label="Тема" />
      <TextEditor name="result" label="Результат" />
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { contact } = entity
    return {
      ...entity,
      contact: contact ? { label: contact.fullName, value: contact.id } : null
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, `/clients/${values.clientId}/stories`)
})(Form)
