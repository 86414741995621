import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"

const Table = ({ collection, destroyServiceSetWithFetch }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`service-set-${index}`}>
          <td>
            <Link to={`/service_sets/${i.id}`}>{i.name}</Link>
          </td>
          <td>
            <Edit url={`/service_sets/${i.id}/edit`} />
            <Destroy action={() => destroyServiceSetWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
