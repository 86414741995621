import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import TabsBlock from "pages/Clients/components/TabsBlock"
import formatDate from "utils/formatDate"
import ReactAutolinker from "react-autolinker"

const Show = ({ client }) => (
  <Fragment>
    <Breadcrumbs>
      <BreadcrumbItem active>{client.name}</BreadcrumbItem>
      <BreadcrumbItem active>Карточка клиента</BreadcrumbItem>
    </Breadcrumbs>
    <TabsBlock client={client} />
    <Card>
      <Button url={`/clients/${client.id}/edit`} title="Редактировать">
        <i className="fas fa-edit" />
      </Button>
    </Card>
    <table className="table table-hover">
      <tbody>
        <tr>
          <td className="col-6">Название</td>
          <td>{client.name}</td>
        </tr>
        <tr>
          <td className="col-6">Псевдоним</td>
          <td>{client.nickname}</td>
        </tr>
        <tr>
          <td>Адрес</td>
          <td>{client.address}</td>
        </tr>
        <tr>
          <td>Сайт</td>
          <td>
            <ReactAutolinker text={client.site} />
          </td>
        </tr>
        <tr>
          <td>Телефоны</td>
          <td>{client.phones.map(({ value }) => value).join(", ")}</td>
        </tr>
        <tr>
          <td>Менеджер</td>
          <td>{client.manager?.fullName}</td>
        </tr>
        <tr>
          <td>Дата создания</td>
          <td>{formatDate(client.createdAt)}</td>
        </tr>
      </tbody>
    </table>
  </Fragment>
)

export default Show
