import React, { Fragment } from "react"
import Breadcrumbs from "pages/Presentations/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import usePresentations from "hooks/usePresentations"
import Table from "./components/Table"

const List = (props) => {
  const { presentations, fetchPresentations, destroyPresentationWithFetch } = usePresentations(props.presentations)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/presentations/new">Создать</Button>
      </Card>
      <Table collection={presentations} destroyPresentationWithFetch={destroyPresentationWithFetch} />
      <Paginate collection={presentations} fetch={fetchPresentations} />
    </Fragment>
  )
}

export default List
