import React, { Fragment, useEffect, useState } from "react"
import Breadcrumbs from "pages/Calendars/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import useEmploymentsCalendar from "hooks/useEmploymentsCalendar"
import useModal from "hooks/useModal"
import useModalTrigger from "hooks/useModalTrigger"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import usePermissions from "hooks/usePermissions"
import CreateEmploymentModal from "./components/CreateEmploymentModal"
import ShowEmploymentModal from "./components/ShowEmploymentModal"
import Filter from "./components/Filter"

const Employments = ({ employmentOptions, ...props }) => {
  const allow = usePermissions(["ADMIN", "ACCOUNT_MANAGER", "BOSS", "FINANCES"])
  const { employments, fetchEmploymentsCalendar, createEmploymentWithRefresh, destroyEmploymentWithRefresh } =
    useEmploymentsCalendar(props.employments)

  const [isCreateEmploymentModalOpen, openCreateEmploymentModal, closeCreateEmploymentModal] = useModal()
  const [isShowEmploymentModalOpen, editModalData, openShowEmploymentModal, closeShowEmploymentModal] = useModalTrigger(
    { extendedProps: {} }
  )

  const [filter, setFilter] = useState({})

  useEffect(() => {
    fetchEmploymentsCalendar({
      filter
    })
  }, [filter])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Календарь занятости</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button onClick={openCreateEmploymentModal} disabled={!allow}>
          Создать
        </Button>
      </Card>
      <Card>
        <Filter
          options={employmentOptions}
          collection={employments}
          handleSubmit={async (values) =>
            setFilter({ ...filter, byId: values.byId, byPositionId: values.byPositionId })
          }
        />
      </Card>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ru"
        firstDay={1}
        dayMaxEventRows={3}
        initialDate={new Date()}
        datesSet={({ startStr, endStr }) => setFilter({ ...filter, startStr, endStr })}
        eventClick={({ event }) => openShowEmploymentModal(event)}
        events={employments.data}
        moreLinkContent="Еще"
        displayEventTime={false}
        buttonText={{
          today: "Сегодня"
        }}
      />
      <CreateEmploymentModal
        isOpen={isCreateEmploymentModalOpen}
        closeModal={closeCreateEmploymentModal}
        employmentOptions={employmentOptions}
        createEmploymentWithRefresh={createEmploymentWithRefresh}
      />
      <ShowEmploymentModal
        isOpen={isShowEmploymentModalOpen}
        closeModal={closeShowEmploymentModal}
        data={editModalData}
        destroyEmploymentWithRefresh={destroyEmploymentWithRefresh}
      />
    </Fragment>
  )
}

export default Employments
