import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput, DateTimeInput } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <UserSelect name="employmentableId" label="Мененджер" required />
    <TextInput name="description" label="Описание" required />
    <DateTimeInput name="startedAt" label="Начало" required />
    <DateTimeInput name="finishedAt" label="Окончание" required />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/users")
})(Form)
