import { useState } from "react"
import ServiceApi from "api/service"

const useService = (collection) => {
  const [service, setService] = useState(collection)

  const createService = async (params) => {
    const { data } = await ServiceApi.create(params)
    setService(data.service)
    return data
  }

  const updateService = async (params) => {
    const { data } = await ServiceApi.update(params)
    setService(data.service)
    return data
  }

  return { service, createService, updateService }
}

export default useService
