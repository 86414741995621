import React from "react"
import "./styles.scss"
import Spinner from "react-bootstrap/Spinner"

const Loader = () => (
  <div className="wrapper">
    <div className="spinner">
      <Spinner
        animation="border"
        style={{
          width: "3rem",
          height: "3rem"
        }}
      />
    </div>
  </div>
)

export default Loader
