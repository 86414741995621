import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Reservations = () => (
  <Routes>
    <Route
      path="/projects/:projectId/reservations"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/reservations`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/reservations/:reservationId"
      fetchData={async ({ projectId, reservationId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/reservations/${reservationId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/projects/:projectId/reservations/new"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/reservations/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/reservations/:reservationId/edit"
      fetchData={async ({ projectId, reservationId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/reservations/${reservationId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Reservations
