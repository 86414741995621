import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import TabsBlock from "pages/ServiceSets/components/TabsBlock"

const Show = ({ serviceSet }) => (
  <Fragment>
    <Breadcrumbs>
      <BreadcrumbItem active>{serviceSet.name}</BreadcrumbItem>
      <BreadcrumbItem active>Карточка коллекции услуг</BreadcrumbItem>
    </Breadcrumbs>
    <TabsBlock serviceSet={serviceSet} />
    <Card>
      <Button url={`/service_sets/${serviceSet.id}/edit`} title="Редактировать">
        <i className="fas fa-edit" />
      </Button>
    </Card>
    <table className="table table-hover">
      <tbody>
        <tr>
          <td className="col-6">Название</td>
          <td>{serviceSet.name}</td>
        </tr>
      </tbody>
    </table>
  </Fragment>
)

export default Show
