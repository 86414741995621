import React from "react"
import { Modal, ModalBody, ModalHeader } from "components/UI"
import ServiceApi from "api/service"
import Form from "./components/Form"

const ServiceModal = ({ service, isOpen, closeModal, onServiceUpdate, ...props }) => {
  const handleSubmit = async (values) => {
    const { data } = await ServiceApi.update(values)
    onServiceUpdate(data.service)
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Редактирование услуги</ModalHeader>
      <ModalBody>
        <Form entity={service} handleSubmit={handleSubmit} />
      </ModalBody>
    </Modal>
  )
}

export default ServiceModal
