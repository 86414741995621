import React, { useState } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, ImagePreview } from "components/UI"
import formatDateTime from "utils/formatDateTime"
import ReservationApi from "api/reservation"
import download from "utils/download"

const Show = ({ project, reservation }) => {
  const [disabled, setDisabled] = useState(false)

  const onExport = async () => {
    setDisabled(true)
    const { data } = await ReservationApi.export({ id: reservation.id })
    setDisabled(false)
    download(data, `${reservation.id}.pdf`)
  }
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/reservations`}>Реквизит</BreadcrumbItem>
        <BreadcrumbItem active>{reservation.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/projects/${project.id}/reservations/${reservation.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <Button variant="success" className="float-right" onClick={onExport} disabled={disabled}>
          Экспорт в .pdf
        </Button>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-lg-6">Отгрузка со склада</td>
            <td>{formatDateTime(reservation.startedAt)}</td>
          </tr>
          <tr>
            <td className="col-lg-6">Возврат на склад</td>
            <td>{formatDateTime(reservation.finishedAt)}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th>Наименование</th>
            <th>Изображение</th>
            <th>Количество</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {reservation.reservationRequisites.map((i) => (
            <tr key={`reservation-requisite-${i.id}`}>
              <td>{i.requisite.name}</td>
              <td>
                <ImagePreview src={i.requisite.file} />
              </td>
              <td>{i.count}</td>
              <td>{i.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </DisabledPage>
  )
}

export default Show
