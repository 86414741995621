import { useState } from "react"
import HrApi from "api/hr"

const useHrs = (collection) => {
  const [hrs, setHrs] = useState(collection)

  const fetchHrs = async (params) => {
    const { data } = await HrApi.fetch(params)
    setHrs(data.hrs)
    return data.hrs
  }

  return { hrs, fetchHrs }
}

export default useHrs
