import React from "react"
import ReactModal from "react-modal"

const Modal = ({ children, isOpen, onRequestClose }) => (
  <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" ariaHideApp={false}>
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        {React.Children.map(children, (child) => React.cloneElement(child, { onRequestClose: onRequestClose }))}
      </div>
    </div>
  </ReactModal>
)

const ModalHeader = ({ children, onRequestClose, disabled = false }) => (
  <div className="modal-header">
    <h5 className="modal-title">{children}</h5>
    <button
      type="button"
      className="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      onClick={() => !disabled && onRequestClose()}
    />
  </div>
)

const ModalBody = ({ children }) => <div className="modal-body">{children}</div>

const ModalFooter = ({ children }) => <div className="modal-footer">{children}</div>

export { Modal, ModalHeader, ModalBody, ModalFooter }
