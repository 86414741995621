import React, { Fragment } from "react"
import formatMoney from "utils/formatMoney"

const GroupByKind = ({ kind, services }) => (
  <Fragment>
    <tr className="table-success">
      <td colSpan="9">{kind.label}</td>
    </tr>
    {services.map((i, index) => (
      <tr key={`service-${index}`}>
        <td>{i.orderName}</td>
        <td>{i.quantity}</td>
        <td>{i.working}</td>
        <td>{i.kind.value === "main" && i.numberOfHours}</td>
        <td>{formatMoney(i.innerCostPerHour)}</td>
        <td>{formatMoney(i.outerCostPerHour)}</td>
        <td>{i.discount}</td>
        <td className="table-info">{formatMoney(i.innerCost)}</td>
        <td className="table-danger">{formatMoney(i.outerCost)}</td>
      </tr>
    ))}
  </Fragment>
)

export default GroupByKind
