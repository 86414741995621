import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem } from "components/UI"
import TabsBlock from "pages/Projects/components/TabsBlock"
import ProjectStaffsByService from "./components/ProjectStaffsByService"

const StaffsByTemplateId = ({ project, service, projectStaffs }) => {
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem active>Переработки</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <ProjectStaffsByService projectStaffs={projectStaffs} project={project} service={service} />
    </DisabledPage>
  )
}

export default StaffsByTemplateId
