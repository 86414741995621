import React from "react"
import { withFormik } from "formik"
import { Submit, PhoneInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const PhoneForm = ({ handleSubmit }) => (
  <form className="login" onSubmit={handleSubmit}>
    <PhoneInput name="login" label="Телефон" autoFocus />
    <Submit>Отправить разовый пароль</Submit>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/")
})(PhoneForm)
