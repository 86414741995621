import React from "react"
import Form from "pages/Clients/containers/Contacts/components/Form"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import useContact from "hooks/useContact"

const ContactModal = ({ clientId, isOpen, closeModal, onContactAdded }) => {
  const { createContact } = useContact({})

  const handleSubmit = async (values) => {
    const { contact } = await createContact(values)
    onContactAdded({ label: contact.fullName, value: contact.id })
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать контактное лицо</ModalHeader>
      <ModalBody>
        <Form entity={{ clientId }} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ContactModal
