import React from "react"
import Card from "react-bootstrap/Card"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button } from "components/UI"
import useProjectStaffs from "hooks/useProjectStaffs"
import useModal from "hooks/useModal"
import useModalTrigger from "hooks/useModalTrigger"
import useSelect from "hooks/useSelect"
import useServices from "hooks/useServices"
import TabsBlock from "pages/Projects/components/TabsBlock"
import StaffModal from "./components/StaffModal"
import EditProjectStaffModal from "./components/EditProjectStaffModal"
import ProjectStaffsByService from "./components/ProjectStaffsByService"

const ProjectStaffs = ({ project, staffs, staffOptions, ...props }) => {
  const [isStaffModalOpen, openStaffModal, closeStaffModal] = useModal(false)
  const { services, updateServiceWithRefresh, increaseServicesProjectStaffsCount, reduceServicesProjectStaffsCount } =
    useServices(props.services)

  const {
    projectStaffs,
    createProjectStaffWithRefresh,
    updateProjectStaffWithRefresh,
    destroyProjectStaffWithRefresh,
    destroyProjectStaffsWithRefresh,
    fetchMoreProjectStaffs
  } = useProjectStaffs(props.projectStaffs)

  const availableServicesIds = services
    .filter((i) => (i.brief || "").replace(/<\/?[^>]+(>|$)/g, "").trim().length !== 0)
    .map((i) => i.id)

  const [
    selectedServiceIds,
    totalServiceIdsSelected,
    isServiceIdChecked,
    onServiceIdChecked,
    isServiceIdCheckedAll,
    onServiceIdCheckedAll,
    resetSelectedServiceIds
  ] = useSelect(availableServicesIds)

  const [
    isEditProjectStaffModalOpen,
    editProjectStaffModalData,
    openEditProjectStaffModal,
    closeEditProjectStaffModal
  ] = useModalTrigger()
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/teams`}>Команда</BreadcrumbItem>
        <BreadcrumbItem active>Персонал</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />

      <Card>
        <Card.Body>
          <Button variant="success" disabled={selectedServiceIds.length === 0} onClick={openStaffModal}>
            Создать
          </Button>
        </Card.Body>
      </Card>
      <ProjectStaffsByService
        destroyProjectStaffWithRefresh={destroyProjectStaffWithRefresh}
        destroyProjectStaffsWithRefresh={destroyProjectStaffsWithRefresh}
        isServiceIdChecked={isServiceIdChecked}
        isServiceIdCheckedAll={isServiceIdCheckedAll}
        onServiceIdChecked={onServiceIdChecked}
        onServiceIdCheckedAll={onServiceIdCheckedAll}
        openEditProjectStaffModal={openEditProjectStaffModal}
        projectStaffs={projectStaffs}
        resetSelectedServiceIds={resetSelectedServiceIds}
        selectedServiceIds={selectedServiceIds}
        services={services}
        availableServicesIds={availableServicesIds}
        updateServiceWithRefresh={updateServiceWithRefresh}
        fetchMoreProjectStaffs={fetchMoreProjectStaffs}
        reduceServicesProjectStaffsCount={reduceServicesProjectStaffsCount}
      />
      <StaffModal
        closeModal={() => {
          resetSelectedServiceIds()
          closeStaffModal()
        }}
        createProjectStaffWithRefresh={createProjectStaffWithRefresh}
        isOpen={isStaffModalOpen}
        options={staffOptions}
        projectStaffs={projectStaffs}
        serviceIds={selectedServiceIds}
        staffs={staffs}
        increaseServicesProjectStaffsCount={increaseServicesProjectStaffsCount}
      />
      <EditProjectStaffModal
        closeModal={closeEditProjectStaffModal}
        isOpen={isEditProjectStaffModalOpen}
        projectStaff={editProjectStaffModalData}
        updateProjectStaffWithRefresh={updateProjectStaffWithRefresh}
      />
    </DisabledPage>
  )
}

export default ProjectStaffs
