import instance from "connection/instance"

const StaffExperience = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/experiences`, { params })
  }
}

export default StaffExperience
