import instance from "connection/instance"

const Entity = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/entities`, { params })
  },
  create: (data) => instance.post("/entities", { data }),
  update: (data) => instance.put(`/entities/${data.id}`, { data }),
  destroy: (data) => instance.delete(`/entities/${data.id}`),
  suggest(params) {
    return instance.get(`${this.baseURL}/entities/suggest`, { params })
  }
}

export default Entity
