import store from "store"
import getParams from "utils/getParams"

const historyPushToStore = (key, search) => {
  store.set(key, search)
  window.history.pushState(null, "", search)
}

const historyPushFromStore = (key = window.location.pathname) => {
  const search = store.get(key)
  if (search) {
    window.history.pushState(null, "", search)
  }
}

const restoreParams = () => {
  const params = getParams()
  const prevUrl = window.history.state?.prevUrl
  console.log(prevUrl)
  if (Object.keys(params).length === 0 && prevUrl) {
    historyPushFromStore()
  } else {
    const { pathname, search } = window.location
    historyPushToStore(pathname, search)
  }

  return params
}

export { historyPushToStore, historyPushFromStore, restoreParams }
