import React from "react"
import { Destroy, Edit } from "components/UI"
import formatDate from "utils/formatDate"
import usePermissions from "hooks/usePermissions"

const Table = ({ collection, destroyReservationWithFetch }) => {
  const allow = usePermissions(["ADMIN"])
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">Резерв</th>
          <th scope="col">№ проекта</th>
          <th scope="col">Аккаунт-менеджер </th>
          <th scope="col">Статус</th>
          <th className="actions two" />
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr
            key={`reservation-${index}`}
            className={
              {
                treatment: "table-danger",
                finished: "",
                canceled: "table-primary",
                ready: "table-warning",
                issued: "table-success"
              }[i.status.value]
            }
          >
            <td>
              {formatDate(i.startedAt)} - {formatDate(i.finishedAt)}
            </td>
            <td>{i.project.id}</td>
            <td>{i.project.accountManager.fullName}</td>
            <td>{i.status.label}</td>
            <td>
              <Edit url={`/reservations/${i.id}/edit`} />
              <Destroy action={() => destroyReservationWithFetch({ id: i.id })} disabled={!allow} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
