import React, { Fragment } from "react"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import groupBy from "lodash.groupby"
import GroupByKind from "./components/GroupByKind"

const Table = ({ kinds, estimate }) => {
  const services = estimate.services.filter((i) => !i._destroy)

  const innerCostSum = services.reduce((a, b) => a + parseMoney(b.innerCost), 0)
  const outerCostSum = services.reduce((a, b) => a + parseMoney(b.outerCost), 0)
  const groupByKind = groupBy(services, "kind.value")

  const acCost = estimate.isWithAc ? outerCostSum * parseInt(estimate.ac) * 0.01 : 0
  const ndsCost = estimate.calculationKind.value === "nds" ? (outerCostSum + acCost) * 0.2 : 0

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th>Наименование в заказ-наряде</th>
          <th>Кол-во</th>
          <th>Дата и время работы</th>
          <th>Кол-во часов</th>
          <th>Стоимость за час внутренняя, руб.</th>
          <th>Стоимость за час клиентская, руб.</th>
          <th>Скидка, %.</th>
          <th>Общая стоимость внутренняя, руб.</th>
          <th>Общая стоимость клиентская, руб.</th>
        </tr>
      </thead>
      <tbody>
        {kinds.map(
          (kind) =>
            groupByKind[kind.value] && (
              <GroupByKind key={`service-${kind.value}`} kind={kind} services={groupByKind[kind.value]} />
            )
        )}
      </tbody>
      <tfoot>
        {estimate.isWithAc && (
          <tr>
            <th colSpan="7" className="text-right">
              Агентская комиссия
            </th>
            <th></th>
            <th>{formatMoney(acCost)}</th>
          </tr>
        )}
        <tr>
          <th colSpan="7" className="text-right">
            Итого
          </th>
          <th>{formatMoney(innerCostSum)}</th>
          <th>{formatMoney(outerCostSum + acCost)}</th>
        </tr>
        {estimate.calculationKind.value === "nds" && (
          <Fragment>
            <tr>
              <th colSpan="7" className="text-right">
                НДС 20%:
              </th>
              <th></th>
              <th>{formatMoney(ndsCost)}</th>
            </tr>
            <tr>
              <th colSpan="7" className="text-right">
                Итого с учётом НДС:
              </th>
              <th></th>
              <th>{formatMoney(outerCostSum + acCost + ndsCost)}</th>
            </tr>
          </Fragment>
        )}
        <tr>
          <th colSpan="7" className="text-right">
            Сметная прибль
          </th>
          <th>{formatMoney(outerCostSum + acCost - innerCostSum)}</th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  )
}

export default Table
