import React from "react"
import { Destroy, Edit } from "components/UI"

const Table = ({ collection, destroyClientWithFetch, client }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Номер</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`sources-${index}`}>
          <td>{i.number}</td>
          <td>
            <Edit url={`/clients/${client.id}/agreements/${i.id}/edit`} />
            <Destroy action={() => destroyClientWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
