import { useEffect, useState } from "react"

const useSelect = (ids = [], resetTrigger = false) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    resetTrigger && setSelected([])
  }, [resetTrigger])

  const isChecked = (id) => selected.includes(id)

  const onChecked = (id) => {
    const ids = isChecked(id) ? selected.filter((i) => i !== id) : selected.concat([id])
    setSelected(ids)
  }

  const isCheckedAll = selected.length > 0 && selected.length === ids.length

  const onCheckedAll = (e) => (e.target.checked ? setSelected(ids) : setSelected([]))

  const totalSelected = selected.length

  const resetSelected = () => setSelected([])

  return [selected, totalSelected, isChecked, onChecked, isCheckedAll, onCheckedAll, resetSelected]
}

export default useSelect
