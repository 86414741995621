import React from "react"
import { withFormik } from "formik"
import { DateTimeInput, Submit, CurrencyInput, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="description" label="Описание" required />
    <DateTimeInput name="startedAt" label="Начало" required />
    <DateTimeInput name="finishedAt" label="Окончание" required />
    <CurrencyInput name="payment" label="Сумма к выдаче" />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, `/projects/${values.projectId}/managers/${values.employmentableId}/employments`)
})(Form)
