import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useContact from "hooks/useContact"
import Form from "pages/Clients/containers/Contacts/components/Form"

const Edit = ({ client, ...props }) => {
  const { contact, updateContact } = useContact(props.contact)
  const { kinds } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/contacts`}>Контактные лица</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...contact, clientId: client.id }}
        options={{ kinds }}
        handleSubmit={async (values) => updateContact({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
