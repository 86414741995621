import React, { Fragment } from "react"
import { DateInput, SelectInput, ResetButton, Submit } from "components/UI"
import { withFormik } from "formik"
import formatMoney from "utils/formatMoney"

const Filter = ({ collection, options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <SelectInput name="byStatus" label="Статус" options={options.statuses} />
      </div>
      <div className="col-3">
        <DateInput name="byFinishFrom" label="Дата окончания проекта с" />
      </div>
      <div className="col-3">
        <DateInput name="byFinishTo" label="Дата окончания проекта по" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
    {collection.statistics.map((i, index) => (
      <Fragment key={`statistic-${index}`}>
        &nbsp;&nbsp;&nbsp;{i[0]}: <strong>{formatMoney(i[1])}</strong>
      </Fragment>
    ))}
  </form>
)

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, { props }) => props.handleSubmit(values)
})(Filter)
