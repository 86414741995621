import React from "react"
import { Tab, Tabs } from "components/UI"

const FinancesTabsBlock = ({ project: { id } }) => {
  const mappings = [
    ["Сметы", `/projects/${id}/estimates`, `/projects/${id}/estimates`],
    [
      "Расходы",
      `/projects/${id}/expenses`,
      (path) => /\/projects\/\d+\/expenses/.test(path) || /\/projects\/\d+\/cashes/.test(path)
    ],
    ["Платежи", `/projects/${id}/payments`, (path) => /\/projects\/\d+\/payments/.test(path)]
  ]

  const paths = Object.keys(mappings)
  const onSelect = (i) => window.history.pushState(null, "", mappings[i][1])
  const activeKey = paths.findIndex((i) => {
    const mapping = mappings[i][2]
    if (typeof mapping === "function") {
      return mapping(window.location.pathname.replace(/\/+$/, ""))
    }
    return mapping === window.location.pathname.replace(/\/+$/, "")
  })

  return (
    <Tabs activeKey={activeKey} onSelect={onSelect}>
      {mappings.map((i, index) => (
        <Tab key={index} eventKey={index} title={i[0]} />
      ))}
    </Tabs>
  )
}

export default FinancesTabsBlock
