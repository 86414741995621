import instance from "connection/instance"
import parseMoney from "utils/parseMoney"

const prepare = (data = {}) => {
  const { cost, ...other } = data
  return {
    cost: parseMoney(cost),
    ...other
  }
}

const Entity = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  create: (data) => instance.post("/expenses", { data: prepare(data) }),
  update: (data) => instance.put(`/expenses/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/expenses/${data.id}`)
}

export default Entity
