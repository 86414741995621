const parseMoney = (value) => {
  const money = parseFloat(
    String(value || "")
      .replace(",", ".")
      .replace(/[^\d.]/g, "")
  )
  return isNaN(money) ? 0 : money
}

export default parseMoney
