import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useContact from "hooks/useContact"

const Edit = ({ client, ...props }) => {
  const { contact, updateContact } = useContact(props.contact)
  const { kinds } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/contacts`}>Контактные лица</BreadcrumbItem>
        <BreadcrumbItem active>{contact.fullName}</BreadcrumbItem>
      </Breadcrumbs>
    </Fragment>
  )
}

export default Edit
