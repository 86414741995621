import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useStories from "hooks/useStories"
import TabsBlock from "pages/Clients/components/TabsBlock"
import Table from "./components/Table"

const List = ({ client, ...props }) => {
  const { stories, fetchStories, destroyStoryWithFetch } = useStories(props.stories, `/clients/${client.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem active>История</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock client={client} />
      <Card>
        <Button url={`/clients/${client.id}/stories/new`}>Создать</Button>
      </Card>
      <Table collection={stories} destroyStoryWithFetch={destroyStoryWithFetch} client={client} />
      <Paginate collection={stories} fetch={fetchStories} />
    </Fragment>
  )
}

export default List
