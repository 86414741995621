import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { useField } from "formik"

const TextInput = ({ name, label, type = "text", required = false, hint = null, ...props }) => {
  const id = nanoid()
  const [{ onBlur, onChange }, { value, error }] = useField(name)
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className={classnames("mb-3", { required })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        autoComplete="off"
        className={className}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        value={value}
        {...props}
      />
      {hint && <small className="form-text text-muted">{hint}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}
export default TextInput
