import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn } from "formik"

const RadioButtonGroup = ({ label, name, children, disabled = false, afterChange = (option) => {}, ...props }) => {
  const id = nanoid()
  const { errors } = props.formik
  const error = getIn(errors, name.replace(".value", ""), false)
  const className = classnames("mb-3", { "is-invalid": error })
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { name: name, afterChange, disabled }))

  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <fieldset>{childrenWithProps}</fieldset>
      {error && <div className="invalid-feedback">{error[0]}</div>}
    </div>
  )
}

export default connect(RadioButtonGroup)
