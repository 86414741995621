import instance from "connection/instance"

const prepare = (data = {}) => {
  const {
    kind: { value: kind },
    createdAt,
    ...other
  } = data
  return {
    ...other,
    kind
  }
}

const Portfolio = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/portfolios`, { params })
  },
  create: (data) => instance.post("/portfolios", { data: prepare(data) }),
  update: (data) => instance.put(`/portfolios/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/portfolios/${data.id}`)
}

export default Portfolio
