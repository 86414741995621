import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatDate from "utils/formatDate"

const Table = ({ collection, destroyProjectWithFetch, project }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Интревал резерва</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`reservations-${index}`}>
          <td>
            <Link to={`/projects/${project.id}/reservations/${i.id}`}>{formatDate(i.startedAt)} - {formatDate(i.finishedAt)}</Link>
          </td>
          <td>{i.status.label}</td>
          <td>
            <Edit url={`/projects/${project.id}/reservations/${i.id}/edit`} />
            <Destroy action={() => destroyProjectWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
