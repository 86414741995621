import { useState } from "react"
import ClientApi from "api/client"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useClients = (collection) => {
  const [clients, setClients] = useState(collection)

  const fetchClients = async (params) => {
    const { data } = await ClientApi.fetch(params)
    setClients(data.clients)
    return data.clients
  }

  const destroyClient = async (params) => ClientApi.destroy(params)
  const destroyClientWithFetch = async (params) => afterDestroyFetch(clients, fetchClients, destroyClient, params)

  return { clients, fetchClients, destroyClient, destroyClientWithFetch }
}

export default useClients
