import { useState } from "react"
import CashApi from "api/cash"

const useCash = (collection) => {
  const [cash, setCash] = useState(collection)

  const createCash = async (params) => {
    const { data } = await CashApi.create(params)
    setCash(data.cash)
    return data
  }

  const updateCash = async (params) => {
    const { data } = await CashApi.update(params)
    setCash(data.cash)
    return data
  }

  return { cash, createCash, updateCash }
}

export default useCash
