import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useClients from "hooks/useClients"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ users, clientsFilterOptions, ...props }) => {
  const { clients, fetchClients, destroyClientWithFetch } = useClients(props.clients)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/clients/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          options={clientsFilterOptions}
          collection={clients}
          handleSubmit={async (values) => fetchClients({ filter: values })}
        />
      </Card>
      <Table collection={clients} destroyClientWithFetch={destroyClientWithFetch} />
      <Paginate collection={clients} fetch={fetchClients} />
    </Fragment>
  )
}

export default List
