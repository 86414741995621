import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn, ErrorMessage } from "formik"
import { AddressSuggestions } from "react-dadata"
import "react-dadata/dist/react-dadata.css"

const AddressInput = ({ name, label, type = "text", ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || props.value || ""
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">{label}</label>
      <AddressSuggestions
        token="5c97ae359bff07e1a5ed292c09d8eb511caee739"
        placeholder={value}
        onBlur={handleBlur}
        value={{ value }}
        onChange={({ unrestricted_value }) => props.formik.setFieldValue(name, unrestricted_value)}
        inputProps={props}
        {...{ className, id, name, type, ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(AddressInput)
