import { useState } from "react"
import ExpenseApi from "api/expense"

const useExpenses = (collection) => {
  const [expenses, setExpenses] = useState(collection)

  const createExpense = async (params) => await ExpenseApi.create(params)
  const updateExpense = async (params) => await ExpenseApi.update(params)
  const destroyExpense = async (params) => await ExpenseApi.destroy(params)
  const createExpenseWithRefresh = async (params) => {
    const { data } = await createExpense(params)
    setExpenses(expenses.concat(data.expense))
  }
  const updateExpenseWithRefresh = async (params) => {
    const { data } = await updateExpense(params)

    setExpenses(
      expenses.map((i) => {
        if (i.id === data.expense.id) {
          return data.expense
        }
        return i
      })
    )
  }
  const destroyExpenseWithRefresh = async (params) => {
    await destroyExpense(params)
    setExpenses(expenses.filter((i) => i.id !== params.id))
  }

  return {
    expenses,
    createExpenseWithRefresh,
    updateExpenseWithRefresh,
    destroyExpenseWithRefresh
  }
}

export default useExpenses
