import instance from "connection/instance"

const prepare = (data = {}) => {
  const { manager, ...other } = data
  return {
    ...other,
    managerId: manager?.value || null
  }
}

const Client = {
  fetch: (params) => instance.get("/clients", { params }),
  create: (data) => instance.post("/clients", { data: prepare(data) }),
  update: (data) => instance.put(`/clients/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/clients/${data.id}`),
  suggest: (params) => instance.get("/clients/suggest", { params }),
  export: () =>
    instance.get(`/clients/export_to_xlsx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-excel"
      }
    })
}

export default Client
