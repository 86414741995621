import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePortfolio from "hooks/usePortfolio"
import Form from "pages/Staffs/containers/Portfolios/components/Form"

const Edit = ({ staff, ...props }) => {
  const { portfolio, updatePortfolio } = usePortfolio(props.portfolio)
  const { kinds } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem to={`/staffs/${staff.id}/portfolios`}>Портфолио</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...portfolio, staffId: staff.id }}
        options={{ kinds }}
        handleSubmit={async (values) => updatePortfolio({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
