import React from "react"
import { Link } from "components/Router"
import Card from "react-bootstrap/Card"

const Reserves = ({ project, reserves }) => (
  <Card>
    <Card.Header>Резерв</Card.Header>
    <Card.Body>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">Позиция</th>
            <th scope="col" className="col-3">Количество (набрано)</th>
          </tr>
        </thead>
        <tbody>
          {reserves.map((i, index) => (
            <tr key={`service-${index}`}>
              <td>
                <Link to={`/projects/${project.id}/teams/reserves/${i.id}`}>{i.innerName}</Link>
              </td>
              <td>
                {i.projectStaffsCount} из {i.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card.Body>
  </Card>
)

export default Reserves
