import React, { Fragment } from "react"
import Breadcrumbs from "pages/PaymentRegistries/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePaymentRegistry from "hooks/usePaymentRegistry"
import Form from "pages/PaymentRegistries/components/Form"

const Edit = ({ paymentRegistryOptions, staffOptions, staffs, ...props }) => {
  const { paymentRegistry, updatePaymentRegistry } = usePaymentRegistry(props.paymentRegistry)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{paymentRegistry.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={paymentRegistry}
        paymentRegistryOptions={paymentRegistryOptions}
        staffOptions={staffOptions}
        staffs={staffs}
        handleSubmit={async (values) => updatePaymentRegistry(values)}
      />
    </Fragment>
  )
}

export default Edit
