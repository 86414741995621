import { useState } from "react"
import PortfolioApi from "api/portfolio"
import afterDestroyFetch from "utils/afterDestroyFetch"

const usePortfolios = (collection, url = null) => {
  const [portfolios, setPortfolios] = useState(collection)

  const fetchPortfolios = async (params) => {
    const { data } = await PortfolioApi.setBaseURL(url).fetch(params)
    setPortfolios(data.portfolios)
    return data
  }

  const destroyPortfolio = async (params) => PortfolioApi.destroy(params)
  const destroyPortfolioWithFetch = async (params) =>
    afterDestroyFetch(portfolios, fetchPortfolios, destroyPortfolio, params)

  return { portfolios, fetchPortfolios, destroyPortfolio, destroyPortfolioWithFetch }
}

export default usePortfolios
