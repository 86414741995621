import React from "react"
import { Button } from "components/UI"
import useModalTrigger from "hooks/useModalTrigger"
import ClientSelect from "components/Common/ClientSelect"
import ClientModal from "./components/ClientModal"

const ClientSelectWithCreating = ({ name = "client", options, afterChange = () => {}, onClientAdded, ...props }) => {
  const [isClientModalOpen, clientModalData, openClientModal, closeClientModal] = useModalTrigger()
  return (
    <div className="mb-3">
      <ClientSelect
        defaultOptions={options.clients}
        options={{ users: options.users }}
        afterChange={afterChange}
        {...props}
      />
      <Button onClick={openClientModal} size="sm">
        Создать клиента
      </Button>
      <ClientModal
        options={options}
        isOpen={isClientModalOpen}
        closeModal={closeClientModal}
        onClientAdded={onClientAdded}
      />
    </div>
  )
}

export default ClientSelectWithCreating
