import Select from "react-select"
import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { getIn, useField, useFormikContext } from "formik"

const SelectInput = ({
  name,
  label,
  options,
  required = false,
  isMulti = false,
  disabled = false,
  errorName = null,
  isClearable = false,
  ...props
}) => {
  const id = nanoid()
  const [{ onBlur }, { value: originalValue }, { setValue }] = useField(name)
  const error = getIn(useFormikContext().errors, errorName || name.replace(".value", ""), false)
  const value = isMulti
    ? options.filter(({ value }) => originalValue && originalValue.includes(value))
    : options.find(({ value }) => value === getIn(originalValue, "value", originalValue))
  const className = classnames("react-select", { "is-invalid": error })

  const errorStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e74a3b"
    })
  }

  return (
    <div className={classnames("mb-3", { required: required })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <Select
        id={id}
        isDisabled={disabled}
        className={className}
        escapeClearsValue={false}
        noOptionsMessage={() => "Нет элементов для выбора"}
        onBlur={onBlur}
        onChange={(option) => {
          if (option) {
            Array.isArray(option) ? setValue(option.map(({ value }) => value)) : setValue(option.value)
          } else {
            setValue(option)
          }
        }}
        options={options}
        placeholder="Выбрать"
        value={value || ""}
        isMulti={isMulti}
        isClearable={isClearable}
        styles={error && errorStyles}
        {...props}
      />
      {error && <div className="invalid-feedback">{error.join(', ')}</div>}
    </div>
  )
}

export default SelectInput
