import { useState } from "react"
import ServicesCalendarApi from "api/servicesCalendar"

const useServicesCalendar = (collection, url = null) => {
  const [services, setServices] = useState(collection)

  const fetchServicesCalendar = async (params) => {
    const { data } = await ServicesCalendarApi.fetch(params)
    setServices(data.services)
    return data
  }

  return { services, fetchServicesCalendar }
}

export default useServicesCalendar
