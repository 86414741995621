import React from "react"
import { Destroy, Clone, Edit } from "components/UI"
import { Link } from "components/Router"
import classnames from "classnames"

const Table = ({ collection, cloneEstimateWithFetch, destroyEstimetWithFetch, project }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название</th>
        <th scope="col" className="actions three" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`estimates-${index}`} className={classnames({ "table-success": i.isDefault })}>
          <td>
            <Link to={`/projects/${project.id}/estimates/${i.id}`}>{i.name}</Link>
          </td>
          <td>
            <Clone action={() => cloneEstimateWithFetch({ id: i.id })} />
            <Edit url={`/projects/${project.id}/estimates/${i.id}/edit`} />
            <Destroy action={() => destroyEstimetWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
