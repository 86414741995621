import React from "react"
import { withFormik } from "formik"
import { CheckboxInput } from "components/UI"
import usePermissions from "hooks/usePermissions"
import formSubmitHandler from "utils/formSubmitHandler"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { Submit } from "components/UI"

const Form = ({ entity, staff, values, setFieldValue, handleSubmit }) => {
  const allow = usePermissions(["ADMIN", "FINANCES"])
  const isPaymentIsIssuedCheckedAll = values.every((i) => i.paymentIsIssued)
  const onPaymentIsIssuedCheckedAll = (e) =>
    entity.forEach((_, index) => setFieldValue(`${index}.paymentIsIssued`, e.target.checked))

  const isInPaymentRegistryCheckedAll = values.every((i) => i.paymentRegistryProjectStaff?.inPaymentRegistry)
  const onInPaymentRegistryCheckedAll = (e) =>
    entity.forEach((_, index) =>
      setFieldValue(`${index}.paymentRegistryProjectStaff.inPaymentRegistry`, e.target.checked)
    )

  const isSelfemployed = staff.selfemployedRequisite?.isSelfemployed

  return (
    <form onSubmit={handleSubmit}>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ width: "60%" }}>
              Позиция
            </th>
            <th scope="col">Сумма к выплате</th>
            <th scope="col">Статус выдано</th>
            {isSelfemployed && <th scope="col">Добавить в реестр</th>}
          </tr>
        </thead>
        <tbody>
          {entity.map((i, index) => (
            <tr key={`project-staff-${index}`}>
              <td>
                {i.service.innerName} {i.service.working}
              </td>
              <td>{formatMoney(i.payment)}</td>
              <td>
                <CheckboxInput name={`${index}.paymentIsIssued`} disabled={!allow} />
              </td>
              {isSelfemployed && (
                <td>
                  <CheckboxInput name={`${index}.paymentRegistryProjectStaff.inPaymentRegistry`} disabled={!allow} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Итого:</th>
            <th>{formatMoney(entity.reduce((a, b) => a + parseMoney(b.payment), 0))}</th>
            <th>
              <CheckboxInput
                checked={isPaymentIsIssuedCheckedAll}
                onChange={onPaymentIsIssuedCheckedAll}
                disabled={!allow}
              />
            </th>
            {isSelfemployed && (
              <th>
                <CheckboxInput
                  checked={isInPaymentRegistryCheckedAll}
                  onChange={onInPaymentRegistryCheckedAll}
                  disabled={!allow}
                />
              </th>
            )}
          </tr>
        </tfoot>
      </table>
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
