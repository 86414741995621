import { useState } from "react"
import UserApi from "api/user"

const useUser = (collection) => {
  const [user, setUser] = useState(collection)

  const createUser = async (params) => {
    const { data } = await UserApi.create(params)
    setUser(data.user)
    return data
  }

  const updateUser = async (params) => {
    const { data } = await UserApi.update(params)

    setUser(data.user)
    return data
  }

  return { user, createUser, updateUser }
}

export default useUser
