import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Edit from "./containers/Edit"

const Services = () => (
  <Routes>
    <Route
      path="/projects/:projectId/services/:serviceId/edit"
      fetchData={async ({ projectId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/services/${serviceId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Services
