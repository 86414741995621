import React from "react"
import { withFormik } from "formik"
import { CurrencyInput, TextEditor } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ values, handleSubmit }) => {
  return (
    <form id="my-form" onSubmit={handleSubmit}>
      <TextEditor
        name="comment"
        label="Комментарий"
        required={values.payment > 0}
      />
      <CurrencyInput name="payment" label="Выдано" disabled={values.paymentIsIssued} />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
