import { useState } from "react"
import UserApi from "api/user"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useUsers = (collection) => {
  const [users, setUsers] = useState(collection)

  const fetchUsers = async (params) => {
    const { data } = await UserApi.fetch(params)
    setUsers(data.users)
    return data
  }

  const destroyUser = async (params) => await UserApi.destroy(params)
  const destroyUserWithFetch = async (params) => afterDestroyFetch(users, fetchUsers, destroyUser, params)

  return { users, fetchUsers, destroyUser, destroyUserWithFetch }
}

export default useUsers
