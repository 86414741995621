import React, { Fragment } from "react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import TabsBlock from "pages/Users/components/TabsBlock"
import { BreadcrumbItem, Button, Card, ImagePreview } from "components/UI"
import formatDate from "utils/formatDate"
import differenceInYears from "date-fns/differenceInYears"

const Show = ({ user }) => (
  <Fragment>
    <Breadcrumbs>
      <BreadcrumbItem active>{user.fullName}</BreadcrumbItem>
      <BreadcrumbItem active>Карточка сотрудника</BreadcrumbItem>
    </Breadcrumbs>
    <TabsBlock user={user} />
    <Card>
      <Button url={`/users/${user.id}/edit`} title="Редактировать">
        <i className="fas fa-edit" />
      </Button>
    </Card>
    <table className="table table-hover">
      <tbody>
        <tr>
          <td>Создал</td>
          <td>{user.creator?.fullName}</td>
        </tr>
        <tr>
          <td>Дата создания</td>
          <td>{formatDate(user.createdAt)}</td>
        </tr>
        <tr>
          <td>ID раздела «Персонал»</td>
          <td>{user.staffId}</td>
        </tr>
        <tr>
          <td>Позиция</td>
          <td>{user.positions.map((i) => i.name).join(", ")}</td>
        </tr>
        <tr>
          <td>Фотография</td>
          <td>
            <ImagePreview src={user.avatar} />
          </td>
        </tr>
        <tr>
          <td>Телефоны</td>
          <td>
            {user.phones.map((i, index) => [
              index > 0 && ", ",
              <a key={`phone-${i.id}`} href={`tel:${i.value}`}>
                +{i.value}
              </a>
            ])}
          </td>
        </tr>
        <tr>
          <td>Emails</td>
          <td>
            {user.emails.map((i, index) => [
              index > 0 && ", ",
              <a key={`email-${i.id}`} href={`mailto:${i.value}`}>
                {i.value}
              </a>
            ])}
          </td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>{user.fullName}</td>
        </tr>
        <tr>
          <td>Пол</td>
          <td>{user.passport.sex.label}</td>
        </tr>
        <tr>
          <td>Дата рождения</td>
          <td>
            {formatDate(user.passport.birthdayOn)} ({differenceInYears(new Date(), user.passport.birthdayOn)})
          </td>
        </tr>
        <tr>
          <td>Гражданство</td>
          <td>{user.passport.citizenship.label}</td>
        </tr>
        <tr>
          <td>Фактический адрес</td>
          <td>{user.address}</td>
        </tr>
        <tr>
          <td>Скан разворот</td>
          <td>
            <ImagePreview src={user.passport?.firstScan} />
          </td>
        </tr>
        <tr>
          <td>Скан прописка</td>
          <td>
            <ImagePreview src={user.passport?.secondScan} />
          </td>
        </tr>
        <tr>
          <td>Рабочий телефон</td>
          <td>
            <a href={`tel:${user.workPhone}`}>+{user.workPhone}</a>
          </td>
        </tr>
        <tr>
          <td>Рабочий email</td>
          <td>
            <a href={`mailto:${user.workEmail}`}>{user.workEmail}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </Fragment>
)

export default Show
