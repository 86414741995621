import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatDateTime from "utils/formatDateTime"
import truncate from "utils/truncate"

const Table = ({ collection, destroyRequestWithFetch }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Дата и время создания</th>
        <th scope="col">Тип</th>
        <th scope="col">Предмет запроса</th>
        <th scope="col">Статус</th>
        <th scope="col">Аккаунт-менеджер</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`requests-${index}`}>
          <td>
            <Link to={`/requests/${i.id}`}>{formatDateTime(i.createdAt)}</Link>
          </td>
          <td>{i.kind.label}</td>
          <td dangerouslySetInnerHTML={{ __html: truncate(i.brief) }} />
          <td>{i.status.label}</td>
          <td>{i.accountManager?.fullName}</td>
          <td>
            <Edit url={`/requests/${i.id}/edit`} />
            <Destroy action={() => destroyRequestWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
