import { useState } from "react"
import ServiceSetApi from "api/serviceSet"

const useServiceSet = (collection) => {
  const [serviceSet, setServiceSet] = useState(collection)

  const createServiceSet = async (params) => {
    const { data } = await ServiceSetApi.create(params)
    setServiceSet(data.serviceSet)
    return data
  }

  const updateServiceSet = async (params) => {
    const { data } = await ServiceSetApi.update(params)
    setServiceSet(data.serviceSet)
    return data
  }

  return { serviceSet, createServiceSet, updateServiceSet }
}

export default useServiceSet
