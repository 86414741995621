import React from "react"
import { Link } from "components/Router"
import formatDate from "utils/formatDate"

const Table = ({ collection }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Даты проекта</th>
        <th scope="col">Название</th>
        <th scope="col">Статус</th>
        <th scope="col">HR Менеджер</th>
        <th scope="col">Набрано в команду</th>
        <th scope="col">Не пришел</th>
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`hrs-${index}`}>
          <td>{i.id}</td>
          <td>{[formatDate(i.startedOn), formatDate(i.finishedOn)].filter(Boolean).join(" - ")}</td>
          <td>
            <Link to={`/projects/${i.id}/teams`}>{i.name}</Link>
          </td>
          <td>{i.status.label}</td>
          <td>{i.hrManagers}</td>
          <td>{i.recruitedProjectStaffsCount} из {i.projectStaffsCount}</td>
          <td>{i.skippedProjectStaffsCount}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
