import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useReservations from "hooks/useReservations"
import TabsBlock from "pages/Projects/components/TabsBlock"
import Table from "./components/Table"

const List = ({ project, ...props }) => {
  const { reservations, fetchReservations, destroyReservationWithFetch } = useReservations(props.reservations, `/projects/${project.id}`)
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Реквизит</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/reservations/new`}>Создать</Button>
      </Card>
      <Table collection={reservations} destroyProjectWithFetch={destroyReservationWithFetch} project={project} />
      <Paginate collection={reservations} fetch={fetchReservations} />
    </DisabledPage>
  )
}

export default List
