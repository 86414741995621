import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import ProjectStaffs from "./containers/ProjectStaffs"
import Overworks from "./containers/Overworks"
import StaffsByTemplateId from "./containers/StaffsByTemplateId"
import Reserves from "./containers/Reserves"
import Vacancies from "./containers/Vacancies"

const Teams = () => (
  <Routes>
    <Route
      path="/projects/:projectId/teams"
      fetchData={async ({ projectId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/teams`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/teams/staffs/:serviceId"
      fetchData={async ({ projectId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/project_staffs/${serviceId}`, {
          signal
        })
        return data
      }}
      component={ProjectStaffs}
    />
    <Route
      path="/projects/:projectId/teams/overworks/:serviceId"
      fetchData={async ({ projectId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/overworks/${serviceId}`, {
          signal
        })
        return data
      }}
      component={StaffsByTemplateId}
    />
    <Route
      path="/projects/:projectId/teams/overworks/:serviceId/staffs/:staffId"
      fetchData={async ({ projectId, serviceId, staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/overworks/${serviceId}/staffs/${staffId}`, {
          signal
        })
        return data
      }}
      component={Overworks}
    />
    <Route
      path="/projects/:projectId/teams/reserves/:serviceId"
      fetchData={async ({ projectId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/project_staffs/${serviceId}/reserves`, {
          signal
        })
        return data
      }}
      component={Reserves}
    />
    <Route
      path="/projects/:projectId/teams/vacancies/:serviceId"
      fetchData={async ({ projectId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/vacancies/${serviceId}`, {
          signal
        })
        return data
      }}
      component={Vacancies}
    />
  </Routes>
)
export default Teams
