import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"

const Table = ({ collection, destroyCourierWithFetch }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Отправитель</th>
        <th scope="col">Получатель</th>
        <th scope="col">Компания</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`couriers-${index}`}>
          <td>{i.id}</td>
          <td>
            <Link to={`/couriers/${i.id}`}>{i.senderName}</Link>
          </td>
          <td>{i.recipientName}</td>
          <td>{i.entity?.name}</td>
          <td>
            <Edit url={`/couriers/${i.id}/edit`} />
            <Destroy action={() => destroyCourierWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
