import { useState } from "react"
import PaymentApi from "api/payment"
import afterDestroyFetch from "utils/afterDestroyFetch"

const usePayments = (collection, url = null) => {
  const [payments, setPayments] = useState(collection)

  const fetchPayments = async (params) => {
    const { data } = await PaymentApi.setBaseURL(url).fetch(params)
    setPayments(data.payments)
    return data
  }

  const destroyPayment = async (params) => PaymentApi.destroy(params)
  const destroyPaymentWithFetch = async (params) => afterDestroyFetch(payments, fetchPayments, destroyPayment, params)

  return { payments, fetchPayments, destroyPayment, destroyPaymentWithFetch }
}

export default usePayments
