import instance from "connection/instance"

const Position = {
  fetch: (params) => instance.get("/positions", { params }),
  create: (data) => instance.post("/positions", { data }),
  update: (data) => instance.put(`/positions/${data.id}`, { data }),
  destroy: (data) => instance.delete(`/positions/${data.id}`)
}

export default Position
