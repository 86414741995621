import { useState } from "react"
import StaffExperienceApi from "api/staffExperience"

const useStaffExperiences = (collection, url = null) => {
  const [projectStaffs, setStaffExperiences] = useState(collection)

  const fetchStaffExperiences = async (params) => {
    const { data } = await StaffExperienceApi.setBaseURL(url).fetch(params)
    setStaffExperiences(data.projectStaffs)
    return data
  }

  return { projectStaffs, fetchStaffExperiences }
}

export default useStaffExperiences
