import instance from "connection/instance"
import format from "date-fns/format"

const prepareParams = (params = {}) => {
  const { byMetroStationIds, byAvailabilityTo, byAvailabilityFrom, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byMetroStationIds: (byMetroStationIds || []).map((i) => i.value),
      byAvailabilityTo: byAvailabilityTo && format(byAvailabilityTo, "yyyy-MM-dd"),
      byAvailabilityFrom: byAvailabilityFrom && format(byAvailabilityFrom, "yyyy-MM-dd")
    }
  }
}

const prepare = (data = {}) => {
  const {
    status: { value: status },
    clothesSize,
    metroStation,
    passport,
    ...other
  } = data
  return {
    ...other,
    status,
    clothesSize: clothesSize?.value || null,
    passport: {
      ...passport,
      sex: passport?.sex?.value || null
    },
    metroStationId: metroStation?.value || null
  }
}

const Staff = {
  fetch: (params) => instance.get("/staffs", { params: prepareParams(params) }),
  create: (data) => instance.post("/staffs", { data: prepare(data) }),
  update: (data) => instance.put(`/staffs/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/staffs/${data.id}`),
  exportContractToDocx: (data) =>
    instance.get(`/staffs/${data.id}/export_contract_to_docx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-word"
      }
    }),
  exportAgreementToDocx: (data) =>
    instance.get(`/staffs/${data.id}/export_agreement_to_docx`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/vnd.ms-word"
      }
    })
}

export default Staff
