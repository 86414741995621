import instance from "connection/instance"
import format from "date-fns/format"

const prepareParams = (params = {}) => {
  const { byAccountManagerId, byCreatedAtFrom, byCreatedAtTo, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byAccountManagerId: byAccountManagerId?.value || null,
      byCreatedAtFrom: byCreatedAtFrom && format(byCreatedAtFrom, "yyyy-MM-dd"),
      byCreatedAtTo: byCreatedAtTo && format(byCreatedAtTo, "yyyy-MM-dd")
    }
  }
}

const prepare = (data = {}) => {
  const { accountManager, kind, status, ...other } = data
  return {
    ...other,
    accountManagerId: accountManager?.value || null,
    kind: kind?.value || null,
    status: status?.value || null
  }
}

const Request = {
  fetch: (params) => instance.get("/requests", { params: prepareParams(params) }),
  create: (data) => instance.post("/requests", { data: prepare(data) }),
  update: (data) => instance.put(`/requests/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/requests/${data.id}`)
}

export default Request
