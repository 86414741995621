import { useState } from "react"
import ServiceApi from "api/service"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useServices = (collection, url = null) => {
  const [services, setServices] = useState(collection)

  const fetchServices = async (params) => {
    const { data } = await ServiceApi.setBaseURL(url).fetch(params)
    setServices(data.services)
    return data
  }

  const updateService = async (params) => await ServiceApi.update(params)
  const updateServiceWithRefresh = async (params) => {
    const { data } = await updateService(params)

    setServices(
      services.map((i) => {
        if (i.id === data.service.id) {
          return data.service
        }
        return i
      })
    )
  }

  const increaseServicesVacanciesCount = (_services) => {
    setServices(
      services.map((i) => {
        const service = _services.find((_i) => _i.id === i.id)
        if (service) {
          return { ...i, vacanciesCount: i.vacanciesCount + service.vacanciesCount }
        }
        return i
      })
    )
  }

  const increaseServicesAcceptVacanciesCount = (_services) => {
    setServices(
      services.map((i) => {
        const service = _services.find((_i) => _i.id === i.id)
        if (service) {
          return { ...i, acceptVacanciesCount: i.acceptVacanciesCount + service.acceptVacanciesCount }
        }
        return i
      })
    )
  }

  const reduceServicesAcceptVacanciesCount = (_services) => {
    setServices(
      services.map((i) => {
        const service = _services.find((_i) => _i.id === i.id)
        if (service) {
          return { ...i, acceptVacanciesCount: i.acceptVacanciesCount - service.acceptVacanciesCount }
        }
        return i
      })
    )
  }

  const increaseServicesProjectStaffsCount = (_services) => {
    setServices(
      services.map((i) => {
        const service = _services.find((_i) => _i.id === i.id)
        if (service) {
          return { ...i, projectStaffsCount: i.projectStaffsCount + service.projectStaffsCount }
        }
        return i
      })
    )
  }

  const reduceServicesProjectStaffsCount = (_services) => {
    setServices(
      services.map((i) => {
        const service = _services.find((_i) => _i.id === i.id)
        if (service) {
          return { ...i, projectStaffsCount: i.projectStaffsCount - service.projectStaffsCount }
        }
        return i
      })
    )
  }

  const destroyService = async (params) => ServiceApi.destroy(params)
  const destroyServiceWithFetch = async (params) => afterDestroyFetch(services, fetchServices, destroyService, params)

  return {
    services,
    fetchServices,
    updateServiceWithRefresh,
    increaseServicesVacanciesCount,
    increaseServicesAcceptVacanciesCount,
    reduceServicesAcceptVacanciesCount,
    increaseServicesProjectStaffsCount,
    reduceServicesProjectStaffsCount,
    destroyService,
    destroyServiceWithFetch
  }
}

export default useServices
