import React from "react"
import { withFormik } from "formik"
import { EmailInput, PhoneInput, TextEditor, SelectInput, Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ requestOptions, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Имя" />
    <PhoneInput name="phone" label="Телефон" />
    <EmailInput name="email" label="E-mail" />
    <TextInput name="roistat" label="Roistat" />
    <SelectInput name="kind.value" label="Тип" options={requestOptions.kinds} />
    <SelectInput name="status.value" label="Статус" options={requestOptions.statuses} />
    <SelectInput name="accountManager.value" label="Аккаунт-менеджер" options={requestOptions.accountManagers} />
    <TextEditor name="brief" label="Предмет запроса" />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { accountManager } = entity
    return {
      ...entity,
      accountManager: accountManager ? { label: accountManager.name, value: accountManager.id } : null
    }
  },
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(values, formikBag, ({ request: { id, status } }) => {
      if (status.value === "in_project") {
        return `/projects/new?request_id=${id}`
      } else {
        return "/requests"
      }
    })
})(Form)
