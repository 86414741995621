import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ values, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="count" label="Количество" type="number" min="0" hint={`Доступно к заказу: ${values.availableCount}`} required />
    <TextInput name="comment" label="Комментарий" />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
