import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import HTMLTextModal from "components/Common/HTMLTextModal"
import useModalTrigger from "hooks/useModalTrigger"
import truncate from "utils/truncate"
import formatDate from "utils/formatDate"

const Show = ({ client, story }) => {
  const [isHTMLTextModalOpen, htmlTextModalData, openHTMLTextModal, closeHTMLTextModal] = useModalTrigger("")
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/stories`}>История</BreadcrumbItem>
        <BreadcrumbItem active>{story.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/clients/${client.id}/stories/${story.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">Контактное лицо</td>
            <td>{story.contact.fullName}</td>
          </tr>
          <tr>
            <td>Дата обращения</td>
            <td>{formatDate(story.treatmentOn)}</td>
          </tr>
          <tr>
            <td>Тип</td>
            <td>{story.kind.label}</td>
          </tr>
          <tr>
            <td>Тема</td>
            <td
              onClick={() => openHTMLTextModal(story.subject)}
              dangerouslySetInnerHTML={{ __html: truncate(story.subject) }}
            />
          </tr>
          <tr>
            <td>Результат</td>
            <td
              onClick={() => openHTMLTextModal(story.result)}
              dangerouslySetInnerHTML={{ __html: truncate(story.result) }}
            />
          </tr>
        </tbody>
      </table>
      <HTMLTextModal isOpen={isHTMLTextModalOpen} closeModal={closeHTMLTextModal}>
        {htmlTextModalData}
      </HTMLTextModal>
    </Fragment>
  )
}

export default Show
