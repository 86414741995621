import React from "react"
import usePermissions from "hooks/usePermissions"
import classnames from "classnames"

const DisabledPage = ({ project, children }) => {
  const allow = usePermissions(["ADMIN"])
  const disabled = !allow && (project.status?.value || project.status) === "finished_and_paid"
  return <div className={classnames({ disabled: disabled })}>{children}</div>
}

export default DisabledPage
