import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requests/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useRequest from "hooks/useRequest"
import Form from "pages/Requests/components/Form"

const Edit = ({ requestOptions, staffOptions, staffs, ...props }) => {
  const { request, updateRequest } = useRequest(props.request)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{request.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={request}
        requestOptions={requestOptions}
        staffOptions={staffOptions}
        staffs={staffs}
        handleSubmit={async (values) => updateRequest(values)}
      />
    </Fragment>
  )
}

export default Edit
