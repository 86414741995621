import React, { Fragment } from "react"
import { ImagePreview, Button, Destroy } from "components/UI"
import groupBy from "lodash.groupby"
import CountBadge from "components/Common/CountBadge"
import useModalTrigger from "hooks/useModalTrigger"
import classnames from "classnames"
import Sticky from "react-stickynode"
import ServiceModal from "./components/ServiceModal"
import StatusModal from "./components/StatusModal"

const VacanciesByService = ({
  destroyVacancyWithRefresh,
  destroyVacanciesWithRefresh,
  isServiceIdChecked,
  isServiceIdCheckedAll,
  onServiceIdChecked,
  onServiceIdCheckedAll,
  vacancies,
  resetSelectedServiceIds,
  selectedServiceIds,
  services,
  availableServicesIds,
  intersectingServices,
  setIntersectingServices,
  updateServiceWithRefresh,
  closeVacanciesWithRefresh,
  acceptVacanciesWithRefresh,
  rejectVacanciesWithRefresh,
  reserveVacanciesWithRefresh,
  fetchMoreVacancies,
  increaseServicesAcceptVacanciesCount,
  reduceServicesAcceptVacanciesCount,
  overworks
}) => {
  const vacanciesByServiceId = groupBy(vacancies, "serviceId")
  const [isServiceModalOpen, serviceModalData, openServiceModal, closeServiceModal] = useModalTrigger({ service: null })
  const [isStatusModalOpen, statusModalData, openStatusModal, closeStatusModal] = useModalTrigger({
    vacancy: { staff: {} },
    vacancies: []
  })

  const vacanciesByService = (serviceId) => vacanciesByServiceId[serviceId] || []

  const onDestroyVacancies = async (service) => {
    const collection = vacanciesByService(service.id).map(({ id }) => {
      return {
        id
      }
    })
    await destroyVacanciesWithRefresh(collection)

    const services = [
      {
        id: parseInt(service.id, 10),
        acceptVacanciesCount: vacanciesByService(service.id).filter((i) => i.managerStatus.value === "accept").length
      }
    ]
    reduceServicesAcceptVacanciesCount(services)

    resetSelectedServiceIds()
  }

  const onDestroyVacancy = (service, vacancy) => {
    destroyVacancyWithRefresh({ id: vacancy.id })

    if (vacancy.managerStatus.value === "accept") {
      const services = [
        {
          id: parseInt(service.id, 10),
          acceptVacanciesCount: vacanciesByService(service.id).filter((i) => i.managerStatus.value === "accept").length
        }
      ]
      reduceServicesAcceptVacanciesCount(services)
    }
  }

  const onCloseVacancies = async (service) => {
    const collection = vacanciesByService(service.id).map(({ id }) => {
      return {
        id
      }
    })
    await closeVacanciesWithRefresh(collection)
    resetSelectedServiceIds()
  }

  // const isProjectStaffsComplete = services.every(
  //   (service) =>
  //     service.quantity === vacanciesByService(service.id).filter((i) => i.managerStatus.value === "accept").length
  // )

  const isProjectStaffsComplete = services.every((service) => service.quantity === service.acceptVacanciesCount)

  const vacancySorter = (a, b) => {
    const sortingArr = ["accept", null, "reject"]
    return sortingArr.indexOf(a.staffStatus.value) - sortingArr.indexOf(b.staffStatus.value)
  }

  const onLoadMore = ({ service }) => {
    const offset = vacanciesByService(service.id).length
    fetchMoreVacancies({ serviceId: service.id, offset })
  }

  return (
    <div id="profiles">
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="actions one">
              <input
                type="checkbox"
                checked={isServiceIdCheckedAll}
                onChange={onServiceIdCheckedAll}
                disabled={availableServicesIds.length === 0}
              />
            </th>
            <th scope="col" colSpan="6"></th>
            <th scope="col" className="actions two" />
          </tr>
        </thead>
      </table>
      {services.map((service, index) => (
        <Fragment key={`service-${index}`}>
          <Sticky enabled={true} top={48} bottomBoundary="#profiles" innerZ="100">
            <table className="table table-hover">
              <thead>
                <tr className="table-warning">
                  <td className="actions one">
                    <input
                      type="checkbox"
                      checked={isServiceIdChecked(service.id)}
                      onChange={() => onServiceIdChecked(service.id)}
                      disabled={
                        !availableServicesIds.includes(service.id) ||
                        (service.brief || "").replace(/<\/?[^>]+(>|$)/g, "").trim().length === 0
                      }
                    />
                  </td>
                  <td colSpan="6">
                    <strong>{service.innerName}</strong>&nbsp;({service.orderName}) &nbsp;&nbsp;&nbsp;
                    {service.working}
                    &nbsp;&nbsp;&nbsp;
                    <CountBadge projectStaffsCount={service.acceptVacanciesCount} quantity={service.quantity} />
                    <span className="thead-actions">
                      <Button
                        size="sm"
                        onClick={() => onCloseVacancies(service)}
                        disabled={vacanciesByService(service.id).every((i) => i.managerStatus?.value !== null)}
                      >
                        Закрыть вакансию
                      </Button>
                      &nbsp;
                      <Button size="sm" onClick={() => openServiceModal({ service: service })}>
                        Бриф
                      </Button>
                    </span>
                  </td>
                  <th scope="col" className="actions one text-right">
                    <Destroy
                      action={() => onDestroyVacancies(service)}
                      disabled={!selectedServiceIds.includes(service.id)}
                    />
                  </th>
                </tr>
              </thead>
            </table>
          </Sticky>

          <div className="row">
            {vacanciesByService(service.id)
              .sort(vacancySorter)
              .map((vacancy, index) => (
                <div key={`vacancy-${index}`} className="col-3">
                  <div
                    className={classnames(
                      "profile",
                      "bg-light",
                      "p-2",
                      { success: vacancy.staffStatus?.value === "accept" },
                      { danger: vacancy.staffStatus?.value === "reject" }
                    )}
                  >
                    {vacancy.managerStatus.value && (
                      <span
                        className={classnames(
                          "position-absolute",
                          "border",
                          "rounded-circle",
                          { "bg-success": vacancy.managerStatus?.value === "accept" },
                          { "bg-danger": vacancy.managerStatus?.value === "reject" },
                          { "bg-warning": vacancy.managerStatus?.value === "reserve" }
                        )}
                      />
                    )}
                    <ImagePreview
                      src={vacancy.staff.avatar}
                      width="75"
                      height="100"
                      className={vacancy.staff.selfemployedRequisite.isSelfemployed ? "selfemployed-avatar" : ""}
                    />
                    <a
                      href="#"
                      className="open-modal"
                      onClick={(e) => {
                        e.preventDefault()
                        openStatusModal({ vacancy })
                      }}
                    >
                      {vacancy.staff.fullName}
                    </a>
                    <br />
                    <span>Проекты: {vacancy.staff.projectCount}</span>
                    <br />
                    <span>Рейтинг: {vacancy.staff.rating}</span>
                    <Destroy
                      action={() => onDestroyVacancy(service, vacancy)}
                      disabled={
                        overworks
                          .filter((i) => i.staffId == vacancy.staff.id && i.serviceId == vacancy.serviceId)
                          .every(Boolean) &&
                        overworks.filter((i) => i.staffId == vacancy.staff.id && i.serviceId == vacancy.serviceId)
                          .length !== 0
                      }
                    />
                  </div>
                </div>
              ))}
            <div className="col-12">
              <Button
                variant="warning"
                title="Загрузить еще"
                onClick={() => onLoadMore({ service })}
                disabled={service.vacanciesCount <= vacanciesByService(service.id).length}
                style={{ marginBottom: "15px" }}
              >
                <i className="fas fa-sync-alt"></i>
              </Button>
            </div>
          </div>
        </Fragment>
      ))}
      <ServiceModal
        isOpen={isServiceModalOpen}
        closeModal={closeServiceModal}
        service={serviceModalData.service}
        updateServiceWithRefresh={updateServiceWithRefresh}
      />
      <StatusModal
        isProjectStaffsComplete={isProjectStaffsComplete}
        isOpen={isStatusModalOpen}
        closeModal={closeStatusModal}
        vacancies={vacancies}
        vacancy={statusModalData.vacancy}
        intersectingServices={intersectingServices}
        setIntersectingServices={setIntersectingServices}
        acceptVacanciesWithRefresh={acceptVacanciesWithRefresh}
        rejectVacanciesWithRefresh={rejectVacanciesWithRefresh}
        reserveVacanciesWithRefresh={reserveVacanciesWithRefresh}
        increaseServicesAcceptVacanciesCount={increaseServicesAcceptVacanciesCount}
      />
    </div>
  )
}

export default VacanciesByService
