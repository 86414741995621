import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn, ErrorMessage } from "formik"
import { IMaskInput } from "react-imask"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const CurrencyInput = ({ name, label, required = false, hint = null, ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })

  return (
    <div className={classnames("mb-3", { required: required })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <IMaskInput
        mask={Number}
        scale={2}
        padFractionalZeros={true}
        min={0}
        radix=","
        thousandsSeparator={" "}
        value={formatMoney(value)}
        unmask={false}
        onAccept={(value) => {
          setFieldValue(name, parseMoney(value).toString())
        }}
        // onBlur={handleBlur}
        {...{ className, id, name, ...props }}
      />
      {hint && <small className="form-text text-muted">{hint}</small>}
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(CurrencyInput)
