import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card } from "components/UI"
import useExpenses from "hooks/useExpenses"
import useModal from "hooks/useModal"
import TabsBlock from "pages/Projects/components/TabsBlock"
import FinancesTabsBlock from "pages/Projects/components/FinancesTabsBlock"
import Table from "./components/Table"
import StatisticModal from "./components/StatisticModal"

const List = ({ project, ...props }) => {
  const { expenses, destroyExpenseWithRefresh } = useExpenses(props.expenses, `/projects/${project.id}`)
  const [isStatiscticModalOpen, openStatiscticModal, closeStatiscticModal] = useModal()
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Финансы</BreadcrumbItem>
        <BreadcrumbItem active>Расходы</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <FinancesTabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/expenses/new`}>Создать</Button>
        <Button onClick={openStatiscticModal} className="float-right non-blocked" variant="success" title="Статистика">
          <i className="fas fa-chart-bar" />
        </Button>
      </Card>
      <Table collection={expenses} project={project} destroyExpenseWithRefresh={destroyExpenseWithRefresh} />
      <StatisticModal isOpen={isStatiscticModalOpen} closeModal={closeStatiscticModal} staistic={props.staistic} />
    </DisabledPage>
  )
}

export default List
