import React from "react"
import { withFormik } from "formik"
import { AddressInput, Submit, TextEditor, TextInput } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import Phones from "components/Common/Phones"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название" required />
    <TextInput name="nickname" label="Псевдоним" />
    <AddressInput name="address" label="Адрес" />
    <TextInput name="site" label="Сайт" />
    <Phones />
    <UserSelect name="manager" label="Менеджер" required />
    <TextEditor name="comment" label="Комментарий" />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { manager } = entity
    return {
      ...entity,
      manager: manager ? { label: manager.fullName, value: manager.id } : null
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/clients")
})(Form)
