import { useState } from "react"
import ContactApi from "api/contact"

const useContact = (collection) => {
  const [contact, setContact] = useState(collection)

  const createContact = async (params) => {
    const { data } = await ContactApi.create(params)
    setContact(data.contact)
    return data
  }

  const updateContact = async (params) => {
    const { data } = await ContactApi.update(params)
    setContact(data.contact)
    return data
  }

  return { contact, createContact, updateContact }
}

export default useContact
