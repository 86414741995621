import { useEffect, useState } from "react"

const useMultipageSelect = (data = [], resetTrigger = false) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    resetTrigger && setSelected([])
  }, [resetTrigger])

  const isChecked = (row, page) => selected[page] && selected[page].map((i) => i.id).includes(row.id)

  const onChecked = (row, page) => {
    const data = isChecked(row, page)
      ? selected[page].filter((i) => i.id !== row.id)
      : (selected[page] || []).concat([row])
    setSelected({ ...selected, [page]: data })
  }

  const isCheckedAll = (page) => selected[page] && selected[page].length > 0 && selected[page].length === data.length

  const onCheckedAll = (e, page) =>
    e.target.checked ? setSelected({ ...selected, [page]: data }) : setSelected({ ...selected, [page]: [] })

  const totalSelected = Object.values(selected).flat().length

  const resetSelected = () => setSelected({})

  return [Object.values(selected).flat(), totalSelected, isChecked, onChecked, isCheckedAll, onCheckedAll, resetSelected]
}

export default useMultipageSelect
