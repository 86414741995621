import { useState } from "react"
import PaymentRegistryApi from "api/paymentRegistry"
import afterDestroyFetch from "utils/afterDestroyFetch"

const usePaymentRegistries = (collection) => {
  const [paymentRegistries, setPaymentRegistries] = useState(collection)

  const fetchPaymentRegistries = async (params) => {
    const { data } = await PaymentRegistryApi.fetch(params)
    setPaymentRegistries(data.paymentRegistries)
    return data
  }

  const destroyPaymentRegistry = async (params) => PaymentRegistryApi.destroy(params)
  const destroyPaymentRegistryWithFetch = async (params) => afterDestroyFetch(paymentRegistries, fetchPaymentRegistries, destroyPaymentRegistry, params)

  return { paymentRegistries, fetchPaymentRegistries, destroyPaymentRegistry, destroyPaymentRegistryWithFetch }
}

export default usePaymentRegistries
