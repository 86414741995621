import { useState } from "react"
import PresentationApi from "api/presentation"

const usePresentation = (collection) => {
  const [presentation, setPresentation] = useState(collection)

  const createPresentation = async (params) => {
    const { data } = await PresentationApi.create(params)
    setPresentation(data.presentation)
    return data
  }

  const updatePresentation = async (params) => {
    const { data } = await PresentationApi.update(params)
    setPresentation(data.presentation)
    return data
  }

  return { presentation, createPresentation, updatePresentation }
}

export default usePresentation
