import { useState } from "react"
import ReservationApi from "api/reservation"

const useReservation = (collection) => {
  const [reservation, setReservation] = useState(collection)

  const createReservation = async (params) => {
    const { data } = await ReservationApi.create(params)
    setReservation(data.reservation)
    return data
  }

  const updateReservation = async (params) => {
    const { data } = await ReservationApi.update(params)
    setReservation(data.reservation)
    return data
  }

  return { reservation, createReservation, updateReservation }
}

export default useReservation
