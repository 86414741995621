import React, { Fragment } from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatMoney from "utils/formatMoney"

const Table = ({ collection, project, destroyExpenseWithRefresh }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Наименование внутреннее</th>
        <th scope="col">Наименование клиентское</th>
        <th scope="col">Расход в смете</th>
        <th scope="col">Фактический расход</th>
        <th scope="col">Забронировано</th>
        <th scope="col">К возврату</th>
        <th scope="col">Выдано</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection
        .filter((i) => !(i.cashableType === "overwork" && parseFloat(i.payment) === 0))
        .map((i, index) => (
          <tr key={`expense-${index}`}>
            <td>
              <Link to={`/projects/${project.id}/cashes/${i.cashableType}/${i.cashableId}`}>{i.orderName}</Link>
            </td>
            <td>{i.outerName}</td>
            <td>{formatMoney(i.innerCost)}</td>
            <td>{formatMoney(i.payment)}</td>
            <td>{formatMoney(i.cash)}</td>
            <td>{formatMoney(i.cashback)}</td>
            <td>{formatMoney(i.issued)}</td>
            <td>
              {i.cashableType === "service" && (
                <Fragment>
                  <Edit url={`/projects/${project.id}/services/${i.id}/edit`} />
                  <Destroy disabled />
                </Fragment>
              )}
              {i.cashableType === "expense" && (
                <Fragment>
                  <Edit url={`/projects/${project.id}/expenses/${i.id}/edit`} />
                  <Destroy action={() => destroyExpenseWithRefresh({ id: i.id })} disabled={i.paymentIsIssued === 1} />
                </Fragment>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  </table>
)

export default Table
