import React from "react"
import classnames from "classnames"

const ButtonWithSpinner = ({
  children,
  variant = "primary",
  size = null,
  className = null,
  disabled = false,
  ...props
}) => {
  const classNames = classnames("btn", { [`btn-${size}`]: size }, `btn-${variant}`, className)
  if (props.loading) {
    children = <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
    disabled = true
  }
  return (
    <button type="button" className={classNames} disabled={disabled} style={{ width: "107px" }} {...props}>
      {children}
    </button>
  )
}

export default ButtonWithSpinner
