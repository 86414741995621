import React, { Fragment } from "react"
import { Link } from "components/Router"
import useModalTrigger from "hooks/useModalTrigger"
import formatMoney from "utils/formatMoney"
import truncate from "utils/truncate"
import CommentModal from "./components/CommentModal"

const Table = ({ collection }) => {
  const [isCommentModalOpen, commentModalData, openCommentModal, closeCommentModal] = useModalTrigger(false)
  return (
    <Fragment>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">Дата</th>
            <th scope="col">ID проекта</th>
            <th scope="col">Позиция</th>
            <th scope="col">Оценка</th>
            <th scope="col">Выдано</th>
            <th scope="col">Отзыв</th>
          </tr>
        </thead>
        <tbody>
          {collection.data.map((i, index) => (
            <tr key={`project-staff-${index}`}>
              <td>{i.service.working}</td>
              <td><Link to={`/projects/${i.service.projectId}/teams`}>{i.service.projectId}</Link></td>
              <td>{i.service.innerName}</td>
              <td>{i.rating}</td>
              <td>{formatMoney(i.payment)}</td>
              <td
                onClick={() => openCommentModal(i.comment)}
                dangerouslySetInnerHTML={{ __html: truncate(i.comment) }}
              />
            </tr>
          ))}
        </tbody>
      </table>
      <CommentModal isOpen={isCommentModalOpen} closeModal={closeCommentModal}>
        {commentModalData}
      </CommentModal>
    </Fragment>
  )
}

export default Table
