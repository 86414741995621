import React from "react"
import { Destroy, Edit, ImagePreview } from "components/UI"
import formatDate from "utils/formatDate"

const Table = ({ collection, destroyStaffWithFetch, staff }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Превью</th>
        <th scope="col">Тип</th>
        <th scope="col">Дата создания</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`sources-${index}`}>
          <td>
            <ImagePreview src={i.file} />
          </td>
          <td>{i.kind.label}</td>
          <td>{formatDate(i.createdAt)}</td>
          <td>
            <Edit url={`/staffs/${staff.id}/portfolios/${i.id}/edit`} />
            <Destroy action={() => destroyStaffWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
