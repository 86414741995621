import instance from "connection/instance"

const prepare = (data = {}) => {
  const {
    kind,
    ...other
  } = data
  return {
    ...other,
    kind: kind?.value || null
  }
}

const Service = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/services`, { params })
  },
  create: (data) => instance.post("/services", { data: prepare(data) }),
  update: (data) => instance.put(`/services/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/services/${data.id}`),
  createFromTemplate: (data) => instance.post("/services/create_from_template", { data }),
  clone: (data) => instance.post("/services/clone", { data }),
  copy: (data) => instance.put(`/services/${data.id}/copy`)
}

export default Service
