import React, { Fragment } from "react"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useAgreement from "hooks/useAgreement"

const Edit = ({ client, ...props }) => {
  const { agreement, updateAgreement } = useAgreement(props.agreement)
  const { kinds } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/clients/${client.id}`}>{client.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/clients/${client.id}/agreements`}>Договоры</BreadcrumbItem>
        <BreadcrumbItem active>{agreement.fullName}</BreadcrumbItem>
      </Breadcrumbs>
    </Fragment>
  )
}

export default Edit
