import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Clients = () => (
  <Routes>
    <Route
      path="/clients/:clientId/stories"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/stories`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/clients/:clientId/stories/:storyId"
      fetchData={async ({ clientId, storyId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/stories/${storyId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/clients/:clientId/stories/new"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/stories/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/clients/:clientId/stories/:storyId/edit"
      fetchData={async ({ clientId, storyId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/clients/${clientId}/stories/${storyId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Clients
