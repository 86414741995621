import React from "react"
import { ImagePreview } from "components/UI"

const classNameByStatus = (status) => {
  return {
    active: "",
    black_list: "table-danger",
    archive: "table-info",
    under_consideration: "table-warning",
    in_processing: "table-success"
  }[status.value]
}

const Table = ({ collection, isCheckedStaff, onCheckedStaff, isCheckedAllStaffs, onCheckedAllStaffs }) => {
  const { page } = collection.pagination
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllStaffs(page)} onChange={(e) => onCheckedAllStaffs(e, page)} />
          </th>
          <th scope="col">Имя</th>
          <th scope="col">Возраст</th>
          <th scope="col">Телефон</th>
          <th scope="col">Фото</th>
          <th scope="col">Рейтинг</th>
        </tr>
      </thead>
      <tbody>
        {collection.data.map((row, index) => (
          <tr key={`staff-${index}`} className={classNameByStatus(row.status)}>
            <td>
              <input type="checkbox" checked={isCheckedStaff(row, page)} onChange={() => onCheckedStaff(row, page)} />
            </td>
            <td>{row.fullName}</td>
            <td>{row.age}</td>
            <td>
              {row.phones.map((i, index) => [
                index > 0 && ", ",
                <a key={`phone-${index}`} href={`tel:${i.value}`}>
                  {i.value}
                </a>
              ])}
            </td>
            <td>
              <ImagePreview src={row.avatar} width="85" height="110" />
            </td>
            <td>{row.rating}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
