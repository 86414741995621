import instance from "connection/instance"

const Feedback = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL || ""}/feedbacks`, { params })
  }
}

export default Feedback
