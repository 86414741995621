import instance from "connection/instance"

const prepare = (data = {}) => {
  const { status, ...other } = data
  return {
    ...other,
    status: status?.value || null
  }
}

const Reservation = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL || ""}/reservations`, { params })
  },
  create: (data) => instance.post("/reservations", { data }),
  update: (data) => instance.put(`/reservations/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/reservations/${data.id}`),
  export: (data) =>
    instance.get(`/reservations/${data.id}/export`, {
      params: {},
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    })
}

export default Reservation
