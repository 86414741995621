import instance from "connection/instance"

const prepare = (data = {}) => {
  const {
    kind: { value: kind },
    ...other
  } = data
  return { ...other, kind }
}

const Requisite = {
  fetch: (params) => instance.get("/requisites", { params }),
  create: (data) => instance.post("/requisites", { data: prepare(data) }),
  update: (data) => instance.put(`/requisites/${data.id}`, { data: prepare(data) }),
  destroy: (data) => instance.delete(`/requisites/${data.id}`)
}

export default Requisite
