import { useState } from "react"
import ReservationApi from "api/reservation"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useReservations = (collection, url = null) => {
  const [reservations, setReservations] = useState(collection)

  const fetchReservations = async (params) => {
    const { data } = await ReservationApi.setBaseURL(url).fetch(params)
    setReservations(data.reservations)
    return data
  }

  const destroyReservation = async (params) => ReservationApi.destroy(params)
  const destroyReservationWithFetch = async (params) => afterDestroyFetch(reservations, fetchReservations, destroyReservation, params)

  return { reservations, fetchReservations, destroyReservation, destroyReservationWithFetch }
}

export default useReservations
