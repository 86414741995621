import React from "react"
import { withFormik } from "formik"
import { DateInput, Submit, CurrencyInput } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import formatMoney from "utils/formatMoney"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ options, values, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <DateInput name="issuedOn" label="Дата выдачи" required />
    <CurrencyInput name="cost" label="Сумма" required hint={`не более ${formatMoney(values.costHint)}`} />
    <UserSelect name="recipient" label="Получатель" required />
    <Submit />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { recipient } = entity
    return {
      ...entity,
      recipient: recipient ? { label: recipient.fullName, value: recipient.id } : null
    }
  },
  handleSubmit: async (values, formikBag) =>
    formSubmitHandler(
      values,
      formikBag,
      `/projects/${values.projectId}/cashes/${values.cashableType}/${values.cashableId}`
    )
})(Form)
