import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatDate from "utils/formatDate"

const Table = ({ collection, destroyClientWithFetch }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название</th>
        <th scope="col">Менеджер</th>
        <th scope="col">Дата создания</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`clients-${index}`}>
          <td>
            <Link to={`/clients/${i.id}`}>{i.name}</Link>
          </td>
          <td>{i.manager?.fullName}</td>
          <td>{formatDate(i.createdAt)}</td>
          <td>
            <Edit url={`/clients/${i.id}/edit`} />
            <Destroy action={() => destroyClientWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
