import { useState } from "react"
import VacancyApi from "api/vacancy"

const useVacancies = (collection) => {
  const [vacancies, setVacancies] = useState(collection)

  const createVacancy = async (params) => await VacancyApi.create(params)
  const updateVacancies = async (params) => await VacancyApi.updateCollection(params)
  const updateVacancy = async (params) => await VacancyApi.update(params)
  const destroyVacancy = async (params) => await VacancyApi.destroy(params)
  const destroyVacancies = async (params) => await VacancyApi.destroyCollection(params)
  const createVacancyWithRefresh = async (params) => {
    const { data } = await createVacancy(params)
    setVacancies(vacancies.concat(data.vacancy))
    return data
  }
  const updateVacancyWithRefresh = async (params) => {
    const { data } = await updateVacancy(params)

    setVacancies(
      vacancies.map((i) => {
        if (i.id === data.vacancy.id) {
          return data.vacancy
        }
        return i
      })
    )
  }
  const updateVacanciesWithRefresh = async (params) => {
    const { data } = await updateVacancies(params)

    setVacancies(
      vacancies.map((vacancy) => {
        const updatedVacancy = data.vacancies.find((i) => i.id === vacancy.id)
        if (updatedVacancy) {
          return updatedVacancy
        }
        return vacancy
      })
    )
  }

  const destroyVacancyWithRefresh = async (params) => {
    await destroyVacancy(params)
    setVacancies(vacancies.filter((i) => i.id !== params.id))
  }
  const destroyVacanciesWithRefresh = async (params) => {
    await destroyVacancies(params)
    const ids = params.map((i) => i.id)
    setVacancies(vacancies.filter((i) => !ids.includes(i.id)))
  }

  const closeVacancies = async (params) => await VacancyApi.closeCollection(params)
  const closeVacanciesWithRefresh = async (params) => {
    const { data } = await closeVacancies(params)

    setVacancies(
      vacancies.map((vacancy) => {
        const closeVacancy = data.vacancies.find((i) => i.id === vacancy.id)
        if (closeVacancy) {
          return closeVacancy
        }
        return vacancy
      })
    )
  }

  const acceptVacancies = async (params) => await VacancyApi.acceptCollection(params)
  const acceptVacanciesWithRefresh = async (params) => {
    const { data } = await acceptVacancies(params)

    setVacancies(
      vacancies.map((vacancy) => {
        const acceptVacancy = data.vacancies.find((i) => i.id === vacancy.id)
        if (acceptVacancy) {
          return acceptVacancy
        }
        return vacancy
      })
    )

    return data
  }
  
  const rejectVacancies = async (params) => await VacancyApi.rejectCollection(params)
  const rejectVacanciesWithRefresh = async (params) => {
    const { data } = await rejectVacancies(params)

    setVacancies(
      vacancies.map((vacancy) => {
        const rejectVacancy = data.vacancies.find((i) => i.id === vacancy.id)
        if (rejectVacancy) {
          return rejectVacancy
        }
        return vacancy
      })
    )
  }

  const reserveVacancies = async (params) => await VacancyApi.reserveCollection(params)
  const reserveVacanciesWithRefresh = async (params) => {
    const { data } = await reserveVacancies(params)

    setVacancies(
      vacancies.map((vacancy) => {
        const reserveVacancy = data.vacancies.find((i) => i.id === vacancy.id)
        if (reserveVacancy) {
          return reserveVacancy
        }
        return vacancy
      })
    )
  }

  const fetchMoreVacancies = async (params) => {
    const { data } = await VacancyApi.fetchMoreVacancies(params)
    setVacancies((prev) => [...prev, ...data.vacancies])
  
    return data.vacancies
  }

  return {
    vacancies,
    createVacancyWithRefresh,
    updateVacancyWithRefresh,
    updateVacanciesWithRefresh,
    destroyVacancyWithRefresh,
    destroyVacanciesWithRefresh,
    closeVacanciesWithRefresh,
    acceptVacanciesWithRefresh,
    rejectVacanciesWithRefresh,
    reserveVacanciesWithRefresh,
    fetchMoreVacancies
  }
}

export default useVacancies
