import React from "react"
import { withFormik } from "formik"
import { Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название" required />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/positions")
})(Form)
