import { useState } from "react"
import PositionApi from "api/position"

const usePosition = (collection) => {
  const [position, setPosition] = useState(collection)

  const createPosition = async (params) => {
    const { data } = await PositionApi.create(params)
    setPosition(data.position)
    return data
  }

  const updatePosition = async (params) => {
    const { data } = await PositionApi.update(params)
    setPosition(data.position)
    return data
  }

  return { position, createPosition, updatePosition }
}

export default usePosition
