import React from "react"
import { withFormik } from "formik"
import { Submit } from "components/UI"
import UserSelect from "components/Common/UserSelect"

const Form = ({ options, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <UserSelect />
    <Submit/>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { user } = entity
    return {
      ...entity,
      user: user ? { label: user.fullName, value: user.id } : null
    }
  },
  handleSubmit: async (values, formikBag) => formikBag.props.handleSubmit(values)
})(Form)
