import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"
import Add from "./containers/Add"
import Show from "./containers/Show"
import Services from "./containers/Services"

const ServiceSets = () => (
  <Routes>
    <Route
      path="/service_sets"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/service_sets/:serviceSetId"
      fetchData={async ({ serviceSetId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/service_sets/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/service_sets/:serviceSetId/edit"
      fetchData={async ({ serviceSetId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
    <Route path="/service_sets/:serviceSetId/services" component={Services} />
  </Routes>
)
export default ServiceSets
