import React, { Fragment, useState } from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn, ErrorMessage } from "formik"
import instance from "connection/instance"
import { decamelize } from "humps"
import { useDropzone } from "react-dropzone"

const FileInput = ({ name, label, type = "file", onChange = () => {}, accept, fileFormatHint = null, ...props }) => {
  const id = nanoid()
  const { errors, values, setSubmitting } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })
  const attachmentableColumn = name.split(".").pop()

  const getFileFromUrl = (url) => url && decodeURI(url.replace(/^.*[\\|/]/, ""))

  const [fileName, setFileName] = useState(getFileFromUrl(value.file))
  const [uploadProgress, setUploadProgress] = useState(0)

  const onFileInputChange = (file) => {
    const formData = new FormData()
    setSubmitting(true)
    formData.append("data[file]", file)
    formData.append("data[attachmentable_column]", decamelize(attachmentableColumn))
    instance
      .post("/attachments", formData, {
        onUploadProgress: (event) => {
          const percentCompleted = Math.round((event.loaded * 100) / event.total)
          setUploadProgress(percentCompleted)
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data: { attachment } }) => props.formik.setFieldValue(name, attachment))
      .finally(() => {
        setSubmitting(false)
        setUploadProgress(0)
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFileName(acceptedFiles[0].path)
      onFileInputChange(acceptedFiles[0])
    }
  })
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Перетащите сюда файл или кликните, чтобы выбрать файл</p>
        {fileFormatHint && <em>{fileFormatHint}</em>}
      </div>
      {uploadProgress > 0 && uploadProgress <= 100 && (
        <div className="progress" style={{ marginTop: "0.5rem" }}>
          <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} />
        </div>
      )}
      {value && (
        <Fragment>
          {value.thumb && (
            <Fragment>
              <br />
              <div className="upload-image-preview">
                <img src={value.thumb} className="img-fluid img-thumbnail" height="100" />
              </div>
            </Fragment>
          )}

          <span style={{ display: "inline-block", marginTop: "0.5rem" }}>
            <a href={value.file} target="_blank">
              {fileName}
            </a>
          </span>
        </Fragment>
      )}

      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default connect(FileInput)
