import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requests/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useRequest from "hooks/useRequest"
import Form from "pages/Requests/components/Form"

const Add = ({ requestOptions, ...props }) => {
  const { request, createRequest } = useRequest(props.request)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={request}
        requestOptions={requestOptions}
        handleSubmit={async (values) => createRequest(values)}
      />
    </Fragment>
  )
}

export default Add
