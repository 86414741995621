import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Employments from "./containers/Employments"
import Services from "./containers/Services"
import Costs from "./containers/Costs"

const Calendars = () => (
  <Routes>
    <Route
      path="/calendars/employments"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/calendars/employments`, {
          signal
        })
        return data
      }}
      component={Employments}
    />
    <Route
      path="/calendars/services"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/calendars/services`, {
          signal
        })
        return data
      }}
      component={Services}
    />
    <Route
      path="/calendars/costs"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/calendars/costs`, {
          signal
        })
        return data
      }}
      component={Costs}
    />
  </Routes>
)
export default Calendars
