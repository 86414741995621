import { useState } from "react"
import StaffApi from "api/staff"

const useStaff = (collection) => {
  const [staff, setStaff] = useState(collection)

  const createStaff = async (params) => {
    const { data } = await StaffApi.create(params)
    setStaff(data.staff)
    return data
  }

  const updateStaff = async (params) => {
    const { data } = await StaffApi.update(params)
    setStaff(data.staff)
    return data
  }

  return { staff, createStaff, updateStaff }
}

export default useStaff
