import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useCashes from "hooks/useCashes"
import TabsBlock from "pages/Projects/components/TabsBlock"
import FinancesTabsBlock from "pages/Projects/components/FinancesTabsBlock"
import Table from "./components/Table"

const List = ({ project, expense, ...props }) => {
  const { cashes, fetchCashes, destroyCashWithFetch } = useCashes(props.cashes, window.location.pathname)
  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/expenses`}>Расходы</BreadcrumbItem>
        <BreadcrumbItem active>{expense.name}</BreadcrumbItem>
        <BreadcrumbItem active>Денежные средства</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <FinancesTabsBlock project={project} />
      <Card>
        <Button url={`/projects/${project.id}/cashes/${expense.type}/${expense.id}/new`}>Создать</Button>
      </Card>
      <Table collection={cashes} destroyCashWithFetch={destroyCashWithFetch} project={project} expense={expense} />
      <Paginate collection={cashes} fetch={fetchCashes} />
    </DisabledPage>
  )
}

export default List
