import { useState } from "react"
import SkillApi from "api/skill"

const useSkill = (collection) => {
  const [skill, setSkill] = useState(collection)

  const createSkill = async (params) => {
    const { data } = await SkillApi.create(params)
    setSkill(data.skill)
    return data
  }

  const updateSkill = async (params) => {
    const { data } = await SkillApi.update(params)
    setSkill(data.skill)
    return data
  }

  return { skill, createSkill, updateSkill }
}

export default useSkill
