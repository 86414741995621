import React, { Fragment, useState } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import TabsBlock from "pages/Staffs/components/TabsBlock"
import { BreadcrumbItem, Button, Card, ImagePreview } from "components/UI"
import formatDate from "utils/formatDate"
import formatCardNumber from "utils/formatCardNumber"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import StaffApi from "api/staff"
import download from "utils/download"
import differenceInYears from "date-fns/differenceInYears"

const Show = ({ staff }) => {
  const [disabled, setDisabled] = useState(false)

  const exportContractToDocx = async () => {
    setDisabled(true)
    const { data } = await StaffApi.exportContractToDocx(staff)
    setDisabled(false)
    download(data, `Договор № ${staff.id}.docx`)
  }

  const exportAgreementToDocx = async () => {
    setDisabled(true)
    const { data } = await StaffApi.exportAgreementToDocx(staff)
    setDisabled(false)
    download(data, `Согласие № ${staff.id}.docx`)
  }
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Карточка персонала</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock staff={staff} />
      <Card>
        <Button url={`/staffs/${staff.id}/edit`} title="Редактировать">
          <i className="fas fa-edit" />
        </Button>
        <DropdownButton title="Экспорт" variant="success">
          <Dropdown.Item onClick={exportContractToDocx}>Договор в .docx</Dropdown.Item>
          <Dropdown.Item onClick={exportAgreementToDocx}>Согласие в .docx</Dropdown.Item>
        </DropdownButton>
      </Card>
      <table className="table table-hover">
        <tbody>
          <tr>
            <td className="col-6">Создал</td>
            <td>{staff.creator?.fullName}</td>
          </tr>
          <tr>
            <td>Дата создания</td>
            <td>{formatDate(staff.createdAt)}</td>
          </tr>
          <tr>
            <td>Рейтинг</td>
            <td>{staff.rating}</td>
          </tr>
          <tr>
            <td>Фотография</td>
            <td>
              <ImagePreview src={staff.avatar} />
            </td>
          </tr>
          <tr>
            <td>Телефоны</td>
            <td>
              {staff.phones.map((i, index) => [
                index > 0 && ", ",
                <a key={`phone-${i.id}`} href={`tel:${i.value}`}>
                  +{i.value}
                </a>
              ])}
            </td>
          </tr>
          <tr>
            <td>Emails</td>
            <td>
              {staff.emails.map((i, index) => [
                index > 0 && ", ",
                <a key={`email-${i.id}`} href={`mailto:${i.value}`}>
                  {i.value}
                </a>
              ])}
            </td>
          </tr>
          <tr>
            <td>Имя</td>
            <td>{[staff.passport?.lastName, staff.passport?.firstName, staff.passport?.middleName].join(" ")}</td>
          </tr>
          <tr>
            <td>Пол</td>
            <td>{staff.passport.sex.label}</td>
          </tr>
          <tr>
            <td>Дата рождения</td>
            <td>
              {formatDate(staff.passport.birthdayOn)}
              {staff.passport.birthdayOn && ` (${differenceInYears(new Date(), staff.passport.birthdayOn)})`}
            </td>
          </tr>
          <tr>
            <td>Гражданство</td>
            <td>{staff.passport.citizenship.label}</td>
          </tr>
          <tr>
            <td>Комментарий к кандидату</td>
            <td>{staff.candidateComment}</td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>{staff.status.label}</td>
          </tr>
          <tr>
            <td>Комментарий к статусу</td>
            <td>{staff.statusComment}</td>
          </tr>
          <tr>
            <td>Навыки</td>
            <td>{staff.skills.map((i) => i.name).join(", ")}</td>
          </tr>
          <tr>
            <td>Фактический адрес</td>
            <td>{staff.address}</td>
          </tr>
          <tr>
            <td>Станция метро</td>
            <td>{staff.metroStation?.name}</td>
          </tr>
          <tr>
            <td>Иностранный язык</td>
            <td>{staff.languages.map((i) => i.name).join(", ")}</td>
          </tr>
          {staff.languagesComment && (
            <tr>
              <td>Комментарий к знанию языка</td>
              <td>{staff.languagesComment}</td>
            </tr>
          )}
          <tr>
            <td>Мед. книжка</td>
            <td>{staff.isHasMedicalBook ? "Да" : "Нет"}</td>
          </tr>
          <tr>
            <td>Рост</td>
            <td>{staff.growth}</td>
          </tr>
          <tr>
            <td>Вес</td>
            <td>{staff.weight}</td>
          </tr>
          <tr>
            <td>Размер одежды</td>
            <td>{staff.clothesSize.label}</td>
          </tr>
          <tr>
            <td>Размер обуви</td>
            <td>{staff.shoesSize}</td>
          </tr>
          <tr>
            <td>Номер банковской карты</td>
            <td>{formatCardNumber(staff.card?.number)}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={2}>Паспорт</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-6">Гражданство</td>
            <td>{staff.passport.citizenship.label}</td>
          </tr>
          <tr>
            <td className="col-6">Дата рождения</td>
            <td>{formatDate(staff.passport.birthdayOn)}</td>
          </tr>
          <tr>
            <td className="col-6">Место рождения</td>
            <td>{staff.passport.placeOfBirth}</td>
          </tr>
          <tr>
            <td className="col-6">Номер и серия</td>
            <td>{staff.passport.number}</td>
          </tr>
          <tr>
            <td className="col-6">Паспорт выдан</td>
            <td>{staff.passport.issuedBy}</td>
          </tr>
          <tr>
            <td className="col-6">Дата выдачи</td>
            <td>{formatDate(staff.passport.issuedOn)}</td>
          </tr>
          <tr>
            <td className="col-6">Код подразделения</td>
            <td>{staff.passport.code}</td>
          </tr>
          <tr>
            <td className="col-6">Регистрация</td>
            <td>{staff.passport.registration}</td>
          </tr>
          <tr>
            <td>Скан разворот</td>
            <td>
              <ImagePreview src={staff.passport?.firstScan} />
            </td>
          </tr>
          <tr>
            <td>Скан прописка</td>
            <td>
              <ImagePreview src={staff.passport?.secondScan} />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={2}>Реквизиты</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-6">Самозанятый</td>
            <td>{staff.selfemployedRequisite.isSelfemployed ? "Да" : "Нет"}</td>
          </tr>
          <tr>
            <td>Получатель</td>
            <td>{staff.selfemployedRequisite.recipient}</td>
          </tr>
          <tr>
            <td>Расчетный счет</td>
            <td>{staff.selfemployedRequisite.paymentAccount}</td>
          </tr>
          <tr>
            <td>БИК</td>
            <td>{staff.selfemployedRequisite.bik}</td>
          </tr>
          <tr>
            <td>Банк</td>
            <td>{staff.selfemployedRequisite.bank}</td>
          </tr>
          <tr>
            <td>Корр. счет</td>
            <td>{staff.selfemployedRequisite.correspondentAccount}</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>{staff.selfemployedRequisite.inn}</td>
          </tr>
          <tr>
            <td>КПП</td>
            <td>{staff.selfemployedRequisite.kpp}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default Show
