import React from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import DisabledPage from "pages/Projects/components/DisabledPage"
import { BreadcrumbItem } from "components/UI"
import TabsBlock from "pages/Projects/components/TabsBlock"
import useModalTrigger from "hooks/useModalTrigger"
import groupBy from "lodash.groupby"
import useProjectStaffs from "hooks/useProjectStaffs"
import useEmployments from "hooks/useEmployments"
import useOverworks from "hooks/useOverworks"
import Table from "./components/Table"
import EditProjectStaffModal from "./components/EditProjectStaffModal"

const Staffs = ({ project, staffs, accounts, ...props }) => {
  const { projectStaffs, updateProjectStaffsWithRefresh } = useProjectStaffs(props.projectStaffs)
  const { employments, updateEmploymentsWithRefresh } = useEmployments(props.employments)
  const { overworks, updateOverworksWithRefresh } = useOverworks(props.overworks)
  const [
    isEditProjectStaffModalOpen,
    editProjectStaffModalData,
    openEditProjectStaffModal,
    closeEditProjectStaffModal
  ] = useModalTrigger({ id: null })

  const projectStaffsGroupByStaffId = groupBy(projectStaffs, "staffId")
  const managersGroupByEmploymentableId = groupBy(employments, "employmentableId")
  const overworksGroupByStaffId = groupBy(overworks, "staffId")

  return (
    <DisabledPage project={project}>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem active>Выплаты</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock project={project} />
      <Table
        collection={accounts}
        projectStaffsGroupByStaffId={projectStaffsGroupByStaffId}
        managersGroupByEmploymentableId={managersGroupByEmploymentableId}
        overworksGroupByStaffId={overworksGroupByStaffId}
        openEditProjectStaffModal={openEditProjectStaffModal}
      />
      <EditProjectStaffModal
        staff={editProjectStaffModalData}
        closeModal={closeEditProjectStaffModal}
        isOpen={isEditProjectStaffModalOpen}
        
        // projectStaff={editProjectStaffModalData}
        
        updateProjectStaffsWithRefresh={updateProjectStaffsWithRefresh}
        updateEmploymentsWithRefresh={updateEmploymentsWithRefresh}
        updateOverworksWithRefresh={updateOverworksWithRefresh}
        projectStaffs={projectStaffsGroupByStaffId[editProjectStaffModalData.staffId] || []}
        employments={managersGroupByEmploymentableId[editProjectStaffModalData.employmentableId] || []}
        overworks={overworksGroupByStaffId[editProjectStaffModalData.staffId] || []}
      />
    </DisabledPage>
  )
}

export default Staffs
