import React from "react"
import { Link } from "components/Router"

const Edit = ({ url, action = () => {}, disabled = false, ...props }) => {
  if (url) {
    action = () => window.history.pushState(null, "", url)
  }

  return (
    <button type="button" className="btn btn-link btn-action destroy" disabled={disabled} onClick={action} {...props}>
      <i className="fas fa-edit" />
    </button>
  )
}

export default Edit
