import React from "react"
import Form from "pages/Clients/components/Form"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import useClient from "hooks/useClient"

const ClientModal = ({ isOpen, closeModal, options, onClientAdded }) => {
  const { createClient } = useClient({})

  const handleSubmit = async (values) => {
    const { client } = await createClient(values)
    onClientAdded({ label: client.name, value: client.id })
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать клиента</ModalHeader>
      <ModalBody>
        <Form entity={{}} options={{ users: options.users }} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ClientModal
