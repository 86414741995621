import React from "react"
import { withFormik } from "formik"
import {
  AddressInput,
  BankInput,
  BikInput,
  CardNumber,
  CheckboxGroup,
  CheckboxInput,
  CodeInput,
  CorrespondentAccountInput,
  DateInput,
  InnInput,
  KppInput,
  PaymentAccountInput,
  PhoneInput,
  RadioButtonGroup,
  RadioInput,
  SelectInput,
  Submit,
  TextInput,
  UploadImage,
  UploadInput
} from "components/UI"
import Card from "react-bootstrap/Card"
import formSubmitHandler from "utils/formSubmitHandler"
import Emails from "components/Common/Emails"
import Phones from "components/Common/Phones"
import MetroStationSelect from "components/Common/MetroStationSelect"

const Form = ({ options, handleSubmit }) => {
  const { languages, sexes, citizenships, statuses, skills, metroStations, clothesSizes } = options

  const months = new Array(12).fill(null).map((i, index) => {
    return { label: ("0" + (index + 1)).slice(-2), value: index + 1 }
  })

  const years = new Array(20).fill(null).map((i, index) => {
    return { label: index + 2020, value: index + 1 }
  })

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Card.Header>Учетная запись</Card.Header>
        <Card.Body>
          <PhoneInput name="account.login" label="Логин" />
          <CheckboxInput name="account.isActive" label="Учетная запись активна?" />
          <CheckboxInput name="account.isUseWhatsapp" label="WhatsApp" />
        </Card.Body>
      </Card>
      <TextInput name="passport.lastName" label="Фамилия" />
      <TextInput name="passport.firstName" label="Имя" />
      <TextInput name="passport.middleName" label="Отчество" />
      <RadioButtonGroup label="Пол" name="passport.sex">
        {sexes.map(({ label, value }) => (
          <RadioInput key={`passport-sex-${value}`} label={label} value={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Статус" name="status">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status-${value}`} label={label} value={value} />
        ))}
      </RadioButtonGroup>
      <TextInput name="statusComment" label="Комментарий к статусу" />
      <CheckboxGroup label="Иностранный язык" name="languageIds">
        {languages.map(({ label, value }) => (
          <CheckboxInput key={`language-${value}`} label={label} value={value} />
        ))}
      </CheckboxGroup>
      <TextInput name="languagesComment" label="Комментарий к знанию языка" />
      <CheckboxGroup label="Навыки" name="skillIds">
        {skills.map(({ label, value }) => (
          <CheckboxInput key={`skill-${value}`} label={label} value={value} />
        ))}
      </CheckboxGroup>
      <MetroStationSelect name="metroStation" options={metroStations} />
      <SelectInput name="passport.citizenship" label="Гражданство" options={citizenships} isClearable />
      <DateInput name="passport.birthdayOn" label="Дата рождения" />
      <TextInput name="passport.placeOfBirth" label="Место рождения" />
      <TextInput name="passport.number" label="Номер и серия" />
      <TextInput name="passport.issuedBy" label="Паспорт выдан" />
      <DateInput name="passport.issuedOn" label="Дата выдачи" />
      <CodeInput name="passport.code" label="Код подразделения" />
      <AddressInput name="passport.registration" label="Регистрация" />
      <UploadInput name="passport.firstScan" label="Скан разворот" accept="image/jpeg,image/png,image/gif" />
      <UploadInput name="passport.secondScan" label="Скан прописка" accept="image/jpeg,image/png,image/gif" />
      <TextInput name="growth" label="Рост" type="number" min="0" max="200" />
      <TextInput name="weight" label="Вес" type="number" min="0" max="200" />
      <SelectInput name="clothesSize.value" label="Размер одежды" options={clothesSizes} />
      <TextInput name="shoesSize" label="Размер обуви" type="number" min="0" max="200" />
      <Emails />
      <Phones />
      <TextInput name="candidateComment" label="Комментарий к кандидату" />
      <AddressInput name="address" label="Фактический адрес" />
      <Card>
        <Card.Header>Банковская карта</Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-sm">
              <TextInput name="card.holder" label="Имя владельца" />
            </div>
            <div className="col-sm">
              <CardNumber name="card.number" label="Номер карты" />
            </div>
            <div className="col col-lg-2">
              <SelectInput name="card.expirationMonth" label="Месяц" options={months} />
            </div>
            <div className="col col-lg-2">
              <SelectInput name="card.expirationYear" label="Год" options={years} />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Реквизиты</Card.Header>
        <Card.Body>
          <CheckboxInput name="selfemployedRequisite.isSelfemployed" label="Самозанятый" />
          <TextInput name="selfemployedRequisite.recipient" label="Получатель" />
          <PaymentAccountInput name="selfemployedRequisite.paymentAccount" label="Расчетный счет" />
          <BikInput name="selfemployedRequisite.bik" label="БИК" />
          <BankInput name="selfemployedRequisite.bank" label="Банк" />
          <CorrespondentAccountInput name="selfemployedRequisite.correspondentAccount" label="Корр. счет" />
          <InnInput name="selfemployedRequisite.inn" label="ИНН" />
          <KppInput name="selfemployedRequisite.kpp" label="КПП" />
        </Card.Body>
      </Card>
      <CheckboxInput name="isHasMedicalBook" label="Мед. книжка" />
      <UploadImage name="avatar" label="Фотография" />
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => {
    const { metroStation, passport, languages, skills } = entity
    return {
      ...entity,
      metroStation: metroStation ? { label: metroStation.name, value: metroStation.id } : null,
      passport: passport ? { ...passport, citizenship: passport.citizenship.value } : {},
      languageIds: languages ? languages.map((i) => i.id) : [],
      skillIds: skills ? skills.map((i) => i.id) : []
    }
  },
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, ({ staff }) => `/staffs/${staff.id}`)
})(Form)
