import React, { useContext } from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { connect, getIn } from "formik"
import CommonContext from "contexts/CommonContext"

const CheckboxInput = ({ name, label, value, ...props }) => {
  const id = nanoid()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  // const _value = getIn(values, name, false)

  const { checked } = useContext(CommonContext)
  const _value = getIn(values, name, false)

  const className = classnames("form-check-input", { "is-invalid": error })
  // console.log(value.map(i => i.id ? String(i.id).toString() : i))
  return (
    <div className="form-check-inline form-check">
      <input
        // checked={String(_value).toString() === String(value).toString()}
        type="checkbox"
        value={value}
        onBlur={handleBlur}
        // onChange={handleChange}

        checked={_value ? (typeof _value === "boolean" ? _value : _value.includes(value)) : false}
        onChange={(e) => {
          if (e.target.checked) {
            if (Array.isArray(_value)) {
              props.formik.setFieldValue(name, _value.concat([value]))
            } else {
              props.formik.setFieldValue(name, e.target.checked)
            }
          } else {
            if (Array.isArray(_value)) {
              props.formik.setFieldValue(
                name,
                _value.filter((i) => i !== value)
              )
            } else {
              props.formik.setFieldValue(name, e.target.checked)
            }
          }
        }}
        {...{ className, id, name, ...props }}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>

      {error && <div className="invalid-feedback">{error[0]}</div>}
    </div>
  )
}

export default connect(CheckboxInput)
