import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Clients from "./containers/Clients"
import SalesByServices from "./containers/SalesByServices"
import ProjectsByContacts from "./containers/ProjectsByContacts"
import Services from "./containers/Services"

const Calendars = () => (
  <Routes>
    <Route
      path="/analytics/clients"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/analytics/clients`, {
          signal
        })
        return data
      }}
      component={Clients}
    />
    <Route
      path="/analytics/clients/:clientId/sales_by_services"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/analytics/clients/${clientId}/sales_by_services`, {
          signal
        })
        return data
      }}
      component={SalesByServices}
    />
    <Route
      path="/analytics/clients/:clientId/projects_by_contacts"
      fetchData={async ({ clientId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/analytics/clients/${clientId}/projects_by_contacts`, {
          signal
        })
        return data
      }}
      component={ProjectsByContacts}
    />
    <Route
      path="/analytics/services"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/analytics/services`, {
          signal
        })
        return data
      }}
      component={Services}
    />
  </Routes>
)
export default Calendars
