import React from "react"
import classnames from "classnames"
import { nanoid } from "utils/nanoid"
import { useField } from "formik"

const TextArea = ({ name, label, required = false, ...props }) => {
  const id = nanoid()
  const [{ onBlur, onChange }, { value, error }] = useField(name)
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className={classnames("mb-3", { required })}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        className={className}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        {...props}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}
export default TextArea
