import React from "react"
import { DateInput, SelectInput, ResetButton, Submit } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import { withFormik } from "formik"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ collection, requestOptions, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <DateInput name="byCreatedAtFrom" label="Дата создания с" />
      </div>
      <div className="col-3">
        <DateInput name="byCreatedAtTo" label="Дата создания по" />
      </div>
      <div className="col-3">
        <SelectInput name="byKind" label="Тип" options={requestOptions.kinds} />
      </div>
      <div className="col-3">
        <SelectInput name="byStatus" label="Статус" options={requestOptions.statuses} />
      </div>
      <div className="col-3">
        <UserSelect name="byAccountManagerId" label="Аккаунт-менеджер" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
    &nbsp;&nbsp;&nbsp;Найдено: <strong>{collection.pagination.totalCount}</strong>
  </form>
)

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
})(Filter)
