import { useState } from "react"
import EntityApi from "api/entity"

const useEntity = (collection) => {
  const [entity, setEntity] = useState(collection)

  const createEntity = async (params) => {
    const { data } = await EntityApi.create(params)
    setEntity(data.entity)
    return data
  }

  const updateEntity = async (params) => {
    const { data } = await EntityApi.update(params)
    setEntity(data.entity)
    return data
  }

  return { entity, createEntity, updateEntity }
}

export default useEntity
