import React, { Fragment } from "react"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useCourier from "hooks/useCourier"
import Form from "pages/Couriers/components/Form"

const Edit = ({ courierOptions, staffOptions, staffs, ...props }) => {
  const { courier, updateCourier } = useCourier(props.courier)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{courier.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={courier}
        courierOptions={courierOptions}
        staffOptions={staffOptions}
        staffs={staffs}
        handleSubmit={async (values) => updateCourier(values)}
      />
    </Fragment>
  )
}

export default Edit
