import React from "react"
import NestedFields from "components/UI/NestedFields"
import Email from "./components/Email"

const defaultValues = {
  value: ""
}

const Emails = ({ name = "emails", required = false }) => (
  <NestedFields name={name} label="Emails" component={Email} defaultValues={defaultValues} required={required} />
)

export default Emails
