import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tabs, Tab } from "components/UI"
import ServiceApi from "api/service"
import Table from "./components/Table"

const TemplatesModal = ({ services, isOpen, closeModal, onServicesAdd }) => {
  const { kinds, templates } = services

  useEffect(() => {
    setTemplateIds([])
  }, [isOpen])

  const onSave = () => ServiceApi.createFromTemplate({ templateIds }).then(({ data }) => onServicesAdd(data))

  const [templateIds, setTemplateIds] = useState([])

  const isCheckedTemplateIds = (id) => templateIds.includes(id)

  const onCheckedTemplateIds = (id) => {
    const checkedTemplateIds = isCheckedTemplateIds(id) ? templateIds.filter((i) => i !== id) : templateIds.concat([id])
    setTemplateIds(checkedTemplateIds)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Услуги</ModalHeader>
      <ModalBody>
        <Tabs defaultActiveKey="main" transition={false}>
          {kinds.map(({ value, label }) => (
            <Tab key={`tab-${value}`} eventKey={value} title={label}>
              <Table
                templates={templates.filter((i) => i.kind.value === value)}
                isChecked={isCheckedTemplateIds}
                onChecked={onCheckedTemplateIds}
              />
            </Tab>
          ))}
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSave} variant="success" disabled={templateIds.length === 0}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TemplatesModal
