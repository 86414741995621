import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useEstimate from "hooks/useEstimate"
import Form from "pages/Projects/containers/Estimates/components/Form"

const Edit = ({ project, estimateOptions, ...props }) => {
  const { estimate, updateEstimate } = useEstimate(props.estimate)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Сметы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates/${estimate.id}`}>{estimate.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...estimate, projectId: project.id }}
        options={estimateOptions}
        handleSubmit={async (values) => updateEstimate({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
