import { toast } from "react-toastify"

const formSubmitHandler = async (values, formikBag, path) => {
  try {
    const response = await formikBag.props.handleSubmit(values)
    if (response && path) {
      if (typeof path === "function") {
        window.history.pushState(null, "", path(response))
      } else {
        window.history.pushState(null, "", path)
      }
    }
    return response?.data
  } catch (error) {
    formikBag.setErrors(error.response.data.errors)
    if (error.response.status !== 401 && error.response.status !== 404) {
      toast.error("Ошибка сохранения. Проверьте форму на наличие ошибок.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  } finally {
    formikBag.setSubmitting(false)
  }
}

export default formSubmitHandler
