import React from "react"

const Destroy = ({ action, disabled = false }) => {
  const onClick = e => {
    e.preventDefault()
    if (window.confirm("Уверены?")) {
      action()
    }
  }
  return (
    <button type="button" className="btn btn-link btn-action destroy" disabled={disabled} onClick={onClick}>
      <i className="fas fa-trash-alt" />
    </button>
  )
}
export default Destroy
