import { useState } from "react"
import CostsCalendarApi from "api/costsCalendar"

const useCostsCalendar = (collection, url = null) => {
  const [costs, setCosts] = useState(collection)

  const fetchCostsCalendar = async (params) => {
    const { data } = await CostsCalendarApi.fetch(params)
    setCosts(data.costs)
    return data
  }

  return { costs, fetchCostsCalendar }
}

export default useCostsCalendar
