import React, { Fragment } from "react"
import { DateInput, CheckboxGroup, CheckboxInput } from "components/UI"

const Dates = ({ isCheckedExcludeDays, onCheckedExcludeDays, data, setData }) => {
  const wDays = [
    { label: "Понедельник", value: 1 },
    { label: "Вторник", value: 2 },
    { label: "Среда", value: 3 },
    { label: "Четверг", value: 4 },
    { label: "Пятница", value: 5 },
    { label: "Суббота", value: 6 },
    { label: "Воскресенье", value: 0 }
  ]
  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <DateInput
            name="start_on"
            label="Начало"
            value={data.startedAt}
            onChange={(e) => setData({ ...data, startedAt: e })}
          />
        </div>
        <div className="col">
          <DateInput
            name="finish_on"
            label="Окончание"
            value={data.finishedAt}
            onChange={(e) => setData({ ...data, finishedAt: e })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <CheckboxGroup label="Исключить дни" name="transport_kinds">
            {wDays.map(({ label, value }) => (
              <CheckboxInput
                key={`transport_kinds_${value}`}
                label={label}
                checked={isCheckedExcludeDays(value)}
                onChange={() => onCheckedExcludeDays(value)}
              />
            ))}
          </CheckboxGroup>
        </div>
      </div>
    </Fragment>
  )
}

export default Dates
