import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePayment from "hooks/usePayment"
import Form from "pages/Projects/containers/Payments/components/Form"

const Edit = ({ project, paymentOptions, payments, ...props }) => {
  const { payment, updatePayment } = usePayment(props.payment)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/projects/${project.id}`}>{project.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/estimates`}>Финансы</BreadcrumbItem>
        <BreadcrumbItem to={`/projects/${project.id}/payments`}>Платежи</BreadcrumbItem>
        <BreadcrumbItem active>{payment.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...payment, projectId: project.id }}
        payments={payments}
        paymentOptions={paymentOptions}
        handleSubmit={async (values) => updatePayment({ ...values })}
      />
    </Fragment>
  )
}

export default Edit
