import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import TabsBlock from "pages/Staffs/components/TabsBlock"
import { BreadcrumbItem, Card, Paginate } from "components/UI"
import useStaffExperiences from "hooks/useStaffExperiences"
import Table from "./components/Table"
import Filter from "./components/Filter"

const Experiences = ({ staff, ...props }) => {
  const { projectStaffs, fetchStaffExperiences } = useStaffExperiences(props.projectStaffs, `/staffs/${staff.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Опыт</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock staff={staff} />
      <Card>
        <Filter collection={projectStaffs} handleSubmit={async (values) => fetchStaffExperiences({ filter: values })} />
      </Card>
      <Table collection={projectStaffs} />
      <Paginate collection={projectStaffs} fetch={fetchStaffExperiences} />
    </Fragment>
  )
}

export default Experiences
