import React from "react"
import { Modal, ModalBody, ModalHeader } from "components/UI"
import Form from "./components/Form"

const ServiceModal = ({ closeModal, isOpen, service, updateServiceWithRefresh }) => {
  const handleSubmit = async (data) => {
    await updateServiceWithRefresh(data)
    closeModal()
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Бриф</ModalHeader>
      <ModalBody>
        <Form entity={service} handleSubmit={handleSubmit} />
      </ModalBody>
    </Modal>
  )
}

export default ServiceModal
