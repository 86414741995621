import React, { Fragment } from "react"
import { Edit, Clone, Destroy, Copy } from "components/UI"
import useSelect from "hooks/useSelect"
import formatMoney from "utils/formatMoney"
import compareAsc from "date-fns/compareAsc"
import ServiceApi from "api/service"

const GroupByKind = ({
  kind,
  services,
  openServiceFormModal,
  openCloneModal,
  markServiceForDestroy,
  onCopyServicesAdded
}) => {
  const [
    selectedServiceIds,
    totalServiceIdsSelected,
    isServiceIdChecked,
    onServiceIdChecked,
    isServiceIdCheckedAll,
    onServiceIdCheckedAll
  ] = useSelect(services.map((i) => i.id))

  const copyService = (id) => {
    ServiceApi.copy({ id }).then(({ data }) => onCopyServicesAdded(data))
  }

  return (
    <Fragment>
      <tr className="table-success">
        <td>
          <input type="checkbox" checked={isServiceIdCheckedAll} onChange={onServiceIdCheckedAll} />
        </td>
        <td colSpan="9">{kind.label}</td>
        <td className="text-right">
          <Destroy
            action={() => selectedServiceIds.forEach((i) => markServiceForDestroy(i))}
            disabled={selectedServiceIds.length === 0}
          />
        </td>
      </tr>
      {services
        .sort((a, b) => compareAsc(a.startedAt, b.startedAt))
        .map((i, index) => (
          <tr key={`service-${index}`}>
            <td>
              <input type="checkbox" checked={isServiceIdChecked(i.id)} onChange={() => onServiceIdChecked(i.id)} />
            </td>
            <td>{i.orderName}</td>
            <td>{i.quantity}</td>
            <td>{i.working}</td>
            <td>{i.kind.value === "main" && i.numberOfHours}</td>
            <td>{formatMoney(i.innerCostPerHour)}</td>
            <td>{formatMoney(i.outerCostPerHour)}</td>
            <td>{i.discount}</td>
            <td className="table-info">{formatMoney(i.innerCost)}</td>
            <td className="table-danger">{formatMoney(i.outerCost)}</td>
            <td>
              <Edit action={() => openServiceFormModal(i.id)} disabled={i.cashIsIssued} />
              <Clone action={() => openCloneModal(i.id)} />
              <Copy action={() => copyService(i.id)} />
              <Destroy action={() => markServiceForDestroy(i.id)} disabled={i.cashIsIssued} />
            </td>
          </tr>
        ))}
    </Fragment>
  )
}
export default GroupByKind
