import React, { Fragment } from "react"
import Breadcrumbs from "pages/ServiceSets/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useService from "hooks/useService"
import Form from "pages/ServiceSets/containers/Services/components/Form"

const Add = ({ serviceOptions, serviceSet, ...props }) => {
  const { service, createService } = useService(props.service)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/service_sets/${serviceSet.id}`}>{serviceSet.name}</BreadcrumbItem>
        <BreadcrumbItem to={`/service_sets/${serviceSet.id}/services`}>Услуги</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={{ ...service, serviceSetId: serviceSet.id }}
        options={serviceOptions}
        handleSubmit={async (values) => createService({ ...values })}
      />
    </Fragment>
  )
}

export default Add
