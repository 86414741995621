import React, { Fragment } from "react"
import Breadcrumbs from "pages/Requests/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useRequests from "hooks/useRequests"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ requestOptions, ...props }) => {
  const { requests, fetchRequests, destroyRequestWithFetch } = useRequests(props.requests)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/requests/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          collection={requests}
          requestOptions={requestOptions}
          handleSubmit={async (values) => fetchRequests({ filter: values })}
        />
      </Card>
      <Table collection={requests} destroyRequestWithFetch={destroyRequestWithFetch} />
      <Paginate collection={requests} fetch={fetchRequests} />
    </Fragment>
  )
}

export default List
