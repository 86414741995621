import React, { Fragment, useContext, useState } from "react"
import Form from "pages/Login/components/Form"
import PhoneForm from "pages/Login/components/PhoneForm"
import AuthContext from "contexts/AuthContext"

const Login = () => {
  const { login, sendSms } = useContext(AuthContext)

  const [isSendSms, setIsSendSms] = useState(false)
  const [phone, setPhone] = useState(false)

  const onSendSms = async (values) => {
    setPhone(values.login)
    await sendSms(values)
    setIsSendSms(true)
  }
  return (
    <Fragment>
      {isSendSms ? (
        <Form entity={{ password: "" }} handleSubmit={async (values) => login({ ...values, login: phone })} />
      ) : (
        <PhoneForm entity={{ login: "" }} handleSubmit={async (values) => onSendSms(values)} />
      )}
    </Fragment>
  )
}

export default Login
