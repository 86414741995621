import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Projects = () => (
  <Routes>
    <Route
      path="/projects/:projectId/cashes/:cashableType/:cashableId"
      fetchData={async ({ projectId, cashableType, cashableId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/cashes/${cashableType}/${cashableId}`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/projects/:projectId/cashes/:cashableType/:cashableId/new"
      fetchData={async ({ projectId, cashableType, cashableId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/cashes/${cashableType}/${cashableId}/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/projects/:projectId/cashes/:cashableType/:cashableId/:cashId/edit"
      fetchData={async ({ projectId, cashableType, cashableId, cashId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/projects/${projectId}/cashes/${cashableType}/${cashableId}/${cashId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Projects
