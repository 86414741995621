import React from "react"
import formatMoney from "utils/formatMoney"
import groupBy from "lodash.groupby"

const Table = ({ collection }) => {
  const groupByKind = groupBy(collection.data, "kind.label")
  return Object.keys(groupByKind).map((kind) => (
    <table key={`kind-${kind}`} className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th colSpan={5}>{kind}</th>
        </tr>
        <tr>
          <th>Наименование внутреннее</th>
          <th className="col-lg-2">Кол-во проектов</th>
          <th className="col-lg-2">Реализовано на сумму</th>
          <th className="col-lg-2">Сметная прибыль</th>
          <th className="col-lg-2">% от сметной прибыли</th>
        </tr>
      </thead>
      <tbody>
        {groupByKind[kind].map((row, index) => (
          <tr key={`services-${index}`}>
            <td>{row.innerName}</td>
            <td>{row.projectsCount}</td>
            <td>{formatMoney(row.outerCost)}</td>
            <td>{formatMoney(row.profit)}</td>
            <td>{row.percent}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ))
}

export default Table
