import React from "react"
import { ResetButton, SelectInput, Submit } from "components/UI"
import UserSelect from "components/Common/UserSelect"
import { withFormik } from "formik"

const Filter = ({ options, values, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <UserSelect name="byId" label="Менеджер" isDisabled={values.byPositionId} />
      </div>
      <div className="col-3">
        <SelectInput name="byPositionId" label="Позиция" options={options.positions} disabled={values.byId} />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, { props }) => props.handleSubmit(values)
})(Filter)
