import React from "react"
import { withFormik } from "formik"
import { CheckboxInput } from "components/UI"
import usePermissions from "hooks/usePermissions"
import formSubmitHandler from "utils/formSubmitHandler"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import formatDateTime from "utils/formatDateTime"
import { Submit } from "components/UI"

const Form = ({ entity, values, setFieldValue, handleSubmit }) => {
  const allow = usePermissions(["ADMIN", "FINANCES"])
  const isCheckedAll = values.every((i) => i.paymentIsIssued)
  const onCheckedAll = (e) => entity.forEach((_, index) => setFieldValue(`${index}.paymentIsIssued`, e.target.checked))
  return (
    <form onSubmit={handleSubmit}>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th colSpan={3}>Выплаты менеджеру</th>
          </tr>
          <tr>
            <th scope="col" style={{width: '60%'}}>Позиция</th>
            <th scope="col">Сумма к выплате</th>
            <th scope="col">Статус выдано</th>
          </tr>
        </thead>
        <tbody>
          {entity.map((i, index) => (
            <tr key={`manager-${index}`}>
              <td>
                {i.description} {formatDateTime(i.startedAt)} - {formatDateTime(i.finishedAt)}
              </td>
              <td>{formatMoney(i.payment)}</td>
              <td>
                <CheckboxInput name={`${index}.paymentIsIssued`} disabled={!allow} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Итого:</th>
            <th>{formatMoney(entity.reduce((a, b) => a + parseMoney(b.payment), 0))}</th>
            <th>
              <CheckboxInput checked={isCheckedAll} onChange={onCheckedAll} disabled={!allow} />
            </th>
          </tr>
        </tfoot>
      </table>
      <Submit />
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ entity }) => entity,
  handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag)
})(Form)
