import React, { Fragment } from "react"
import { EmailInput } from "components/UI"

const Email = ({ prefix }) => (
  <Fragment>
    <EmailInput name={`${prefix}.value`} label="Email" />
  </Fragment>
)

export default Email
