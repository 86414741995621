import { useState } from "react"
import DocumentApi from "api/document"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useDocuments = (collection, url = null) => {
  const [documents, setDocuments] = useState(collection)

  const fetchDocuments = async (params) => {
    const { data } = await DocumentApi.setBaseURL(url).fetch(params)
    setDocuments(data.documents)
    return data
  }

  const destroyDocument = async (params) => DocumentApi.destroy(params)
  const destroyDocumentWithFetch = async (params) => afterDestroyFetch(documents, fetchDocuments, destroyDocument, params)

  return { documents, fetchDocuments, destroyDocument, destroyDocumentWithFetch }
}

export default useDocuments
