import { useState } from "react"
import ExpenseApi from "api/expense"

const useExpense = (collection) => {
  const [expense, setExpense] = useState(collection)

  const createExpense = async (params) => {
    const { data } = await ExpenseApi.create(params)
    setExpense(data.expense)
    return data
  }

  const updateExpense = async (params) => {
    const { data } = await ExpenseApi.update(params)
    setExpense(data.expense)
    return data
  }

  return { expense, createExpense, updateExpense }
}

export default useExpense
