import React from "react"

const NotFound = () => (
  <div>
    <h1>404</h1>
    <p>
      Запрашиваемая вами страница не найдена.
      <br />
      Возможно, вы пришли по устаревшей ссылке или ошиблись при наборе в адресной строке.
    </p>
  </div>
)

export default NotFound
