import { useState } from "react"
import EstimateApi from "api/estimate"

const useEstimate = (collection) => {
  const [estimate, setEstimate] = useState(collection)

  const createEstimate = async (params) => {
    const { data } = await EstimateApi.create(params)
    setEstimate(data.estimate)
    return data
  }

  const updateEstimate = async (params) => {
    const { data } = await EstimateApi.update(params)
    setEstimate(data.estimate)
    return data
  }

  return { estimate, createEstimate, updateEstimate }
}

export default useEstimate
