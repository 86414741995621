import { useState } from "react"
import ServiceSetApi from "api/serviceSet"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useServiceSets = (collection) => {
  const [serviceSets, setServiceSets] = useState(collection)

  const fetchServiceSets = async (params) => {
    const { data } = await ServiceSetApi.fetch(params)
    setServiceSets(data.serviceSets)
    return data
  }

  const destroyServiceSet = async (params) => ServiceSetApi.destroy(params)
  const destroyServiceSetWithFetch = async (params) => afterDestroyFetch(serviceSets, fetchServiceSets, destroyServiceSet, params)

  return { serviceSets, fetchServiceSets, destroyServiceSet, destroyServiceSetWithFetch }
}

export default useServiceSets
