import { useState } from "react"
import StoryApi from "api/story"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useStories = (collection, url = null) => {
  const [stories, setStories] = useState(collection)

  const fetchStories = async (params) => {
    const { data } = await StoryApi.setBaseURL(url).fetch(params)
    setStories(data.stories)
    return data
  }

  const destroyStory = async (params) => StoryApi.destroy(params)
  const destroyStoryWithFetch = async (params) => afterDestroyFetch(stories, fetchStories, destroyStory, params)

  return { stories, fetchStories, destroyStory, destroyStoryWithFetch }
}

export default useStories
