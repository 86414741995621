import { useState } from "react"
import ClientsAnalyticApi from "api/clientsAnalytic"

const useClientsAnalytic = (collection, url = null) => {
  const [clients, setClients] = useState(collection)

  const fetchClientsAnalytic = async (params) => {
    const { data } = await ClientsAnalyticApi.fetch(params)
    setClients(data.clients)
    return data
  }

  return { clients, fetchClientsAnalytic }
}

export default useClientsAnalytic
