import { useState } from "react"
import StoryApi from "api/story"

const useStory = (collection) => {
  const [story, setStory] = useState(collection)

  const createStory = async (params) => {
    const { data } = await StoryApi.create(params)
    setStory(data.story)
    return data
  }

  const updateStory = async (params) => {
    const { data } = await StoryApi.update(params)
    setStory(data.story)
    return data
  }

  return { story, createStory, updateStory }
}

export default useStory
