import React, { Fragment, useState } from "react"
import { Button, SelectInput, TextInput, PhoneInput, ResetButton, Submit, CheckboxInput } from "components/UI"
import MetroStationSelect from "components/Common/MetroStationSelect"
import { withFormik } from "formik"
import classnames from "classnames"

const Filter = ({ collection, options, values, handleSubmit }) => {
  const [isShowAdditional, setIsShowAdditional] = useState(false)
  const toggleAddtional = () => setIsShowAdditional(!isShowAdditional)
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="byIdOrName" label="ID или имя" />
        </div>
        <div className="col-3">
          <PhoneInput name="byPhone" label="Телефон" />
        </div>
        <div className="col-3">
          <SelectInput name="bySex" label="Пол" options={options.sexes} />
        </div>
        <div className="col-3">
          <SelectInput name="byStatus" label="Статус" options={options.statuses} />
        </div>
        {isShowAdditional && (
          <Fragment>
            <div className="col-3">
              <SelectInput name="bySkillIds" label="Навыки" options={options.skills} isMulti />
            </div>
            <div className="col-3">
              <MetroStationSelect name="byMetroStationIds" options={options.metroStations} isMulti />
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <TextInput name="byAgeFrom" label="Возраст от" type="number" min="18" />
                </div>
                <div className="col-6">
                  <TextInput name="byAgeTo" label="Возраст до" type="number" max="100" />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <SelectInput
                    name="byClothesSizeFrom"
                    label="Размер от"
                    options={options.clothesSizes}
                    isOptionDisabled={(option) => values.byClothesSizeTo && option.value > values.byClothesSizeTo}
                  />
                </div>
                <div className="col-6">
                  <SelectInput
                    name="byClothesSizeTo"
                    label="Размер до"
                    options={options.clothesSizes}
                    isOptionDisabled={(option) => values.byClothesSizeFrom && option.value < values.byClothesSizeFrom}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <TextInput name="byRatingFrom" label="Рейтинг от" type="number" min="0" />
                </div>
                <div className="col-6">
                  <TextInput name="byRatingTo" label="Рейтинг до" type="number" max="5" />
                </div>
              </div>
            </div>
            <div className="col-3">
              <SelectInput name="byLanguageIds" label="Иностранный язык" options={options.languages} isMulti />
            </div>
            <div className="col-3 filter-checkboxes">
              <CheckboxInput name="byIsHasMedicalBook" label="Медкнижка" />
              <CheckboxInput name="byIsSelfemployed" label="Самозанятый" />
            </div>
          </Fragment>
        )}
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;&nbsp;&nbsp;Найдено: <strong>{collection.pagination.totalCount}</strong>
      <Button className="show-additional" onClick={toggleAddtional} title="Дополнительные фильтры">
        <i className={classnames(["fas", { "fa-angle-up": isShowAdditional, "fa-angle-down": !isShowAdditional }])}></i>
      </Button>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ collection }) => collection.filter,
  handleSubmit: (values, { props }) => props.handleSubmit(values)
})(Filter)
