import instance from "connection/instance"

const Document = {
  baseURL: null,
  setBaseURL(baseURL) {
    this.baseURL = baseURL
    return this
  },
  fetch(params) {
    return instance.get(`${this.baseURL}/documents`, { params })
  },
  create: (data) => instance.post("/documents", { data }),
  update: (data) => instance.put(`/documents/${data.id}`, { data }),
  destroy: (data) => instance.delete(`/documents/${data.id}`)
}

export default Document
