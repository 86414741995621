import React, { Fragment } from "react"
import Breadcrumbs from "pages/Analytics/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import useServicesAnalytic from "hooks/useServicesAnalytic"
import Filter from "./components/Filter"
import Table from "./components/Table"

const Services = ({ serviceOptions, ...props }) => {
  const { services, fetchServicesAnalytic } = useServicesAnalytic(props.services)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Услуги</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter
          options={serviceOptions}
          collection={services}
          handleSubmit={async (values) => fetchServicesAnalytic({ filter: values })}
        />
      </Card>
      <Table collection={services} />
    </Fragment>
  )
}

export default Services
