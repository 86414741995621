import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useProjects from "hooks/useProjects"
import Table from "./components/Table"
import Filter from "./components/Filter"

const List = ({ projectsFilterOptions, ...props }) => {
  const { projects, fetchProjects, destroyProjectWithFetch } = useProjects(props.projects)
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/projects/new">Создать</Button>
      </Card>
      <Card>
        <Filter
          options={projectsFilterOptions}
          collection={projects}
          handleSubmit={async (values) => fetchProjects({ filter: values })}
        />
      </Card>
      <Table collection={projects} destroyProjectWithFetch={destroyProjectWithFetch} />
      <Paginate collection={projects} fetch={fetchProjects} />
    </Fragment>
  )
}

export default List
