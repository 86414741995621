import { useState } from "react"
import ProjectApi from "api/project"
import afterDestroyFetch from "utils/afterDestroyFetch"

const useProjects = (collection) => {
  const [projects, setProjects] = useState(collection)

  const fetchProjects = async (params) => {
    const { data } = await ProjectApi.fetch(params)
    setProjects(data.projects)
    return data.projects
  }

  const destroyProject = async (params) => ProjectApi.destroy(params)
  const destroyProjectWithFetch = async (params) => afterDestroyFetch(projects, fetchProjects, destroyProject, params)

  return { projects, fetchProjects, destroyProject, destroyProjectWithFetch }
}

export default useProjects
