import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Services = () => (
  <Routes>
    <Route
      path="/service_sets/:serviceSetId/services"
      fetchData={async ({ serviceSetId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}/services`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/service_sets/:serviceSetId/services/:serviceId"
      fetchData={async ({ serviceSetId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}/services/${serviceId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/service_sets/:serviceSetId/services/new"
      fetchData={async ({ serviceSetId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}/services/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/service_sets/:serviceSetId/services/:serviceId/edit"
      fetchData={async ({ serviceSetId, serviceId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/service_sets/${serviceSetId}/services/${serviceId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Services
