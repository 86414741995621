import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Show from "./containers/Show"
import Edit from "./containers/Edit"
import Add from "./containers/Add"

const PaymentRegistries = () => (
  <Routes>
    <Route
      path="/payment_registries"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/payment_registries`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/payment_registries/:paymentRegistryId"
      fetchData={async ({ paymentRegistryId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/payment_registries/${paymentRegistryId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/payment_registries/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/payment_registries/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/payment_registries/:paymentRegistryId/edit"
      fetchData={async ({ paymentRegistryId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/payment_registries/${paymentRegistryId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default PaymentRegistries
