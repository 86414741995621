import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Edit from "./containers/Edit"

const Cashes = () => (
  <Routes>
    <Route
      path="/cashes"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/cashes`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/cashes/:cashId/edit"
      fetchData={async ({ cashId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/cashes/${cashId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Cashes
