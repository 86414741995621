import React, { Fragment } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useProject from "hooks/useProject"
import Form from "pages/Projects/components/Form"

const Add = (props) => {
  const { project, createProject } = useProject(props.project)
  const { clients, contacts, entities, failureReasons, statuses, methodOfPayments, users } = props
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form
        entity={project}
        options={{ clients, contacts, entities, failureReasons, statuses, methodOfPayments, users }}
        handleSubmit={async (values) => createProject(values)}
      />
    </Fragment>
  )
}

export default Add
