import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "components/Router"
import formatPhone from "utils/formatPhone"

const Table = ({ collection, destroyClientWithFetch, client }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th className="col-lg-3">Имя</th>
        <th>Позиция</th>
        <th className="col-lg-3">E-mail</th>
        <th className="col-lg-3">Телефон</th>
        <th className="actions two" />
      </tr>
    </thead>
    <tbody>
      {collection.data.map((i, index) => (
        <tr key={`sources-${index}`}>
          <td>
            {i.fullName}
          </td>
          <td>{i.position}</td>
          <td>
            {i.emails.map((i, index) => [
              index > 0 && ", ",
              <a key={`email-${i.id}`} href={`mailto:${i.value}`}>
                {i.value}
              </a>
            ])}
          </td>
          <td>
            {i.phones.map((i, index) => [
              index > 0 && ", ",
              <a key={`phone-${i.id}`} href={`tel:${i.value}`}>
                {formatPhone(i.value)}
              </a>
            ])}
          </td>
          <td>
            <Edit url={`/clients/${client.id}/contacts/${i.id}/edit`} />
            <Destroy action={() => destroyClientWithFetch({ id: i.id })} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
