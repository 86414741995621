import React from "react"

const Forbidden = () => (
  <div>
    <h1>403</h1>
    <p>
      Вы не авторизованы для выполнения этого действия.
    </p>
  </div>
)

export default Forbidden
