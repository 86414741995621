import instance from "connection/instance"

const prepareParams = (params = {}) => {
  const { byStatus, ...other } = params.filter || {}
  return {
    ...params,
    filter: {
      ...other,
      byStatus: byStatus?.value || null
    }
  }
}

const CostsCalendar = {
  fetch: (params) => instance.get("/calendars/costs", { params: prepareParams(params) })
}

export default CostsCalendar
